import _pull from 'lodash/pull'

const updateWithTypeChange = (id, jsData) => {
  jsData.forEach((item, index) => {
    if(+item.id === +id) {
      let { rnRoles=[] } = jsData[index].$original
      rnRoles = rnRoles.includes('curator') ? _pull(rnRoles, 'curator') : [...rnRoles, 'curator']
      jsData[index].$original = {
        ...jsData[index].$original,
        rnRoles
      }
    }
  })
  return jsData
}
const updateWithItemDelete= (id, jsData) => {
  jsData.forEach((item, index) => {
    if(+item.id === +id) {
      jsData.splice(index, 1)
    }
  })
  return jsData
}
const adaptUserTypeChange = (id, allData, isActionDelete) => {
  if(!isActionDelete) {
    return updateWithItemDelete(id, allData)
  }
  return updateWithTypeChange(id, allData)
    
    
}

export {
  adaptUserTypeChange
}