import _get from 'lodash/get'
import { createAsyncAction } from '../actions'
import { fetchAPI } from '../../core/http'
import { showAxiosError } from '../../../web/utils/messenger'

const { FETCH_EMAIL_TEMPLATES } = createAsyncAction('FETCH_EMAIL_TEMPLATES', function (id, type) {
  const url = type === 'brand' ? `api/brand/${id}/email_templates` : `/api/events/${id}/relationships/email_templates`

  return dispatch =>
    fetchAPI(url)
      .catch(err => {
        const errMsg = _get(err, 'errors[0].details')
        showAxiosError(err.errors[0], errMsg, 3)
        throw err
      })
      .then(res => {
        const out = { id, emailtemplates: res }
        dispatch(this.success(out))
        return res
      })
})

const { CREATE_EMAIL_TEMPLATE } = createAsyncAction('CREATE_EMAIL_TEMPLATE', function (id, form, type) {
  const url = type === 'brand' ? `api/brand/${id}/email_template` : `/api/event/${id}/email_template`
  const body = {
    data: {
      attributes: {
        ...form
      }
    }
  }

  return dispatch =>
    fetchAPI(url, {
      method: 'POST',
      body: JSON.stringify(body)
    })
      .catch(err => {
        const errMsg = _get(err, 'errors[0].details')
        showAxiosError(err.errors[0], errMsg, 3)
        throw err
      })
      .then(res => {
        dispatch(this.success(res))
        return res
      })
})

const { UPDATE_EMAIL_TEMPLATE } = createAsyncAction('UPDATE_EMAIL_TEMPLATE', function (pid, id, form, type) {
  const url = type === 'brand' ? `api/brand/${id}/email_template/${pid}` : `/api/event/${id}/email_template/${pid}`
  const body = {
    data: {
      attributes: {
        ...form
      }
    }
  }
  
  return dispatch =>
    fetchAPI(url, {
      method: 'PATCH',
      body: JSON.stringify(body)
    })
      .catch(err => {
        const errMsg = _get(err, 'errors[0].details')
        showAxiosError(err.errors[0], errMsg, 3)
        throw err
      })
      .then(res => {
        dispatch(this.success(res))
        return res
      })
})

export { FETCH_EMAIL_TEMPLATES, CREATE_EMAIL_TEMPLATE, UPDATE_EMAIL_TEMPLATE }
