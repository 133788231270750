import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import ConfirmModal from '../_library/ConfirmModal'
import _get from 'lodash/get'

import store from '../../_common/redux/store'
import { checkIdVerificationStatus, PARTLY_VERIFIED } from '../utils/checkVerification'
import { reVerify } from '../../_common/core/http_services'

const verificationHOC = ChildComponent => {
  const VerificationComponent = props => {
    const history = useHistory()
    const globalStates = store.getState()
    const user = _get(globalStates, 'auth.user')
    const verifyInfo = _get(globalStates, 'auth.user.verifyInfo')

    const component = _get(props, 'component')
    const [isModalOpen, openCloseModal] = useState(false)

    const checkVerification = () => {
      const isModalAlreadyShowed = localStorage.getItem('isModalAlreadyShowed')
      const isPartlyVerified = checkIdVerificationStatus(verifyInfo) === PARTLY_VERIFIED

      if (isPartlyVerified && !isModalAlreadyShowed && !!user && !isModalOpen) {
        openCloseModal(true)
        reVerify()
      }
    }

    const onConfirmResponse = response => {
      switch (response) {
      case 'verify': {
        history.push('/account')
        break
      }
      case 'dismiss': {
        break
      }
      }

      localStorage.setItem('isModalAlreadyShowed', true)
      openCloseModal(false)
    }

    useEffect(() => {
      checkVerification()
    })

    return !isModalOpen ? (
      <ChildComponent component={component} />
    ) : (
      <ConfirmModal
        isOpen={isModalOpen}
        header={'Warning!'}
        content={
          'We’ve made changes to our user identity verification process and will need you to verify your account again. We’ve emailed you further information on this.'
        }
        actions={[
          { value: 'verify', label: 'Verify', className: 'btn btn-success btn-shadow' },
          { value: 'dismiss', label: 'Dismiss', className: 'btn btn-default' }
        ]}
        onAction={onConfirmResponse}
        classNames={{ modal: 'modal-dialog modal-trans center' }}
      />
    )
  }

  return VerificationComponent
}

export default verificationHOC
