import React from 'react'
import _identity from 'lodash/identity'
import _get from 'lodash/get'
import { FieldPassword } from '../../_library/PasswordField'

export const PasswordField = ({
  field,
  form: { touched, errors, setFieldValue, setFieldTouched },
  touchedOnBlur = false,
  normalizer = _identity,
  onBlurNormalizer = _identity,
  showErrorOnlyTouched = true,
  ...props
}) => {
  const setValue = (value, n = normalizer) => {
    setFieldValue(field.name, n(value))
  }
  const onChange = event => {
    const { value } = event.target
    if (props.onChange) {
      props.onChange(event)
    }
    setValue(value)
  }
  const onBlur = event => {
    const {
      target: { value }
    } = event
    setValue(value, onBlurNormalizer)
    setFieldTouched(field.name, true)
    if (props.onBlur) {
      props.onBlur(event)
    }
  }
  return (
    <FieldPassword
      {...field}
      {...props}
      id={field.name}
      touched={_get(touched, field.name)}
      error={_get(errors, field.name)}
      onChange={onChange}
      onBlur={onBlur}
    />
  )
}