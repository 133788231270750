import _get from 'lodash/get'
import _map from 'lodash/map'
import _groupBy from 'lodash/groupBy'
import createAction from 'redux-actions/lib/createAction'
import { shortid } from '../../core/rand'

function notify(level, ...messages) {
  console.log(level, messages)
  // bad code
  if (_get(messages, '0.0.isAxiosError')) {
    const { response } = messages[0]
    let message = 'Something isn\'t quite right. Please try again.'
    if (response) {
      const { data: { message: errMessage=message }={} } = response
      message = errMessage
    }
    return [{ id: shortid(), level, message: { details: message } }]
  }

  const grouped = _groupBy(messages, (m) => m.code === 'validation_failed'? 'vf' : 'rest')
  const { vf, rest } = grouped
  const filtered = [].concat(rest || [])

  if (vf && vf.length) {
    filtered.push({ details: 'Validation failed', code: 'validation_failed', meta: { errors: vf } })
  }

  return _map(filtered, (message) => ({ id: shortid(), level, message }))
}

export const INFO = createAction('notifications/INFO', notify.bind(null, 'info'))
export const ERROR = createAction('notifications/ERROR', notify.bind(null, 'danger'))
export const WARNING = createAction('notifications/WARNING', notify.bind(null, 'warning'))
export const SUCCESS = createAction('notifications/SUCCESS', notify.bind(null, 'success'))
export const DISMISS = createAction('notifications/DISMISS')
export const CLEAR = createAction('notifications/CLEAR')
