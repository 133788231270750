import React from 'react'
import _identity from 'lodash/identity'

const AlternativeCheckbox = ({
  field,
  form: { touched, errors, setFieldValue, setFieldTouched },
  touchedOnBlur = false,
  normalizer = _identity,
  label,
  ...props
}) => {
  const locale = !!props.locale
  const customized = !!props.customized
  const { localeChecked } = props
  const { initialCheckedData } = props

  const setValue = value => {
    setFieldValue(field.name, normalizer(value))
  }
  const onChange = ({ target: { checked } }) => {
    if(checked && customized) {
      locale ? localeChecked(field.name) : initialCheckedData(field.name)
    }
    setValue(checked)
  }
  const onBlur = ({ target: { checked } }) => {
    setValue(checked)
    touchedOnBlur && setFieldTouched(field.name, checked)
  }

  delete props.event
  delete props.values
  delete props.locale
  delete props.image
  delete props.description
  delete props.customized
  delete props.localeChecked
  delete props.initialCheckedData

  return (
    <div className="checkbox-alternative-view">
      <div className="checkbox-container">
        <label className="checkbox-label">
          <input
            {...{ ...field, checked: field.value }}
            {...props}
            type="checkbox"
            id={field.name}
            onChange={onChange}
            onBlur={onBlur}
          />
          <span className="checkbox-custom rectangular"/>
        </label>
        <label htmlFor={field.name} />
        {label && <label>{label}</label>}
      </div>
    </div>
  )
}

export { AlternativeCheckbox }
