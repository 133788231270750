import _get from 'lodash/get'
import handleActions from 'redux-actions/lib/handleActions'
import { createAsyncHandlers } from '../actions'

const initialState = {
  invitations: {},
  members: [],
  promoterGenericInvitation: null,
  hasTeamInvitationFlag: false
}

const SET_HAS_TEAM_INVITATION = createAsyncHandlers('SET_HAS_TEAM_INVITATION', {
  success(state, action) {    
    state.hasTeamInvitationFlag = action.payload
  }
})

const REGENERATE_PROMOTER_GENERIC_INVITATION_BRAND = createAsyncHandlers('REGENERATE_PROMOTER_GENERIC_INVITATION_BRAND', {
  success(state, action) {
    state.promoterGenericInvitation = {...state.promoterGenericInvitation, token: action.payload}
  }
})

const FETCH_BRAND_TEAM_INVITATIONS = createAsyncHandlers('FETCH_BRAND_TEAM_INVITATIONS', {
  success(state, action) {    
    const { data } = action.payload
    state.invitations = _get(data, '$original')
  }
})

const FETCH_BRAND_TEAM_MEMBERS = createAsyncHandlers('FETCH_BRAND_TEAM_MEMBERS', {
  success(state, action) {    
    const { data } = action.payload
    state.members = data.map((d) => _get(d, '$original'))
  }
})

const FETCH_BRAND_PROMOTER_GENERIC_INVITATION = createAsyncHandlers('FETCH_BRAND_PROMOTER_GENERIC_INVITATION', {
  success(state, action) {
    state.promoterGenericInvitation = action.payload
  }
})

const UPDATE_BRAND_PROMOTER_GENERIC_INVITATION = createAsyncHandlers('UPDATE_BRAND_PROMOTER_GENERIC_INVITATION', {
  success(state, action) {
    const {data} = action.payload
    state.promoterGenericInvitation = {...state.promoterGenericInvitation, status: data['$original'].attributes.status}
  }
})

export default handleActions({
  ...FETCH_BRAND_TEAM_INVITATIONS,
  ...FETCH_BRAND_TEAM_MEMBERS,
  ...FETCH_BRAND_PROMOTER_GENERIC_INVITATION,
  ...REGENERATE_PROMOTER_GENERIC_INVITATION_BRAND,
  ...UPDATE_BRAND_PROMOTER_GENERIC_INVITATION,
  ...SET_HAS_TEAM_INVITATION
}, initialState)
