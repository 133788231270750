import React from 'react'

import Field from '../_library/Field'
import Card from '../_library/Card'
import TextArea from '../_library/TextArea'

const BrandTicketAdvancedOptions = React.memo((props) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    importFbPixelDialog,
    closed,
    showGoogleTagManager = true
  } = props
  return (
    <Card icon={'fa-clipboard'} title={'Advanced Options'} closed={closed}>
      <div className="event-ticket-advanced-options">
        <div className="row">
          <div className="col-xs-12">
            <span className="junior_text">Conversion Tracking JavaScript Code</span>
            <button className="btn btn-primary" style={{ float:'right' }} type="button" onClick={importFbPixelDialog}>
              <i className="fa fa-facebook-official" aria-hidden="true" /> Import Facebook Pixel
            </button>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-xs-12">
            <TextArea
              rows="5"
              id="pagePixels"
              label="View of Event Page"
              value={values.pagePixels}
              error={errors.pagePixels}
              touched={touched.pagePixels}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <TextArea
              rows="5"
              id="checkoutPixels"
              label="Start of Checkout"
              value={values.checkoutPixels}
              error={errors.checkoutPixels}
              touched={touched.checkoutPixels}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <TextArea
              rows="5"
              id="conversionPixels"
              label="Completion of Purchase (use {CURRENCY} and {VALUE} to auto-insert conversion data)"
              value={values.conversionPixels}
              error={errors.conversionPixels}
              touched={touched.conversionPixels}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <Field
              className="ga-field"
              id="googleAnalyticsKey"
              label="Google Analytics Tracking ID"
              value={values.googleAnalyticsKey}
              error={errors.googleAnalyticsKey}
              touched={touched.googleAnalyticsKey}
              placeholder="UA-XXXXX-Y"
              onChange={handleChange}
            />
          </div>
        </div>
        {showGoogleTagManager && (
          <div className="row">
            <div className="col-xs-12">
              <Field
                className="ga-field"
                id="googleTagManagerKey"
                label="Google Tag Manager Container ID"
                value={values.googleTagManagerKey}
                error={errors.googleTagManagerKey}
                touched={touched.googleTagManagerKey}
                placeholder="GTM-XXXX"
                onChange={handleChange}
              />
            </div>
          </div>
        )}
      </div>
    </Card>
  )
})

export default BrandTicketAdvancedOptions
