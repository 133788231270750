import { createAsyncAction } from '../actions'
import {
  getTicketTrackingData,
  createTicketTrackingData,
  regenerateTrackingToken,
  deleteTicketTrackingData,
  getTicketTrackingDataByToken
} from '../../core/http_services'
import { showAxiosError } from '../../../web/utils/messenger'

const { FETCH_TICKET_TRACKING_DATA } = createAsyncAction('FETCH_TICKET_TRACKING_DATA', function (eventId) {
  return async dispatch => {
    try {
      const data = await getTicketTrackingData(eventId)
      dispatch(this.success(data))
      return data
    } catch (err) {
      showAxiosError(err)
    }
  }
})

const { CREATE_TICKET_TRACKING_DATA } = createAsyncAction('CREATE_TICKET_TRACKING_DATA', function (
  eventId,
  body
) {
  return async dispatch => {
    try {
      const data = await createTicketTrackingData(eventId, body)
      dispatch(this.success(data))
      Messenger().post({
        type: 'success',
        message: data.data.message,
        hideAfter: 3,
        showCloseButton: true
      })

      return data
    } catch (err) {
      showAxiosError(err)
    }
  }
})

const { DELETE_TICKET_TRACKING_DATA } = createAsyncAction('DELETE_TICKET_TRACKING_DATA', function (
  eventId,
  trackingSalesId
) {
  return async dispatch => {
    try {
      const data = await deleteTicketTrackingData(eventId, trackingSalesId)
      dispatch(this.success({ id: trackingSalesId, data }))
      Messenger().post({
        type: 'success',
        message: data.data.message,
        hideAfter: 3,
        showCloseButton: true
      })

      return data
    } catch (err) {
      showAxiosError(err)
    }
  }
})

const { REGENERATE_TRACKING_TOKEN } = createAsyncAction('REGENERATE_TRACKING_TOKEN', function (
  eventId,
  trackingSalesId
) {
  return async dispatch => {
    try {
      const data = await regenerateTrackingToken(eventId, trackingSalesId)
      dispatch(this.success({ id: trackingSalesId, data }))
      Messenger().post({
        type: 'success',
        message: data.data.message,
        hideAfter: 3,
        showCloseButton: true
      })

      return data
    } catch (err) {
      showAxiosError(err)
    }
  }
})
const { FETCH_TRACKING_DATA_BY_TOKEN } = createAsyncAction('FETCH_TRACKING_DATA_BY_TOKEN', function (token) {
  return async dispatch => {
    try {
      const data = await getTicketTrackingDataByToken(token)
      dispatch(this.success(data))
      return data
    } catch (err) {
      showAxiosError(err)
    }
  }
})
export {
  FETCH_TICKET_TRACKING_DATA,
  CREATE_TICKET_TRACKING_DATA,
  DELETE_TICKET_TRACKING_DATA,
  REGENERATE_TRACKING_TOKEN,
  FETCH_TRACKING_DATA_BY_TOKEN
}
