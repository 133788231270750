import React from 'react'
import _map from 'lodash/map'
import _uniqBy from 'lodash/uniqBy'
import Button from '../../../../_library/Button'
import { Field } from 'formik'
import { SelectField } from '../../../../formik/Fields/SelectField'
import { requiredValidator } from '../../../../../_common/core/validation'

export default class BrandRow extends React.Component {
  render() {
    const { organizations, onClickNewBrand, values, onChange } = this.props
    let filteredOrg = _map(_uniqBy(organizations, 'id'), item => ({
      value: item.id,
      label: item.displayName
    }))
    filteredOrg = filteredOrg.sort((a, b) => a.label.trim().localeCompare(b.label))

    return (
      <div className="row">
        <div className="row-brand col-xs-12">
          <div className={'dropdown-brand '}>
            <Field
              name="owner"
              label="Brand"
              options={filteredOrg}
              defaultOption={
                values.owner ? values.owner : { label: 'Select the brand running this event', value: '' }
              }
              component={SelectField}
              validate={requiredValidator()}
              onChange={onChange}
            />
          </div>
          <div className="btn-create-brand">
            <Button className="btn btn-default btn-shadow" type="button" onClick={onClickNewBrand}>
              Create Brand
            </Button>
          </div>
        </div>
      </div>
    )
  }
}
