const metaMask = new class {
    #provider
    
    constructor() {
        if (typeof window.ethereum !== 'undefined' && window.ethereum.isMetaMask) {
            this.#provider = window.ethereum
        }
    }

    get isInstalled() {
        return Boolean(this.#provider)
    }

    getAccounts = async () => {
        const accounts = await this.#provider.request({ method: 'eth_requestAccounts' })
        return accounts
    }

    getFirstAccount = async () => {
        const accounts = await this.getAccounts()
        const account = accounts[0]
        return account
    }
}

export {metaMask}