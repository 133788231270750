import handleActions from 'redux-actions/lib/handleActions'
import { createAsyncHandlers } from '../actions'

const initialState = {
  supportInfo: {}
}

const FETCH_EVENT_SUPPORT = createAsyncHandlers('FETCH_EVENT_SUPPORT', {
  success(state, action) {
    return state.supportInfo = action.payload
  }
})

const UPDATE_EVENT_SUPPORT = createAsyncHandlers('UPDATE_EVENT_SUPPORT', {
  success(state) {
    return state
  }
})

export default handleActions(
  {
    ...FETCH_EVENT_SUPPORT,
    ...UPDATE_EVENT_SUPPORT
  },
  initialState
)
