import _get from 'lodash/get'
import handleActions from 'redux-actions/lib/handleActions'

import { createAsyncHandlers } from '../actions'

const initialState = {
  launchDarklyFlags: {}
}

const SET_LD_FLAGS = createAsyncHandlers('SET_LD_FLAGS', {
  success(state, action) {
    const data = _get(action, 'payload.data')
    return state.launchDarklyFlags = { ...data }
  }
})

export default handleActions(
  {
    ...SET_LD_FLAGS
  },
  initialState
)
