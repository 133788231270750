import _get from 'lodash/get'
import handleActions from 'redux-actions/lib/handleActions'
import { createAsyncHandlers } from '../actions'

const initialState = {
  talent_search: {},
  talent_event: {},
}

const SEARCH_TALENT = createAsyncHandlers('SEARCH_TALENT', {
  success(state, action) {
    state.talent_search = action.payload
  }
})

const GET_EVENT_TALENT = createAsyncHandlers('GET_EVENT_TALENT', {
  success(state, action) {
  	const { data } = action.payload
    state.talent_event = _get(data, '$original.talent')
  }
})

const GET_TALENT = createAsyncHandlers('GET_TALENT', {
  success(state, action) {
    const { data } = action.payload
    state.talent_indi = _get(data, '$original')
  }
})


export default handleActions({
  ...SEARCH_TALENT,
  ...GET_EVENT_TALENT,
  ...GET_TALENT,
}, initialState)

