import {createAsyncAction} from '../actions'
import {fetchAPI, formatFiltersAsParam} from '../../core/http'
import {ERROR} from '../notification/actions'

const { FETCH_TOUR_TEAM_INVITATIONS } = createAsyncAction('FETCH_TOUR_TEAM_INVITATIONS', function(eid) {
  return (dispatch) => fetchAPI(`/api/tour/${eid}/members/invitations`)
    .catch((err) => {
      dispatch(ERROR(...err.errors))
      dispatch(this.failed(err))
      throw err
    })
    .then((res) => {
      dispatch(this.success(res))
      return res
    })
})

const { ADD_TOUR_TEAM_INVITATION } = createAsyncAction('ADD_TOUR_TEAM_INVITATION', function(eid, form) {
  return (dispatch) => {
    form.attributes.tourId = parseInt(eid)
    return fetchAPI('/api/team-invitations', {
      method: 'POST',
      body: JSON.stringify({ data: { ...form } })
    })
      .catch((err) => {
        dispatch(ERROR(...err.errors))
        dispatch(this.failed(err))
        throw err
      })
      .then((res) => {
        dispatch(this.success(res))
        return res
      })
  }
})

const { FETCH_TOUR_TEAM_MEMBERS } = createAsyncAction('FETCH_TOUR_TEAM_MEMBERS', function(eid) {
  return (dispatch) => fetchAPI(`/api/tour/${eid}/members`)
    .catch((err) => {
      dispatch(ERROR(...err.errors))
      dispatch(this.failed(err))
      throw err
    })
    .then((res) => {
      dispatch(this.success(res))
      return res
    })
})

const { RESEND_TOUR_TEAM_INVITATION } = createAsyncAction('RESEND_TOUR_TEAM_INVITATION', function(tid, email) {
  return (dispatch) => fetchAPI(`/api/tour/${tid}/team-invitation/reminder`, {
    method: 'POST',
    body: JSON.stringify({
      data: {
        attributes: {
          emails: [email]
        }
      }
    })
  })
    .catch((err) => {
      dispatch(ERROR(...err.errors))
      dispatch(this.failed(err))
      throw err
    })
    .then((res) => {
      dispatch(this.success(res))
      return res
    })
})

const {FETCH_TOUR_PROMOTER_GENERIC_INVITATION} = createAsyncAction('FETCH_TOUR_PROMOTER_GENERIC_INVITATION', function (eid) {
  return (dispatch) => {
    const filter = formatFiltersAsParam({type: 'generic'})

    return fetchAPI(`/api/tour/${eid}/members/invitations`, {
      params: {
        ...filter
      }
    })
      .catch((err) => {
        dispatch(ERROR(...err.errors))
        dispatch(this.failed(err))
        throw err
      })
      .then((res) => {
        let promoterInvitation = _.find(res.data, invitation => invitation.role === 'promoter')
        dispatch(this.success(promoterInvitation))
        return promoterInvitation
      })
  }
})

const {REGENERATE_PROMOTER_GENERIC_INVITATION_TOUR} = createAsyncAction('REGENERATE_PROMOTER_GENERIC_INVITATION_TOUR', function (token) {
  return (dispatch) => {
    return fetchAPI(`/api/team-invitations/regenerate`, {
      method: "POST",
      params: {
        token
      }
    })
      .catch((err) => {
        dispatch(ERROR(...err.errors))
        dispatch(this.failed(err))
        throw err
      })
      .then((res) => {
        dispatch(this.success(res.data.token))
        return res.data.token
      })
  }
})

const {UPDATE_TOUR_PROMOTER_GENERIC_INVITATION} = createAsyncAction('UPDATE_TOUR_PROMOTER_GENERIC_INVITATION', function (token, form) {
  return (dispatch) => {
    return fetchAPI(`/api/team-invitations/${token}`, {
      method: "PUT",
      body: JSON.stringify({data: {attributes: {...form}}}),
    })
      .catch((err) => {
        dispatch(ERROR(...err.errors))
        dispatch(this.failed(err))
        throw err
      })
      .then((res) => {
        dispatch(this.success(res))
        return res
      })
  }
})

export {
  FETCH_TOUR_TEAM_INVITATIONS,
  ADD_TOUR_TEAM_INVITATION,
  FETCH_TOUR_TEAM_MEMBERS,
  RESEND_TOUR_TEAM_INVITATION,
  FETCH_TOUR_PROMOTER_GENERIC_INVITATION,
  REGENERATE_PROMOTER_GENERIC_INVITATION_TOUR,
  UPDATE_TOUR_PROMOTER_GENERIC_INVITATION
}

