import React from 'react'

import TourPage from '../tours/TourPage'


const tourWrapper = (C) => (props) => (
  <TourPage>
    <C {...props}/>
  </TourPage>
)

export { tourWrapper }