/* eslint-disable */
import { ENV } from '../../web/constants/env'

global.dataLayerInit = function dataLayerInit() {
  window.dataLayer = window.dataLayer || []
}

global.gtag = function gtag() {
  window.dataLayer.push(arguments)
}

global.runGtagScript = function runGtagScript() {
  const gaTrackingId = ENV.GA_TRACKING_ID
  if (!gaTrackingId) return
  gtag('js', new Date())
  gtag('config', gaTrackingId)
  gtag('event', 'conversion', { send_to: gaTrackingId })
}

export const movedToSignIn = () => {
  isProd() &&
  gtag('event', 'redirect_to_sign_in_page', {
    'event_category' : 'sign_up',
    'event_label' : 'User has account'
  });
}

export const movedToCreateAccount = () => {
  isProd() &&
  gtag('event', 'redirect_to_create_account_page', {
    'event_category' : 'sign_up',
    'event_label' : 'User has no account, starting new signup'
  });
}

export const movedToCreateBrand = () => {
  isProd() &&
  gtag('event', 'user_data_filled', {
    'event_category' : 'create_account',
    'event_label' : 'All data filled'
  });
}

export const movedToTeamInvitations = brandName => {
  isProd() &&
  gtag('event', 'brand_data_filled', {
    'event_category' : 'create_brand',
    'event_label' : `Brand ${brandName} and account created successfully`
  });
}

export const movedToPayoutBySendingInvitations = () => {
  gtag('event', 'team_invitations_sent_in_sign_up', {
    'event_category' : 'team_invitations',
    'event_label' : 'Team invitations sent'
  });
}

export const movedToPayoutBySkipingTeamInvitations = () => {
  gtag('event', 'team_invitations_skipped_in_sign_up', {
    'event_category' : 'team_invitations',
    'event_label' : 'Team invitations skipped'
  });
}

export const movedToHomeBySkipingPayout = () => {
  isProd() && gtag('event', 'team_invitations_skipped_in_sign_up', {
    'event_category' : 'team_invitations',
    'event_label' : 'Payout mode skipped'
  });
}

export const movedToHomeBySettingStripe = () => {
  isProd() && gtag('event', 'stripe_connected_to_brand_in_sign_up', {
    'event_category' : 'payout_method',
    'event_label' : 'User connected his stripe account to brand created in welcome sign up'
  });
}

export const movedToHomeBySettingPaymentDetails = () => {
  isProd() && gtag('event', 'payment_details_setted_in_sign_up', {
    'event_category' : 'payout_method',
    'event_label' : 'User setted his payment details in brand created in welcome sign up'
  });
}