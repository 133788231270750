import React from 'react'

import BrandPage from '../brands/BrandPage'

const brandWrapper = (C) => (props) => (
  <BrandPage>
    <C {...props} isBrand />
  </BrandPage>
)

export { brandWrapper }