export const getBrandsMenuItems = ({ page, isBrand, isBrandsPage, isNewBrandPage }) => {
  const menuGroup = []

  menuGroup.push({
    img: 'brands',
    title: 'Brands',
    href: `/brands`,
    active: isBrandsPage || isNewBrandPage || (isBrand && page !== ''),
    visible: true
  })

  return menuGroup
}
