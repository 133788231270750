import React from 'react'
import { Link } from 'react-router-dom'
import _get from 'lodash/get'
import { getTitle } from '../../utils/getTitle'

// styles
import './not-found-page.less'


class NotFoundPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    
    const configDocTitle = _get(this.props.configs, 'messages.documentTitle', '')
    document.title = getTitle(configDocTitle)
  }
  render() {
    return (
      <div id="notfound">
        <div className="notfound">
          <div className="notfound-404">
            <h1>404</h1>
            <h2>Page not found</h2>
          </div>
          <Link to="/">Homepage</Link>
        </div>
      </div>
    )
  }
}

export { NotFoundPage }
