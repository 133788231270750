import cx from 'classnames'

const Disabled = (props) => {
    const {
        children,
        disabled=false,
        opacity=0.3,
        className='',
        style={}
    } = props

    const _className = cx('disabled_wrapper', className, {
        disabled,
    })

    const _style = {...style}
    if (disabled) {
        _style.opacity = opacity
    }

    return (
        <div
            className={_className}
            style={_style}
        >
            {children}
        </div>
    )
}

export default Disabled