import _get from 'lodash/get'
import handleActions from 'redux-actions/lib/handleActions'
import { createAsyncHandlers } from '../actions'

const initialState = {
}

const FETCH_PROMOTIONS = createAsyncHandlers('FETCH_PROMOTIONS', {
  success(state, action) {    
    const { eid, promotions } = action.payload
    state[eid] = _get(promotions, 'data.$original.promotions')
  }
})

export default handleActions({
  ...FETCH_PROMOTIONS,
}, initialState)

