import handleActions from 'redux-actions/lib/handleActions'
import { createAsyncHandlers } from '../actions'

const initialState = {
  credentialsData: {
    checked: false,
    exist: false,
    data: {}
  },
}

//ACTIONS
const FETCH_CREDENTIALS = createAsyncHandlers('FETCH_CREDENTIALS', {
  success(state, action) {    
    const { data } = action.payload
    state.credentialsData = {
      data,
      checked: true,
      exist: true
    }
  },
  failed(state) {
    state.credentialsData = {
      data: {},
      checked: true,
      exist: false
    }
  }
})
const CREATE_CREDENTIALS = createAsyncHandlers('CREATE_CREDENTIALS', {
  success(state, action) {    
    const { data } = action.payload
    state.credentialsData = {
      data,
      checked: true,
      exist: true
    }
  },
  failed(state) {
    state.credentialsData = {
      data: {},
      checked: true,
      exist: false
    }
  }
})
const ACTIVATE_DEACTIVATE_CREDENTIALS = createAsyncHandlers('ACTIVATE_DEACTIVATE_CREDENTIALS', {
  success(state, action) {    
    const { data } = action.payload
    state.credentialsData = {
      data,
      checked: true,
      exist: true
    }
  },
  failed(state) {
    state.credentialsData = {
      data: {},
      checked: true,
      exist: false
    }
  }
})

const UPDATE_CREDENTIALS = createAsyncHandlers('UPDATE_CREDENTIALS', {
  success(state, action) {    
    const { data } = action.payload
    state.credentialsData = {
      data,
      checked: true,
      exist: true
    }
  },
  failed(state) {
    state.credentialsData = {
      data: {},
      checked: true,
      exist: false
    }
  }
})
//RULES
const FETCH_CREDENTIALS_RULES = createAsyncHandlers('FETCH_CREDENTIALS_RULES', {
  success(state, action) {    
    const { data } = action.payload

    state.credentialsData = {
      data,
      checked: true,
      exist: true
    }
  },
  failed(state) {
    state.credentialsData = {
      data: {},
      checked: true,
      exist: false
    }
  }
})
const DELETE_CREDENTIALS = createAsyncHandlers('DELETE_CREDENTIALS', {
  success(state, action) {    
    const { data } = action.payload
    state.credentialsData = {
      data,
      checked: true,
      exist: true
    }
  },
  failed(state) {
    state.credentialsData = {
      data: {},
      checked: true,
      exist: false
    }
  }
})

const DELETE_CREDENTIALS_RULES = createAsyncHandlers('DELETE_CREDENTIALS_RULES', {
  success(state, action) {    
    const { data } = action.payload
    state.credentialsData = {
      data,
      checked: true,
      exist: true
    }
  },
  failed(state) {
    state.credentialsData = {
      data: {},
      checked: true,
      exist: false
    }
  }
})

const CREATE_CREDENTIALS_RULES = createAsyncHandlers('CREATE_CREDENTIALS_RULES', {
  success(state, action) {    
    const { data } = action.payload
    state.credentialsData = {
      data,
      checked: true,
      exist: true
    }
  },
  failed(state) {
    state.credentialsData = {
      data: {},
      checked: true,
      exist: false
    }
  }
})
const UPDATE_CREDENTIALS_RULES = createAsyncHandlers('UPDATE_CREDENTIALS_RULES', {
  success(state, action) {    
    const { data } = action.payload
    state.credentialsData = {
      data,
      checked: true,
      exist: true
    }
  },
  failed(state) {
    state.credentialsData = {
      data: {},
      checked: true,
      exist: false
    }
  }
})

export default handleActions({
  ...FETCH_CREDENTIALS,
  ...CREATE_CREDENTIALS,
  ...FETCH_CREDENTIALS_RULES,
  ...ACTIVATE_DEACTIVATE_CREDENTIALS,
  ...DELETE_CREDENTIALS,
  ...DELETE_CREDENTIALS_RULES,
  ...CREATE_CREDENTIALS_RULES,
  ...UPDATE_CREDENTIALS,
  ...UPDATE_CREDENTIALS_RULES,
}, initialState)