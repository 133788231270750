import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'

import { withRouter } from 'react-router-dom'
import { RouteLeavingGuard } from '../_library/RouteLeavingGuard'

import { UPDATE_TOUR, FETCH_TOURS } from '../../_common/redux/tours/actions'
import {
  SHOPIFY_CHECK_CONNECT,
  SHOPIFY_CONNECT,
  SHOPIFY_DISCONNECT,
  SHOPIFY_UPDATE,
  SHOPIFY_REFRESH,
} from '../../_common/redux/shopify/actions'
import TourForm from './TourForm'


import { CARD_STATUS_UNCHANGED, CARD_STATUS_CHANGED, CARD_STATUS_SAVED } from '../../_common/core/form_helper'
import { getTitle } from '../utils/getTitle'

@withRouter
@connect(
  (state) => {
    const { user } = state.auth
    const { events } = state.events
    const tour = state.tours.selected
    const { tours } = state.tours
    const eventsFullReady = state.events.fullReady
    const tourShopifyInfo = state.shopify.tour
    const shopifyConnectLoading = state.loading.has('SHOPIFY_CONNECT')
    const shopifyDisconnectLoading = state.loading.has('SHOPIFY_DISCONNECT')
    const shopifyRefreshLoading = state.loading.has('SHOPIFY_REFRESH')
    const fetchProductsLoading = state.loading.has('FETCH_PRODUCTS')
    const dynamicConfigs = state.launchDarklyInfo.launchDarklyFlags

    return {
      user,
      tour,
      tours,
      events,
      eventsFullReady,
      tourShopifyInfo,
      dynamicConfigs,
      shopifyConnectLoading,
      shopifyDisconnectLoading,
      shopifyRefreshLoading,
      fetchProductsLoading,
    }
  },
  {
    UPDATE_TOUR,
    FETCH_TOURS,
    SHOPIFY_CHECK_CONNECT,
    SHOPIFY_CONNECT,
    SHOPIFY_DISCONNECT,
    SHOPIFY_UPDATE,
    SHOPIFY_REFRESH,
  }
)
export default class TourDetails extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      hasEdittedFields: false,
      nextLocation: null,
    }
    this.cardsStatus = {
      details: CARD_STATUS_UNCHANGED,
      description: CARD_STATUS_UNCHANGED,
      images: CARD_STATUS_UNCHANGED,
      events: CARD_STATUS_UNCHANGED,
    }

    form_helper_reset()
  }

  componentDidMount() {
    const { FETCH_TOURS, SHOPIFY_CHECK_CONNECT, tour: { id, displayName } } = this.props

    FETCH_TOURS()

    Messenger.options = {
      extraClasses: 'messenger-fixed messenger-on-top messenger-on-right',
      theme: 'future'
    }

    const configDocTitle = _.get(this.props.configs, 'messages.documentTitle', '')
    document.title = getTitle(configDocTitle, [displayName])
    SHOPIFY_CHECK_CONNECT(id, 'tour')
  }

  shopifyConnectSubmit = (url) => {
    const { SHOPIFY_CONNECT, tour } = this.props
    SHOPIFY_CONNECT(tour.id, 'tour', url)
  }
  onDisconnectShopify = () => {
    const { SHOPIFY_DISCONNECT, tour } = this.props
    SHOPIFY_DISCONNECT(tour.id, 'tour')
  }
  onSopifyUpdate = (newAddedIds) => {
    const { SHOPIFY_UPDATE, tour } = this.props
    return SHOPIFY_UPDATE(tour.id, 'tour', newAddedIds)
  }
  onSopifyRefresh = () => {
    const { SHOPIFY_REFRESH, tour } = this.props
    return SHOPIFY_REFRESH(tour.id, 'tour')
  }

  componentWillUnmount(){
    form_helper_reset()
    this.isUnmounted = true
  }

  changeCardStatusToNormal = () => {
    if (this.cardsStatus.details !== CARD_STATUS_UNCHANGED) {
      this.cardsStatus.details = CARD_STATUS_SAVED
      setTimeout(() => {
        this.cardsStatus.details = CARD_STATUS_UNCHANGED
        this.forceUpdate()
      }, 3000)
    }
    if (this.cardsStatus.description !== CARD_STATUS_UNCHANGED) {
      this.cardsStatus.description = CARD_STATUS_SAVED
      setTimeout(() => {
        this.cardsStatus.description = CARD_STATUS_UNCHANGED
        this.forceUpdate()
      }, 3000)
    }
    if (this.cardsStatus.images !== CARD_STATUS_UNCHANGED) {
      this.cardsStatus.images = CARD_STATUS_SAVED
      setTimeout(() => {
        this.cardsStatus.images = CARD_STATUS_UNCHANGED
        this.forceUpdate()
      }, 3000)
    }
    if (this.cardsStatus.events !== CARD_STATUS_UNCHANGED) {
      this.cardsStatus.events = CARD_STATUS_SAVED
      setTimeout(() => {
        this.cardsStatus.events = CARD_STATUS_UNCHANGED
        this.forceUpdate()
      }, 3000)
    }
    this.forceUpdate()
  }

  routerWillLeave = () => !form_helper_isEditted()

  handleSubmit = form => {
    const { UPDATE_TOUR, tour } = this.props

    return Promise.resolve(UPDATE_TOUR(tour.id, form))
      .catch((err) => {
        Messenger().post({
          type: 'error',
          message: err,
          hideAfter: 3,
          showCloseButton: true
        })
        return Promise.reject(_.result(err, 'toFieldErrors', err))
      })
      .then((v) => {
        Messenger().post({
          type: 'success',
          message: 'Saved',
          hideAfter: 3,
          showCloseButton: true
        })
        form_helper_reset()
        this.changeCardStatusToNormal()
        return v
      })
  }

  render() {
    const {
      eventsLoading,
      eventsFullReady,
      tourShopifyInfo,
      dynamicConfigs,
      shopifyConnectLoading,
      shopifyDisconnectLoading,
      shopifyRefreshLoading,
      fetchProductsLoading,
      user,
      tour,
      tours,
      events,
      configs,
    } = this.props

    const contentEdittedFields = _.map(form_helper_get(), (field, index)=>{
      let field_title = ''
      switch(field){
      case 'attributes.displayName': 
        field_title = 'Name'
        this.cardsStatus.details = CARD_STATUS_CHANGED
        break
      case 'attributes.slug':
        field_title = 'URL'
        this.cardsStatus.details = CARD_STATUS_CHANGED
        break
      case 'attributes.description':
        field_title = 'Description'
        this.cardsStatus.description = CARD_STATUS_CHANGED
        break
      case 'attributes.bannerURL':
        field_title = 'Banner Image'
        this.cardsStatus.images = CARD_STATUS_CHANGED
        break
      case 'attributes.backgroundURL':
        field_title = 'Background Image'
        this.cardsStatus.images = CARD_STATUS_CHANGED
        break
      case 'attributes.events':
        field_title = 'Events'
        this.cardsStatus.events = CARD_STATUS_CHANGED
        break
      default:
        field_title = field
        break
      }
      return (
        <div key={index}><i className="fa fa-info-circle" aria-hidden="true" /> {field_title}</div>
      )
    })

    return (
      <div>
        <RouteLeavingGuard
          routerWillLeave={this.routerWillLeave}
        />
        <TourForm
          formKey={String(tour.id)}
          initialValues={
            _.merge(tour.$original, {
              attributes: {
                description: mediumEditor_revert(tour.$original.attributes.description)
              }
            })}
          submitLabel="Save"
          onSubmit={this.handleSubmit}
          user={user}
          events={events}
          tours={tours}
          eventsFullReady={eventsFullReady}
          tour={tour}
          shopifyConnectSubmit={this.shopifyConnectSubmit}
          onDisconnectShopify={this.onDisconnectShopify}
          onSopifyUpdate={this.onSopifyUpdate}
          onSopifyRefresh={this.onSopifyRefresh}
          shopifyInfo={tourShopifyInfo}
          shopifyConnectLoading={shopifyConnectLoading}
          shopifyDisconnectLoading={shopifyDisconnectLoading}
          shopifyRefreshLoading={shopifyRefreshLoading}
          fetchProductsLoading={fetchProductsLoading}
          eventsLoading={eventsLoading}
          cardsStatus={this.cardsStatus}
          dynamicConfigs={dynamicConfigs}
          configs={configs}
        />
      </div>
    )
  }
}
