import { createAsyncAction } from '../actions'
import _get from 'lodash/get'
import { fetchAPI } from '../../core/http'
import { ERROR } from '../notification/actions'

const { FETCH_GIFT_CARD_TYPES } = createAsyncAction('FETCH_GIFT_CARD_TYPES', function(eventId) {
  return dispatch => fetchAPI(`/api/event/${eventId}/gift-cards/`)
    .catch(err => {
      dispatch(ERROR(...err.errors))
      dispatch(this.failed(err))
      throw err
    })
    .then(res => {
      const giftCardTypes = res.data.map(el => el.$original)
      dispatch(this.success({ giftCardTypes }))
      return res
    })
})

const { ISSUE_GIFT_CARD } = createAsyncAction('ISSUE_GIFT_CARD', function(eventId, form) {
  return dispatch => fetchAPI(`/api/event/${eventId}/gift-card/issue`, {
    method: 'POST',
    body: JSON.stringify({
      data: {
        attributes: {
          ...form
        }
      }
    })
  })
    .catch(err => {
      dispatch(ERROR(...err.errors))
      dispatch(this.failed(err))
      throw err
    })
    .then(res => res)
})

const { FETCH_ISSUED_GIFT_CARDS } = createAsyncAction('FETCH_ISSUED_GIFT_CARDS', function(eventId) {
  return dispatch => fetchAPI(`/api/event/${eventId}/gift-cards/customers?type=issued`)
    .catch(err => {
      dispatch(ERROR(...err.errors))
      dispatch(this.failed(err))
      throw err
    })
    .then(res => {
      const issuedCards = _get(res, 'data', [])
      dispatch(this.success({ issuedCards }))
      return res
    })
})

const { CREATE_GIFT_CARD } = createAsyncAction('CREATE_GIFT_CARD', function(eventId, form) {
  return dispatch => fetchAPI(`/api/event/${eventId}/gift-card`, {
    method: 'POST',
    body: JSON.stringify({
      data: {
        attributes: {
          ...form
        }
      }
    })
  })
    .catch(err => {
      dispatch(ERROR(...err.errors))
      dispatch(this.failed(err))
      throw err
    })
    .then(res => res)
})

const { UPDATE_GIFT_CARD } = createAsyncAction('UPDATE_GIFT_CARD', function(eventId, giftCardId, form) {
  return dispatch => fetchAPI(`/api/event/${eventId}/gift-card/${giftCardId}`, {
    method: 'PATCH',
    body: JSON.stringify({
      data: {
        attributes: {
          ...form
        }
      }
    })
  })
    .catch(err => {
      dispatch(ERROR(...err.errors))
      dispatch(this.failed(err))
      throw err
    })
    .then(res => res)
})

const { DELETE_GIFT_CARD } = createAsyncAction('DELETE_GIFT_CARD', function(eventId, giftCardId) {
  return dispatch => fetchAPI(`/api/event/${eventId}/gift-card/${giftCardId}`, {
    method: 'DELETE',
  })
    .catch(err => {
      dispatch(ERROR(...err.errors))
      dispatch(this.failed(err))
      throw err
    })
    .then(res => res)
})

export {
  FETCH_GIFT_CARD_TYPES,
  ISSUE_GIFT_CARD,
  FETCH_ISSUED_GIFT_CARDS,
  CREATE_GIFT_CARD,
  UPDATE_GIFT_CARD,
  DELETE_GIFT_CARD,
}
