import _map from 'lodash/map'
import _get from 'lodash/get'
import _filter from 'lodash/filter'
import handleActions from 'redux-actions/lib/handleActions'
import { createAsyncHandlers } from '../actions'

const initialState = {
  budgets: {},
}

const checkID = (a, b) => a.toString() === b.toString()

const findAndReplace = (sections, selectedId, isRow, isAdd, isDelete, form) => {
  let newSections = []
  if (!isRow) { // section
    if (isDelete) {
      let found = false
      _map(sections, section=>{
        if (checkID(section.id, selectedId)) {
          found = true
        }
      })
      if (found) {
        newSections = _filter(sections, s => s.id.toString() !== selectedId.toString())
      } else {
        newSections = _map(sections, section => {
          const newSection = Object.assign({}, section)
          if (newSection.subsections && newSection.subsections.length > 0) {
            newSection.subsections = findAndReplace(newSection.subsections, selectedId, isRow, isAdd, isDelete, form)
          }
          return newSection
        })
      }
      return newSections
    } 
    newSections = _map(sections, section => {
      const newSection = Object.assign({}, section)
      if (checkID(newSection.id, selectedId)) {
        if (isAdd) {
          newSection.subsections.push({
            ...form
          })
        } else {
          newSection.sectionName = form.sectionName
        }
      } else {
        if (newSection.subsections && newSection.subsections.length > 0) {
          newSection.subsections = findAndReplace(newSection.subsections, selectedId, isRow, isAdd, isDelete, form)
        }
      }
      return newSection
    })
    
  } else { // row
    newSections = _map(sections, section => {
      const newSection = Object.assign({}, section)
      if (checkID(newSection.id, selectedId)) {
        if (isDelete) {
          newSection.items = _filter(newSection.items, r => r.id.toString() !== form.id.toString())
        } else {
          if (isAdd) {
            newSection.items.push({
              ...form
            })
          } else {
            let newRows = []
            newRows = _map(newSection.items, row => {
              const newRow = Object.assign({}, row)
              if (checkID(newRow.id, form.id)) {
                newRow.name = form.name
                newRow.cost = form.cost
                newRow.type = form.type
                newRow.category = form.category
                newRow.responsibleUsers = form.responsibleUsers
              }
              return newRow
            })
            newSection.items = newRows
          }
        }
      } else {
        if (newSection.subsections && newSection.subsections.length > 0) {
          newSection.subsections = findAndReplace(newSection.subsections, selectedId, isRow, isAdd, isDelete, form)
        }
      }
      return newSection
    })
  }
  return newSections
}

const FETCH_SECTIONS = createAsyncHandlers('FETCH_SECTIONS', {
  success(state, action) {
    const { eid, sections: { data } } = action.payload
    state.budgets[eid] = _get(data, '$original.attributes')
  }
})

const CREATE_SECTION = createAsyncHandlers('CREATE_SECTION', {
  success(state, action) {
    const { data } = action.payload
    const sectionData = {
      id: data.id,
      parentId: data.parentId,
      eventId: data.eventId,
      completedAt: data.completedAt,
      createdAt: data.createdAt,
      description: data.description,
      sectionName: data.name,
      ownerId: data.ownerId,
      type: data.type,
      items: [],
      subsections: [],
    }

    if (state.budgets[data.eventId]) {
      if (!data.parentId) {
        state.budgets[data.eventId].push(sectionData)
      } else {
        state.budgets[data.eventId] = findAndReplace(state.budgets[data.eventId], data.parentId, false, true, false, sectionData)
      }
    }
  }
})

const UPDATE_SECTION = createAsyncHandlers('UPDATE_SECTION', {
  success(state, action) {
    const { data } = action.payload
    const sectionData = {
      id: data.id,
      parentId: data.parentId,
      eventId: data.eventId,
      completedAt: data.completedAt,
      createdAt: data.createdAt,
      description: data.description,
      sectionName: data.name,
      ownerId: data.ownerId,
    }
    if (state.budgets[data.eventId]) {
      state.budgets[data.eventId] = findAndReplace(state.budgets[data.eventId], data.id, false, false, false, sectionData)
    }
  }
})

const DELETE_SECTION = createAsyncHandlers('DELETE_SECTION', {
  success(state, action) {
    const { eid, sid } = action.payload
    if (state.budgets[eid]) {
      state.budgets[eid] = findAndReplace(state.budgets[eid], sid, false, false, true, null)
    }
  }
})

const CREATE_ITEM = createAsyncHandlers('CREATE_ITEM', {
  success(state, action) {
    const { data, eid } = action.payload
    const itemData = {
      id: data.id,
      sectionId: data.sectionId,
      completedAt: data.completedAt,
      createdAt: data.createdAt,
      description: data.description,
      sectionName: data.name,
      customerId: data.customerId,
      isApproved: data.isApproved,
      type: data.$original.attributes.type === true || data.$original.attributes.type === 'expense' ? 'expense' : 'revenue',
      name: data.name,
      category: data.category,
      responsibleUsers: data.responsibleUsers,
      cost: data.cost,
    }

    if (state.budgets[eid]) {
      state.budgets[eid] = findAndReplace(state.budgets[eid], data.sectionId, true, true, false, itemData)
    }
  }
})

const UPDATE_ITEM = createAsyncHandlers('UPDATE_ITEM', {
  success(state, action) {
    const { data, eid } = action.payload

    const itemData = {
      id: data.id,
      sectionId: data.sectionId,
      completedAt: data.completedAt,
      createdAt: data.createdAt,
      description: data.description,
      sectionName: data.name,
      customerId: data.customerId,
      isApproved: data.isApproved,
      type: data.$original.attributes.type === true || data.$original.attributes.type === 'expense' ? 'expense' : 'revenue',
      name: data.name,
      category: data.category,
      responsibleUsers: data.responsibleUsers,
      cost: data.cost,
    }

    if (state.budgets[eid]) {
      state.budgets[eid] = findAndReplace(state.budgets[eid], data.sectionId, true, false, false, itemData)
    }
  }
})

const DELETE_ITEM = createAsyncHandlers('DELETE_ITEM', {
  success(state, action) {
    const { eid, sid, id } = action.payload
    if (state.budgets[eid]) {
      state.budgets[eid] = findAndReplace(state.budgets[eid], sid, true, false, true, { id })
    }
  }
})

export default handleActions({
  ...FETCH_SECTIONS,
  ...CREATE_SECTION,
  ...UPDATE_SECTION,
  ...DELETE_SECTION,
  ...CREATE_ITEM,
  ...UPDATE_ITEM,
  ...DELETE_ITEM,
}, initialState)