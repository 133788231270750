// Re-render Optimization 14.05.2020
import React from 'react'
import oboe from 'oboe'
import _each from 'lodash/each'
import _map from 'lodash/map'
import moment from 'moment-timezone'
import { Link } from 'react-router-dom'
import { makeURL } from '../../../_common/core/http'
import {
  HTTP_INIT,
  HTTP_LOADING,
  HTTP_LOADING_SUCCESSED,
  HTTP_LOADING_FAILED,
  CACHE_SIZE
} from '../../../_common/core/http'
import NumberAnimation from '../../_library/NumberAnimation'

import { ROLES } from '../../constants/roles'
import { createSlowEventHandler } from '../../../_common/core/utils'
import { checkIsLimitedStats, checkIsPromoter } from '../../utils/permissions'
import { getInfluencersData } from '../../../_common/core/http_services/events'
import { getCookieByName } from '../../../web/utils/cookies'

const ANIMATION_DURATION = 1500

export default class EventTicketStatistics extends React.PureComponent {
  constructor(props) {
    super(props)

    this.tmp = []
    this.refreshFlag = false
    this.refreshTimer = null
    this.unMounted = true

    this.isFirstTime = true
    this.yesterdaySold = 0
    this.initSold = 0
    this.initRevenue = 0
    this.initTotal = 0
    this.ticketsLocked = 0
    this.buyers = 0
    this.initRebates = 0

    this.state = {
      http_status: HTTP_INIT,
      http_error: null,
      rows: [],
      ticketStat: null
    }

    this.handleScroll = createSlowEventHandler(this.handleScroll, 1000)
  }

  componentDidMount() {
    const { autoRefresh } = this.props
    window.addEventListener('scroll', this.handleScroll)
    this.unMounted = false
    this.init(true)

    if (autoRefresh && autoRefresh > 1000) {
      this.refreshTimer = setInterval(() => {
        if (
          (this.state.http_status === HTTP_LOADING_SUCCESSED ||
            this.state.http_status === HTTP_LOADING_FAILED) &&
          !this.refreshFlag
        ) {
          const { event } = this.props
          if (!!event.salesEndDate && moment().isBefore(moment(event.salesEndDate))) {
            this.refreshFlag = true
            this.init(false)
          }
          // this.setState({http_status: this.state.http_status})
        }
      }, autoRefresh + Math.random() * 5000)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
    this.unMounted = true
    this.refreshFlag = false
    if (this.refreshTimer) clearInterval(this.refreshTimer)
  }

  handleScroll = () => {
    const { ticketStat, http_status } = this.state
    const isLoading = http_status === HTTP_LOADING && !this.refreshFlag
    if (this.unMounted || ticketStat || isLoading) {
      return
    }
    const isVisible = this.isInVisibleArea()
    if (isVisible) {
      this.init(true)
    }
  }

  isInVisibleArea() {
    const ele = window.$(this.refs.statContainer)
    if (!ele) return false
    const windowTop = window.$(window).scrollTop()
    const windowBottom = window.$(window).scrollTop() + window.$(window).height()
    const elementTop = ele.offset().top
    const elementBottom = ele.offset().top + ele.height()
    return elementTop >= windowTop ? elementTop <= windowBottom : elementBottom >= windowTop
  }

  init(isInitial) {
    const { event } = this.props

    const isVisible = this.isInVisibleArea()
    if (!isVisible) {
      if (!isInitial) {
        this.refreshFlag = false
      }
      return
    }

    this.tmp = []

    const url = `/api/events/${event.id}/relationships/performance/`
    const param = { section: 'sales|carts|add_on_breakdown' }
    const salesNode = 'data.sales.*'
    const cartsNode = 'data.carts'
    const addonNode = 'data.add_on_breakdown.*'

    this.setState(() => ({
      http_status: HTTP_LOADING
    }))

    oboe({
      url: makeURL(url, param),
      method: 'GET',
      headers: {
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        'X-TF-ECOMMERCE': getCookieByName('X-TF-ECOMMERCE')
      },
      withCredentials: true
    })
      .node(salesNode, record => {
        if (!this.unMounted) {
          this.tmp.push(record)
          if (this.tmp.length === CACHE_SIZE && !this.refreshFlag) {
            this.addToRow(this.tmp, HTTP_LOADING, null)
          }
        }
      })
      .node(addonNode, record => {
        if (!this.unMounted) {
          this.tmp.push(record)
          if (this.tmp.length === CACHE_SIZE && !this.refreshFlag) {
            this.addToRow(this.tmp, HTTP_LOADING, null)
          }
        }
      })
      .node(cartsNode, (record, path) => {
        if (!this.unMounted) {
          this.tmp.push({ [path[1]]: record })
          if (this.tmp.length === CACHE_SIZE && !this.refreshFlag) {
            this.addToRow(this.tmp, HTTP_LOADING, null)
          }
        }
      })
      .done(() => {
        Promise.resolve(getInfluencersData(event.id)).then(influencersData => {
          const { statistics } = influencersData || {}
          this.initRebates = statistics ? statistics.refunds_due : 0
          if (!this.unMounted) {
            this.addToRow(null, HTTP_LOADING_SUCCESSED, null)
          }
        })
        .catch((e)=>{
          if (!this.unMounted) {
            this.addToRow(null, HTTP_LOADING_SUCCESSED, null)
          }
        })
      })
      .fail(errorReport => {
        if (!this.unMounted) this.addToRow(null, HTTP_LOADING_FAILED, errorReport)
      })
  }

  addToRow(cached, http_status, http_error) {
    const rows = this.refreshFlag ? [] : this.state.rows

    const tmp = !!cached ? cached : this.tmp
    const start = rows.length
    _map(tmp, (o, index) => {
      // newRow.id = index
      const newRow = Object.assign({}, o)
      if (newRow) {
        rows.push(newRow)
      }
    })
    if (this.refreshFlag) {
      this.refreshFlag = false
    }
    this.tmp = []
    const { event } = this.props
    let num_sales = 0

    if (http_status > HTTP_LOADING && !this.refreshFlag) {
      const currentDate = !!event.timezone
        ? moment().tz(event.timezone).format('YYYY-MM-DD')
        : moment().format('YYYY-MM-DD')
      const yesterday = !!event.timezone
        ? moment().add(-1, 'days').tz(event.timezone).format('YYYY-MM-DD')
        : moment().add(-1, 'days').format('YYYY-MM-DD')
      let sold = 0
      let revenue = 0
      let total = 0
      let ysold = 0
      let ticketsLocked = 0
      let buyers = 0
      _each(rows, (s, index) => {
        if (s.order_date == yesterday) {
          ysold = !s.quantity || isNaN(s.quantity) ? 0 : parseInt(s.quantity)
        }
        if (s.order_date == currentDate) {
          sold = !s.quantity || isNaN(s.quantity) ? 0 : parseInt(s.quantity)
        }
        if (s.carts) {
          ticketsLocked = s.carts.total
          buyers = s.carts.buyers.length
        }
        if (s.num_sales) {
          num_sales = isNaN(s.num_sales) ? 0 : s.num_sales
        }
        total += !s.quantity || isNaN(s.quantity) ? 0 : parseInt(s.quantity)
        revenue +=
          (!s.income || isNaN(s.income) ? 0 : parseFloat(s.income)) ||
          (!s.cost || isNaN(s.cost) ? 0 : parseFloat(s.cost) * num_sales)
      })
      this.setState(() => ({
        ticketStat: {
          id: this.props.event.id,
          ysold,
          sold,
          total,
          revenue: revenue - this.initRebates,
          ticketsLocked,
          buyers
        }
      }))
    }
    this.setState(() => ({
      http_status,
      http_error,
      rows
    }))
  }

  render() {
    const { http_status, ticketStat } = this.state
    const { event } = this.props
    const isLimitedStats = checkIsLimitedStats(event)
    const isPromoter = checkIsPromoter(event)
    const currency = getCurrencySymbol(event)
    const permission = event ? event.self.role : ''
    const isSupport = ROLES.is_support_or_partial_support(permission)
    const redirectPath = '/performance'
    const supportRedirectPath = '/orders'

    const { sold = 0, revenue = 0, total = 0, ysold = 0, ticketsLocked = 0, buyers = 0 } = ticketStat || {}

    const isLoading = http_status <= HTTP_LOADING && !this.refreshFlag

    if (this.isFirstTime) {
      this.yesterdaySold = ysold
      this.initSold = sold
      this.initRevenue = revenue
      this.initTotal = total
      this.isFirstTime = false
      this.ticketsLocked = ticketsLocked
      this.buyers = buyers
    }

    return (
      <div ref="statContainer" className="events-quickstat">
        <ul className="clearfix">
          {!isPromoter && (
            <Link
              to={
                '/event/' + event.id + (isSupport ? supportRedirectPath : redirectPath + '/tickets-in-carts')
              }
            >
              <div className="quick-event-stat tickets-in-carts-desktop">
                <span className="tictets-state-title">
                  <img
                    src={asset('/resources/images/event/performance/tickets-in-carts-ico.svg')}
                    className="event-stat-ico"
                  />{' '}
                  Tickets In Carts
                </span>
                <li>
                  <div className="eventlist-stat tickets-stat-content">
                    <div className="stat_number">
                      <NumberAnimation
                        isLoading={isLoading}
                        initValue={this.ticketsLocked}
                        target={ticketsLocked}
                        duration={ANIMATION_DURATION}
                        useGroup={true}
                        animation={'up'}
                      />
                    </div>
                    {isLimitedStats && <div className="stat_number">&nbsp;</div>}
                    <div className="legend-text">Tickets Locked</div>
                  </div>
                </li>
                <li>
                  <div className="eventlist-stat tickets-stat-content">
                    <div className="stat_number">
                      <NumberAnimation
                        isLoading={isLoading}
                        initValue={this.buyers}
                        target={buyers}
                        duration={ANIMATION_DURATION}
                        decimals={0}
                        useGroup={true}
                        animation={'up'}
                      />
                    </div>
                    {isLimitedStats && <div className="stat_number">&nbsp;</div>}
                    <div className="legend-text">Buyers</div>
                  </div>
                </li>
              </div>
            </Link>
          )}
          <Link to={'/event/' + event.id + (isSupport ? supportRedirectPath : redirectPath)}>
            <div className="quick-event-stat mobile-revenue-title">
              <span className="tictets-state-title">
                <img src={asset('/resources/images/system_icons/ticket.svg')} className="event-stat-ico" />
                Tickets Sold
              </span>
              <li>
                <div className="eventlist-stat tickets-stat-content">
                  <div className="stat_number">
                    <NumberAnimation
                      isLoading={isLoading}
                      initValue={this.initSold}
                      target={sold}
                      duration={ANIMATION_DURATION}
                      decimals={0}
                      useGroup={false}
                      animation={'up'}
                    />
                  </div>
                  <div className="legend-text">Today</div>
                </div>
              </li>
              <li>
                <div className="eventlist-stat tickets-stat-content">
                  <div className="stat_number">
                    <NumberAnimation
                      isLoading={isLoading}
                      initValue={this.yesterdaySold}
                      target={ysold}
                      duration={ANIMATION_DURATION}
                      decimals={0}
                      useGroup={false}
                      animation={'up'}
                    />
                  </div>
                  <div className="legend-text">Yesterday</div>
                </div>
              </li>
              <li>
                <div className="eventlist-stat tickets-stat-content">
                  <div className="stat_number">
                    <NumberAnimation
                      isLoading={isLoading}
                      initValue={this.initTotal}
                      target={total}
                      duration={ANIMATION_DURATION}
                      decimals={0}
                      useGroup={false}
                      animation={'up'}
                    />
                  </div>
                  <div className="legend-text">Total</div>
                </div>
              </li>
            </div>
          </Link>
          {!isLimitedStats && (
            <Link to={'/event/' + event.id + (isSupport ? supportRedirectPath : redirectPath)}>
              <div className="quick-event-stat mobile-revenue-title">
                <span className="tictets-state-title">
                  <img
                    src={asset('/resources/images/system_icons/dollar-ico.svg')}
                    className="event-stat-ico"
                  />{' '}
                  Revenue
                </span>
                <li>
                  <div className="eventlist-stat tickets-stat-content">
                    <div className="stat_number">
                      <NumberAnimation
                        isLoading={isLoading}
                        initValue={this.initRevenue}
                        target={revenue}
                        duration={ANIMATION_DURATION}
                        decimals={2}
                        useGroup={true}
                        animation={'up'}
                        prefix={currency + ' '}
                      />
                    </div>
                    {isLimitedStats && <div className="stat_number">&nbsp;</div>}
                    <div className="legend-text">Total</div>
                  </div>
                </li>
              </div>
            </Link>
          )}
          <Link
            to={'/event/' + event.id + (isSupport ? supportRedirectPath : redirectPath + '/tickets-in-carts')}
          >
            <div className="quick-event-stat tickets-in-carts-mobile">
              <span className="tictets-state-title">
                <img
                  src={asset('/resources/images/event/performance/tickets-in-carts-ico.svg')}
                  className="event-stat-ico"
                />{' '}
                Tickets In Carts
              </span>
              <li>
                <div className="eventlist-stat tickets-stat-content">
                  <div className="stat_number">
                    <NumberAnimation
                      isLoading={isLoading}
                      initValue={this.ticketsLocked}
                      target={ticketsLocked}
                      duration={ANIMATION_DURATION}
                      useGroup={true}
                      animation={'up'}
                    />
                  </div>
                  {isLimitedStats && <div className="stat_number">&nbsp;</div>}
                  <div className="legend-text">Tickets Locked</div>
                </div>
              </li>
              <li>
                <div className="eventlist-stat tickets-stat-content">
                  <div className="stat_number">
                    <NumberAnimation
                      isLoading={isLoading}
                      initValue={this.buyers}
                      target={buyers}
                      duration={ANIMATION_DURATION}
                      decimals={0}
                      useGroup={true}
                      animation={'up'}
                    />
                  </div>
                  {isLimitedStats && <div className="stat_number">&nbsp;</div>}
                  <div className="legend-text">Buyers</div>
                </div>
              </li>
            </div>
          </Link>
        </ul>
      </div>
    )
  }
}
