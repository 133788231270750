import _get from 'lodash/get'
import _values from 'lodash/values'
import { createAsyncAction } from '../actions'
import { fetchAPI } from '../../core/http'
import { ERROR } from '../notification/actions'
import { ParallelFetchByPagination } from '../../core/http_services/utils'
import { toggleOrderResale, markOrderAsPaid } from '../../core/http_services'
import store from '../store'
import { showAxiosError } from '../../../web/utils/messenger'
import createAction from 'redux-actions/lib/createAction'

const getUserFromState = () => {
  const state = store.getState()
  return state.auth.user
}

let lastEventId = ''

const { FETCH_EVENT_ORDERS } = createAsyncAction('FETCH_EVENT_ORDERS', function (eid) {
  return dispatch => {
    lastEventId = eid
    const getOrders = async (offset, limit) =>
      await fetchAPI(`/api/events/${eid}/relationships/orders/`, {
        params: {
          offset,
          limit
        }
      })
    const ParallelFetch = new ParallelFetchByPagination(getOrders, undefined, 3)
    ParallelFetch.run(100, 1000, (error, res, paginationInfo) => {
      const user = getUserFromState()
      if (!user || lastEventId !== eid) {
        ParallelFetch.abort()
        return
      }
      if (error) {
        dispatch(ERROR(...error.errors))
        dispatch(this.failed(error))
      } else {
        let data = _get(res, 'data.$original', {})
        data = _values(data)
        dispatch(this.success({ data, eventId: eid, pagination: paginationInfo }))
      }
    })
  }
})

const { RESEND_ORDER } = createAsyncAction('RESEND_ORDER', function (eid, form) {
  return dispatch => fetchAPI('/api/resend_order', {
    method: 'POST',
    body: JSON.stringify({
      data: {
        ...form,
        relationships: {
          event: {
            data: {
              id: eid,
              type: 'event'
            }
          }
        }
      }
    })
  })
    .catch(err => {
      dispatch(ERROR(...err.errors))
      dispatch(this.failed(err))
      throw err
    })
    .then(res => {
      dispatch(this.success(res))
      return res
    })
})

const { CANCEL_ORDER } = createAsyncAction('CANCEL_ORDER', function (eid, form) {
  return dispatch => fetchAPI('/api/cancel_free_order', {
    method: 'POST',
    body: JSON.stringify({
      data: {
        ...form,
        relationships: {
          event: {
            data: {
              id: eid,
              type: 'event'
            }
          }
        }
      }
    })
  })
    .catch(err => {
      dispatch(ERROR(...err.errors))
      dispatch(this.failed(err))
      throw err
    })
    .then(res => {
      dispatch(this.success(res))
      return res
    })
})

const { UPDATE_ORDER_TICKET } = createAsyncAction('UPDATE_ORDER_TICKET', function (tid, fields) {
  return dispatch => {
    const body = {
      attributes: fields
    }
    return fetchAPI(`/api/issued_tickets/${tid}`, {
      method: 'PATCH',
      body: JSON.stringify({ data: { ...body } })
    })
      .catch(err => {
        dispatch(ERROR(...err.errors))
        dispatch(this.failed(err))
        throw err
      })
      .then(res => {
        dispatch(this.success(res))
        return res
      })
  }
})

const { TICKETS_TYPE_BULK_UPDATE } = createAsyncAction(
  'TICKETS_TYPE_BULK_UPDATE',
  function (eventId, orderId, ticketTypeId) {
    return dispatch => {
      const body = { attributes: { ticketTypeId } }
      return fetchAPI(`/api/event/${eventId}/order/${orderId}/issued_tickets`, {
        method: 'PATCH',
        body: JSON.stringify({ data: { ...body } })
      })
        .catch(err => {
          dispatch(ERROR(...err.errors))
          dispatch(this.failed(err))
          throw err
        })
        .then(res => {
          dispatch(this.success(res))
          return res
        })
    }
  }
)

const { TOGGLE_ORDER_RESALE } = createAsyncAction('TOGGLE_ORDER_RESALE', function (eventId, orderId, body) {
  return async dispatch => {
    try {
      const data = await toggleOrderResale(eventId, orderId, body)
      dispatch(this.success({ orderId }))

      return data
    } catch (err) {
      console.error(err, 'err')
      showAxiosError(err)
    }
  }
})

const { MARK_ORDER_AS_PAID } = createAsyncAction('MARK_ORDER_AS_PAID', function (eventId, orderId, body) {
  return async dispatch => {
    try {
      const data = await markOrderAsPaid(eventId, orderId, body)
      dispatch(this.success({ orderId }))

      return data
    } catch (err) {
      console.error(err, 'err')
      showAxiosError(err)
    }
  }
})

const REFRESH_ORDERS_DATA = createAction('REFRESH_ORDERS_DATA')

export {
  FETCH_EVENT_ORDERS,
  RESEND_ORDER,
  CANCEL_ORDER,
  UPDATE_ORDER_TICKET,
  TICKETS_TYPE_BULK_UPDATE,
  TOGGLE_ORDER_RESALE,
  MARK_ORDER_AS_PAID,
  REFRESH_ORDERS_DATA
}
