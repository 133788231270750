import React from 'react'

import Card from '../_library/Card'
import FileUploader from '../_library/FileUploader'

const BrandImages = React.memo((props) => {
  const {
    values,
    handleChangeIconURL,
    isRnDomain,
    closed
  } = props
  return (
    <Card icon={'fa-camera'} title={'Brand Image'} closed={closed}>
      <div className="row">
        {isRnDomain && (
          <div className="col-xs-3 col-12">
            <FileUploader
              id="logo" // id need to be updated to 'squareImage'
              label={'Square Image'}
              filetype="image"
              value={values.logo}
              onChange={image => handleChangeIconURL('logo', image)}
            />
          </div>
        )}
        {!isRnDomain && (
          <div className="col-xs-3 col-12">
            <FileUploader
              id="logo"
              label={'Image'}
              filetype="image"
              value={values.logo}
              onChange={image => handleChangeIconURL('logo', image)}
            />
          </div>
        )}
      </div>
    </Card>
  )
})

export default BrandImages
