// Not reusable -- need to write reusable modal component
import React, { Component } from 'react'
import Modal from 'react-modal'
import modalStyle from '../../_common/core/modalStyle'
import Button from '../_library/Button'

const defaultContent = `Are you sure you want to deactivate the custom text?`

export default class DeactivateModal extends Component {
  render() {
    const {
      deleting,
      header,
      content,
      deactivateLabel,
      cancelLabel,
      handleDeactivate,
      handleCancelDeactivate,
      deactivateClass,
      isLoading,
      isDeleteLoading
    } = this.props

    return (
      <Modal
        className="modal-dialog modal-trans"
        style={modalStyle}
        isOpen={!!deleting}
        contentLabel="Modal"
        onRequestClose={isLoading ? null : handleCancelDeactivate}
        closeTimeoutMS={150}
        ariaHideApp={false}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div>
              <div className="modal-header">
                <p className="h4 text-compact">{header || 'Are you sure?'}</p>
              </div>
              <div className="modal-body">
                <p>{content || defaultContent}</p>
              </div>
              <div className="modal-footer">
                <div className="btn-toolbar btn-toolbar-right">
                  <Button
                    className={`btn ${deactivateClass || 'btn-danger'}`}
                    type="button"
                    onClick={() => handleDeactivate(deleting)}
                    loading={isLoading}
                  >
                    {deactivateLabel || 'Delete'}
                  </Button>
                  <Button
                    className="btn btn-cancel btn-shadow"
                    type="button"
                    onClick={handleCancelDeactivate}
                    disabled={isLoading}
                  >
                    {cancelLabel || 'Cancel'}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}
