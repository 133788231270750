import handleActions from 'redux-actions/lib/handleActions'
import handleAction from 'redux-actions/lib/handleAction'

import _get from 'lodash/get'
import _orderBy from 'lodash/orderBy'

import { createAsyncHandlers } from '../actions'
import { mergeArraysBy } from '../../core/utils'

const initialState = {
  initial: true,
  fullReady: false,
  eventId: '',
  total: 0,
  orders: []
}

const FETCH_EVENT_ORDERS = createAsyncHandlers('FETCH_EVENT_ORDERS', {
  success(state, action) {
    const { data, eventId, pagination } = action.payload
    const { total } = pagination
    if (pagination.offset !== 0 && eventId !== state.eventId) {
      return
    }

    state.initial = false
    state.fullReady = pagination.done
    state.total = total
    state.eventId = eventId
    if (pagination.offset === 0) {
      state.orders = _orderBy(_get(data, 'orders', []), 'id', 'desc')
    }
    state.orders = mergeArraysBy(state.orders, data, 'order.id')
  }
})

const RESEND_ORDER = createAsyncHandlers('RESEND_ORDER', {
  success() {}
})

const CANCEL_ORDER = createAsyncHandlers('CANCEL_ORDER', {
  success() {}
})

const UPDATE_ORDER_TICKET = createAsyncHandlers('UPDATE_ORDER_TICKET', {
  success() {}
})

const TICKETS_TYPE_BULK_UPDATE = createAsyncHandlers('TICKETS_TYPE_BULK_UPDATE', {
  success() {}
})

const TOGGLE_ORDER_RESALE = createAsyncHandlers('TOGGLE_ORDER_RESALE', {
  success(state, action) {
    const { orderId } = action.payload
    state.orders = state.orders.map(item => {
      if (item.order.id === orderId) {
        item.order.disableResale = !item.order.disableResale
      }
      return item
    })
  }
})

const MARK_ORDER_AS_PAID = createAsyncHandlers('MARK_ORDER_AS_PAID', {
  success(state, action) {
    const { orderId } = action.payload
    state.orders = state.orders.map(item => {
      if (item.order.id === orderId) {
        item.order.status = 'Paid'
      }
      return item
    })
  }
})

const REFRESH_ORDERS_DATA = handleAction('REFRESH_ORDERS_DATA', {
  success(state, action) {
    state.orders = action.data
  }
}, initialState)

export default handleActions(
  {
    ...FETCH_EVENT_ORDERS,
    ...RESEND_ORDER,
    ...CANCEL_ORDER,
    ...UPDATE_ORDER_TICKET,
    ...TICKETS_TYPE_BULK_UPDATE,
    ...TOGGLE_ORDER_RESALE,
    ...MARK_ORDER_AS_PAID,
    REFRESH_ORDERS_DATA
  },
  initialState
)
