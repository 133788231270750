import _isNaN from 'lodash/isNaN'
import _get from 'lodash/get'
import _isArray from 'lodash/isArray'
import _isEmpty from 'lodash/isEmpty'
import _forEach from 'lodash/forEach'
// eslint-disable-next-line import/no-unresolved
import { logEventListError } from './sentry'
import { useState, useEffect } from "react"

export function isInt(n) {
  return !_isNaN(Number(n)) && n % 1 === 0
}

export function isFloat(n) {
  return !_isNaN(Number(n)) && n % 1 !== 0
}

export function isWeb() {
  return typeof document != 'undefined'
}

export function isNative() {
  return typeof navigator != 'undefined' && navigator.product === 'ReactNative'
}

export const subtractTwoArrays = (arr1, arr2) => arr1.filter(el => !arr2.includes(el))

export const makeId = (length = 20) => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const extractContent = s => {
  const span = document.createElement('span')
  span.innerHTML = s
  const images = span.getElementsByTagName('img')
  const embed = span.getElementsByClassName('medium-insert-embeds')
  return images.length || embed.length ? span.innerHTML : (span.textContent || span.innerText).replace(/\s/g, '')
}

export class LocalStorageService {
  static set(value, ...keys) {
    const key = LocalStorageService.createKey(...keys)
    const newValue = LocalStorageService.stringifyValue(value)
    localStorage.setItem(key, newValue)
  }
  static setIfExist(value, ...keys) {
    if (value) {
      LocalStorageService.set(value, ...keys)
    } else {
      LocalStorageService.delete(...keys)
    }
  }
  static get(...keys) {
    const key = LocalStorageService.createKey(...keys)
    const value = localStorage.getItem(key)
    return LocalStorageService.parseValue(value)
  }
  static delete(...keys) {
    const key = LocalStorageService.createKey(...keys)
    localStorage.removeItem(key)
  }
  static has(...keys) {
    const value = LocalStorageService.get(...keys)
    return value !== null
  }

  static createKey(...keys) {
    const newKeys = keys.map(key => String(key))
    return newKeys.join('')
  }
  static stringifyValue(value) {
    return JSON.stringify(value)
  }
  static parseValue(data) {
    try {
      return JSON.parse(data)
    } catch (e) {
      return data
    }
  }
}

export const toPascalCase = str =>
  (str || '').replace(/-/g, ' ').replace(/(\w)(\w*)/g, (g0, g1, g2) => g1.toUpperCase() + g2.toLowerCase())

export const createSlowEventHandler = (eventHandler, interval) => {
  let lastEventDate = -Infinity
  let currentEvent = null
  let timeoutId = null
  const isLate = currentDate => currentDate - lastEventDate > interval
  return (...args) => {
    const currentDate = Date.now()
    const newIsLate = isLate(currentDate)
    currentEvent = args
    timeoutId !== null && clearTimeout(timeoutId)
    timeoutId = setTimeout(() => {
      lastEventDate = Date.now()
      eventHandler(...currentEvent)
    }, interval)

    if (newIsLate) {
      lastEventDate = currentDate
      eventHandler(...currentEvent)
    }
  }
}

export const shallowCompare = (obj1, obj2) =>
  Object.keys(obj1).length === Object.keys(obj2).length &&
  Object.keys(obj1).every(key => obj2.hasOwnProperty(key) && obj1[key] === obj2[key])

export const getDocumentHeight = () => {
  const { body } = document
  const html = document.documentElement

  return Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight
  )
}

export const mergeArraysBy = (arr1, arr2, by = 'id') => {
  const newArr1 = !_isArray(arr1) ? [] : arr1
  const newArr2 = !_isArray(arr2) ? [] : arr2

  return Array.from(new Map([...newArr1, ...newArr2].map(i => [_get(i, by), i])).values())
}

export const IS_MOBILE_DEVICE = () => {
  const SIDEBAR_THRESHOLD = 768
  // eslint-disable-next-line no-undef
  const width = $(window).width()
  return width <= SIDEBAR_THRESHOLD
}

export const isValidEventObject = event => !!event && !_isEmpty(event) && !_isEmpty(event.owner)

export const logCorrruptedEventList = (userId, events = []) => {
  const corruptedEvents = []
  _forEach(events, item => {
    if (!isValidEventObject(item)) {
      corruptedEvents.push(item)
    }
  })

  if (corruptedEvents.length) {
    logEventListError(userId, corruptedEvents)
  }
}

export const convertFileName = title => {
  let str = title.toLowerCase()
  str = str.replace(/[^a-zA-Z0-9 \.]/g, '')
  str = str.replace(/\s/g, '-')
  return str
}

export const useMediaQuery = query => {
  const [matches, setMatches] = useState(false)

  useEffect(() => {
    const media = window.matchMedia(query)
    if (media.matches !== matches) {
      setMatches(media.matches)
    }
    const listener = () => setMatches(media.matches)
    window.addEventListener("resize", listener)
    return () => window.removeEventListener("resize", listener)
  }, [matches, query])

  return matches
}