import React from 'react'
import _identity from 'lodash/identity'
import _map from 'lodash/map'

import {
  getSortedRows,
} from './utils'

export default class TableHeadCustom extends React.PureComponent {
  handleTableSort = e => {
    const { sortBy = {}, data = [], handleSort = _identity } = this.props

    const nSortBy = {
      column: e.key,
      asc: !sortBy || sortBy.column !== e.key ? true : !sortBy.asc
    }
    const nData = getSortedRows(data, nSortBy)

    handleSort(nData, nSortBy)
  }

  getSortIcon = (sortBy, column) => {
    if (!sortBy || (sortBy && sortBy.column !== column.key)) {
      return ''
    } 
    return sortBy.asc ? 'fa fa-caret-up' : 'fa fa-caret-down'
    
  }

  render() {
    const { data, tableColumns, sortBy } = this.props

    return (
      <div className="table-custom-header">
        {_map(tableColumns, item => (
          <div
            key={item.key}
            className={`table-custom-th ${item.isSortable ? 'sortable' : ''}`}
            onClick={() => this.handleTableSort(item)}
          >
            {item.label}
            <i
              className={'sort-direction ' + this.getSortIcon(sortBy, item)}
              aria-hidden="true"
            />
          </div>
        ))}
      </div>
    )
  }
}
