import { createAsyncAction } from '../actions'
import { fetchAPI } from '../../core/http'
import { ERROR } from '../notification/actions'
import { showAxiosError } from '../../../web/utils/messenger'

const { FETCH_EVENT_TICKETS } = createAsyncAction('FETCH_EVENT_TICKETS', function (eid, params) {
  return dispatch =>
    fetchAPI(`/api/events/${eid}/relationships/tickets`, { params })
      .catch(err => {
        dispatch(ERROR(...err.errors))
        dispatch(this.failed(err))
        throw err
      })
      .then(res => {
        const tickets = res.data
        const out = { eid, tickets }
        dispatch(this.success(out))
        return res
      })
})

const { FETCH_EVENT_TABLES } = createAsyncAction('FETCH_EVENT_TABLES', function (eid) {
  return dispatch =>
    fetchAPI(`/api/events/${eid}/relationships/tables`)
      .catch(err => {
        dispatch(ERROR(...err.errors))
        dispatch(this.failed(err))
        throw err
      })
      .then(res => {
        const tables = res.data
        const out = { eid, tables }
        dispatch(this.success(out))
        return res
      })
})

const { CREATE_TICKET } = createAsyncAction('CREATE_TICKET', function (eid, form, isTimeSlot) {
  const endPoint = isTimeSlot ? `/api/events/${eid}/time-slot-tickets` : `/api/event/${eid}/ticket-type`
  const body = {
    data: {
      ...form,
      type: 'ticket',
      relationships: {
        event: {
          data: { type: 'event', id: eid }
        }
      }
    }
  }
  return dispatch =>
    fetchAPI(endPoint, {
      method: 'POST',
      body: JSON.stringify(body)
    })
      .catch(err => {
        const errMsg = err.errors[0].details
        showAxiosError(err.errors[0], errMsg, 3)
        throw err
      })
      .then(res => {
        if (!isTimeSlot) {
          const { data } = res

          dispatch(this.success({ eid, ticket: data }))
          return res
        }
      })
})

const { CREATE_TABLE } = createAsyncAction('CREATE_TABLE', function (eid, form) {
  const body = {
    data: {
      ...form,
      type: 'table',
      relationships: {
        event: {
          data: { type: 'event', id: eid }
        }
      }
    }
  }
  return dispatch =>
    fetchAPI(`/api/tables`, {
      method: 'POST',
      body: JSON.stringify(body)
    })
      .catch(err => {
        const errMsg = err.errors[0].details
        showAxiosError(err.errors[0], errMsg, 3)
        throw err
      })
      .then(res => {
        const { data } = res

        dispatch(this.success({ eid, table: data }))
        return res
      })
})

const { UPDATE_TICKET } = createAsyncAction('UPDATE_TICKET', function (tid, form, eid) {
  const body = {
    data: {
      ...form,
      id: tid,
      type: 'ticket'
    }
  }
  return dispatch =>
    fetchAPI(`/api/event/${eid}/ticket-type/${tid}`, {
      method: 'PATCH',
      body: JSON.stringify(body)
    })
      .catch(err => {
        const errMsg = err.errors[0].details
        showAxiosError(err.errors[0], errMsg, 3)
        throw err
      })
      .then(res => {
        const { data } = res
        dispatch(this.success({ eid, ticket: data }))
        return res
      })
})

const { UPDATE_TABLE } = createAsyncAction('UPDATE_TABLE', function (tid, form, eid) {
  const body = {
    data: {
      ...form,
      id: tid,
      type: 'table'
    }
  }
  return dispatch =>
    fetchAPI(`/api/tables/${tid}`, {
      method: 'PATCH',
      body: JSON.stringify(body)
    })
      .catch(err => {
        const errMsg = err.errors[0].details
        showAxiosError(err.errors[0], errMsg, 3)
        throw err
      })
      .then(res => {
        const { data } = res
        dispatch(this.success({ eid, table: data }))
        return res
      })
})

const { ADD_TIME_SLOT_TEXT } = createAsyncAction('ADD_TIME_SLOT_TEXT', (eid, form) => {
  const body = {
    data: {
      attributes: {
        ...form
      }
    }
  }
  return dispatch =>
    fetchAPI(`/api/event/${eid}/time-slot-text/`, {
      method: 'POST',
      body: JSON.stringify(body)
    })
      .catch(err => {
        dispatch(ERROR(...err.errors))
        throw err
      })
      .then(res => {
        dispatch(GET_TIME_SLOT_TEXTS(eid))
        return res
      })
})

const { UPDATE_TIME_SLOT_TEXT } = createAsyncAction('UPDATE_TIME_SLOT_TEXT', function (form) {
  const body = {
    data: {
      attributes: {
        ...form
      }
    }
  }
  return dispatch =>
    fetchAPI(`/api/event/${form.eventId}/time-slot-customisation/${form.id}`, {
      method: 'PATCH',
      body: JSON.stringify(body)
    })
      .catch(err => {
        dispatch(ERROR(...err.errors))
        throw err
      })
      .then(res => {
        dispatch(this.success({ form, eid: form.eventId }))
        return res
      })
})

const { GET_TIME_SLOT_TEXTS } = createAsyncAction('GET_TIME_SLOT_TEXTS', function (eid) {
  return dispatch =>
    fetchAPI(`/api/event/${eid}/time-slot-customisations/`)
      .then(res => {
        const data = res.data.map(el => el.$original)
        dispatch(this.success({ data, eid }))
        return res
      })
      .catch(err => {
        dispatch(ERROR(...err.errors))
        throw err
      })
})

const { GET_TIME_SLOT_GROUPS } = createAsyncAction('GET_TIME_SLOT_GROUPS', function (eid) {
  return dispatch =>
    fetchAPI(`/api/event/${eid}/time-slot-groups-dates`)
      .catch(err => {
        dispatch(ERROR(...err.errors))
        throw err
      })
      .then(res => {
        const data = res.data.map(el => el.$original).filter(item => item.id || item.groupName)
        dispatch(this.success({ data, eid }))
        return res
      })
})

export {
  FETCH_EVENT_TICKETS,
  FETCH_EVENT_TABLES,
  CREATE_TICKET,
  CREATE_TABLE,
  UPDATE_TICKET,
  UPDATE_TABLE,
  ADD_TIME_SLOT_TEXT,
  UPDATE_TIME_SLOT_TEXT,
  GET_TIME_SLOT_TEXTS,
  GET_TIME_SLOT_GROUPS
}
