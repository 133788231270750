import handleActions from 'redux-actions/lib/handleActions'
import { createAsyncHandlers } from '../actions'

const initialState = {
  buyerlocation: {}
}

const FETCH_EVENT_BUYERLOCATION = createAsyncHandlers('FETCH_EVENT_BUYERLOCATION', {
  success(state, action) {
    const {
      buyersLocations: { data }
    } = action.payload
    state.buyerlocation = data
  }
})

export default handleActions(
  {
    ...FETCH_EVENT_BUYERLOCATION
  },
  initialState
)
