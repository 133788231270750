import { createSelector } from 'reselect'
import _get from 'lodash/get'

// selectors
import { get_event_id } from '.'

export const get_all_inventorydelegations = state => _get(state, 'inventorydelegations.inventorydelegations')

export const get_event_inventorydelegations = createSelector(
  get_all_inventorydelegations,
  get_event_id,
  (all_inventorydelegations, eid) => _get(all_inventorydelegations, eid, [])
)
