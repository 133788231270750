import isEmpty from 'validator/lib/isEmpty'
import isURL from 'validator/lib/isURL'

import _isString from 'lodash/isString'

import {
  IMAGE_SIZE_LIMIT_ERROR,
  IMAGE_SIZE_LIMIT,
} from '../../../web/constants/shared'

import {
  // utils
  createValidator,

  // checked functions
  isDateRangeValid,
  isEndDateValid,
  createIsMinChecked,
  createIsMaxChecked,
  minLengthChecked,
  maxLengthChecked,
  matchPassword,
  isEmail,
  toInt,
  isValidTag,
} from '.'

export const requiredValidator = (error_message='Required') => createValidator(error_message, (v) => !isEmpty(v + '', { ignore_whitespace: true }))

export const isEmailValidator = (error_message='Invalid email address') => createValidator(error_message, isEmail)

export const isDateRangeValidator = (error_message='End date must be after start date') => createValidator(error_message, isDateRangeValid)

export const isEndDateValidator = (error_message='End date must be after current date') => createValidator(error_message, isEndDateValid)

export const minNumberValidator = (error_message='Value must be greater than or equal to {{min}}', min) => createValidator(error_message.replace('{{min}}', min), createIsMinChecked(min))

export const maxNumberValidator = (error_message='Value must be less than or equal to {{max}}', max) => createValidator(error_message.replace('{{max}}', max), createIsMaxChecked(max))

export const minLengthValidator = (error_message='Length must be greater than or equal to {{min}}', min) => createValidator(error_message.replace('{{min}}', min), minLengthChecked(min))

export const minPasswordLengthValidator = (error_message='Password must be at least {{min}} characters', min) => createValidator(error_message.replace('{{min}}', min), minLengthChecked(min))

export const passwordMatchValidator = (error_message='Passwords do not match', value) => createValidator(error_message, matchPassword(value))

export const maxLengthValidator = (error_message='Length must be less than or equal to {{max}}', max) => createValidator(error_message.replace('{{max}}', max), maxLengthChecked(max))

export const emptyArrayValidator = (error_message='Required') => createValidator(error_message, v => v && v.length)

export const isValidURL = (error_message='Invalid URL', options) => createValidator(error_message, (url) =>  isURL(url, { ...options }))

export const isTagValidator = (error_message = 'Invalid', tagName) => createValidator(error_message,  v => isValidTag(v, tagName))

export const fileSizeLimitValidator = (error_message=IMAGE_SIZE_LIMIT_ERROR, sizeLimit=IMAGE_SIZE_LIMIT) => createValidator(error_message, v => {
  try {
    if (_isString(v)) {
      const params = new URL(v).searchParams
      const size = +(params.get('size'))
      return !(size && size > sizeLimit)
    }
  } catch (e) {}
  return true
})

export const isValidSlug = (error_message='Link may only contain alphanumeric characters (a-z, 0-9), underscores (_) and hyphens (-)') => {
  const slugRE = /^[\w-]*$/
  return createValidator(error_message, v => slugRE.test(v))
}

export const intValidator = (error_message='Invalid') => createValidator(error_message, v =>  toInt(v) === v)
