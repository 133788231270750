import _isEmpty from 'lodash/isEmpty'
import _identity from 'lodash/identity'
import _get from 'lodash/get'
import React from 'react'
import Field from './Field'

export default class CurrencyField extends React.Component {
  constructor(props) {
    super(props)
    const { onBlurNormalizer = _identity, field = {}, value } = props
    this.state = {
      price: onBlurNormalizer(field.value || value || '')
    }
  }

  setValue = (value, n) => {
    const { field = {}, normalizer = _identity, form = {} } = this.props
    const { setFieldValue } = form
    setFieldValue(field.name, n ? n(value) : normalizer(value))
  }

  handleChange = event => {
    const { field = {}, onChange, normalizer = _identity } = this.props
    const { value } = event.target
    this.setState({ price: normalizer(value) })
    !_isEmpty(field) && this.setValue(value)
    if (onChange) {
      onChange(value, event, field.name)
    }
  }

  onBlur = event => {
    const { field = {}, onChange, onBlur, onBlurNormalizer = _identity } = this.props
    const { value } = event.target
    this.setState({ price: onBlurNormalizer(value) })
    !_isEmpty(field) && this.setValue(value)
    if (onBlur) {
      onBlur(value, event)
    }
  }

  render() {
    const {
      field = {},
      form = {},
      touchedOnBlur = false,
      normalizer = _identity,
      onBlurNormalizer = _identity,
      showErrorOnlyTouched = true,
      initialValue,
      id,
      ...props
    } = this.props
    const { touched, errors, setFieldValue, setFieldTouched } = form

    return (
      <Field
        {...field}
        {...props}
        id={field.name || id}
        value={this.state.price || ''}
        touched={!showErrorOnlyTouched || props.touched}
        error={props.error}
        onChange={this.handleChange}
        onBlur={this.onBlur}
      />
    )
  }
}
