// Re-render Optimization 20.05.2020
import { withFormik } from 'formik'
import React from 'react'
import { getConfig } from '../utils/configUtils'
import Button from '../_library/Button'
import Field from '../_library/Field'
import _get from 'lodash/get'

const configFile = getConfig()

class MyForm extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      showRestorePassword: false,
      restoringPassword: false,
      restorePasswordResponse: '',
      copyright:_get(configFile, '*.messages.copyright', 'THE<strong>TICKET</strong>FAIRY, Inc.')
    }
  }

  showRestorePassword = () => {
    this.setState(() => ({
      showRestorePassword: true
    }))
  }

  hideRestorePassword = () => {
    this.setState(() => ({
      showRestorePassword: false
    }))
  }

  restorePassword = () => {
    const {
      onRestorePassword,
      values: { username },
      setFieldTouched
    } = this.props
    if (!validateEmail(username)) {
      setFieldTouched('username', true)
      return
    }
    this.setState(() => ({ restoringPassword: true }))
    onRestorePassword(username)
      .catch(err => {
        this.setState(() => ({ restoringPassword: false, restorePasswordResponse: '' }))
      })
      .then(res => {
        this.setState(() => ({
          restoringPassword: false,
          restorePasswordResponse: res && res.success ? res.message : ''
        }))
      })
  }

  render() {
    const { showRestorePassword, restoringPassword, restorePasswordResponse, copyright } = this.state

    const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit, onClose } =
      this.props

    return (
      <form onSubmit={handleSubmit}>
        <div className="login_holder mc_login_holder">
          <div className="login_content clearfix">
            <div className="login_card">
              {!showRestorePassword && (
                <div className="login_data">
                  <div className="login_logo text-center">
                    <img
                      src={asset('/resources/images/manacommon/MANA_COMMON_DARK_BACKGROUND.svg')}
                      style={{ width: 120 }}
                      alt="no data"
                    />
                  </div>
                  <h2 className="heading">Log In</h2>
                  <div className="form_holder">
                    <Field
                      id="username"
                      label="Email"
                      type="email"
                      placeholder="Email"
                      value={values.username}
                      error={errors.username}
                      touched={touched.username}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <Field
                      id="password"
                      label="Password"
                      type="password"
                      placeholder="Password"
                      value={values.password}
                      error={errors.password}
                      touched={touched.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <div className="short_links clearfix" />
                    <div className="text-center">
                      <Button type="submit" className="login_btn" loading={isSubmitting}>
                        Login
                      </Button>
                    </div>
                    <div className="restore-password-show" onClick={this.showRestorePassword}>
                      Forgotten your password?
                    </div>
                    <div className="copy_right">
                      Copyright &copy; {getCurrentYear()} {copyright}
                    </div>
                  </div>
                </div>
              )}
              {showRestorePassword && (
                <div className="login_data">
                  <div className="login_logo text-center">
                    <img
                      src={asset('/resources/images/manacommon/MANA_COMMON_DARK_BACKGROUND.svg')}
                      style={{ width: 120 }}
                      alt="no data"
                    />{' '}
                  </div>
                  <h2 className="heading">Reset Password</h2>
                  <div className="form_holder">
                    <Field
                      id="username"
                      label="Email"
                      type="email"
                      placeholder="Email"
                      value={values.username}
                      error={errors.username}
                      touched={touched.username}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <div className="text-center">
                      <Button
                        type="button"
                        className="login_btn"
                        loading={restoringPassword}
                        onClick={this.restorePassword}
                      >
                        Send Request
                      </Button>
                    </div>
                    {restorePasswordResponse !== '' && (
                      <div className="restore-password-response">{restorePasswordResponse}</div>
                    )}
                    <div className="restore-password-show" onClick={this.hideRestorePassword}>
                      Back to Log In
                    </div>
                    <div className="copy_right">
                      Copyright &copy; {getCurrentYear()} {copyright}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
    )
  }
}

const LoginForm = withFormik({
  mapPropsToValues: props => ({ username: props.initialEmail || '', password: '' }),

  // Custom sync validation
  validate: values => {
    const errors = {}
    if (!values.username || values.username === '') {
      errors.username = 'Required'
    } else if (!validateEmail(values.username)) {
      errors.username = 'Invalid email address'
    }
    if (!values.password || values.password === '') {
      errors.password = 'Required'
    }
    return errors
  },

  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      await props.onSubmit({ attributes: values })
    } catch (e) {
    } finally {
      setSubmitting(false)
    }
  },
  displayName: 'LoginForm' // helps with React DevTools
})(MyForm)

export default LoginForm
