import _get from 'lodash/get'
import handleAction from 'redux-actions/lib/handleAction'
import handleActions from 'redux-actions/lib/handleActions'
import { createAsyncHandlers } from '../actions'

const initialState = {
  creative_id: null,
  previewGenerated:false,
  previewIframe: null,
  audience_item: null,
  ads: [],
}

const CREATE_AD = createAsyncHandlers('CREATE_AD', {
  success(state, action) {
    const { ad } = action.payload
    const { campaignId } = action.payload
    state.ad = ad
    state.campaign_id = campaignId
    state.has_error = false
  },
  failed(state) {
    state.has_error = true
  }
})
const CREATE_ADCREATIVE = createAsyncHandlers('CREATE_ADCREATIVE', {
  success(state, action) {
    const adCreative = action.payload
    state.creative_id = adCreative.id
    state.previewGenerated = true
    state.has_error = false
  },
  failed(state) {
    state.has_error = true
  }
})

const GENERATE_PREVIEW = createAsyncHandlers('GENERATE_PREVIEW', {
  success(state, action) {
    state.previewIframe = action.payload[0].body
    state.has_error = false
  },
  failed(state) {
    state.has_error = true
  }
})

const UPDATE_AD = createAsyncHandlers('UPDATE_AD', {
  success(state) {
    state.has_success = true
    state.has_error = false
  },
  failed(state) {
    state.has_error = true
  }
})

const DEPLOY_AD = createAsyncHandlers('DEPLOY_AD', {
  success(state) {
    state.has_success = true
    state.has_error = false
  },
  failed(state) {
    state.has_error = true
  }
})

const FETCH_ADS = createAsyncHandlers('FETCH_ADS', {
  success(state, action) {
    const { data } = action.payload
    const ads = _get(data, '$original')
    state.ads = ads.ads
    state.has_error = false
  },
  failed(state) {
    state.has_error = true
  }
})

const CLEAR_PREVIEW = handleAction('CLEAR_PREVIEW', (state) => {
  state.creative_id = null
  state.previewGenerated = false
  state.previewIframe = null
}, initialState)

export default handleActions({
  ...CREATE_AD,
  ...CREATE_ADCREATIVE,
  ...GENERATE_PREVIEW,
  ...DEPLOY_AD,
  ...UPDATE_AD,
  ...FETCH_ADS,
  CLEAR_PREVIEW,
}, initialState)
