export function getDomain(url, onlyDomainName) {
  let updatedUrl = url.replace(/(https?:\/\/)?(www.)?/i, '')
  updatedUrl = updatedUrl.split('.')

  if (onlyDomainName) {
    updatedUrl = updatedUrl.slice(updatedUrl.length - 2, updatedUrl.length - 1).join('.')
  } else {
    updatedUrl = updatedUrl.slice(1).join('.')
  }

  if (updatedUrl.indexOf('/') !== -1) {
    return updatedUrl.split('/')[0]
  }

  return updatedUrl
}
