import { createSelector } from 'reselect'
import _get from 'lodash/get'
import _cloneDeep from 'lodash/cloneDeep'

export const get_credentials_data = createSelector(
  state => _get(state, 'credentials.credentialsData.data'),
  _cloneDeep
)

export default get_credentials_data
