// Deprecated -- use currency normalizer
import React from 'react'
import PropTypes from 'prop-types'

export default class Currency extends React.Component {
  static propTypes = {
    value: PropTypes.number.isRequired,
  }
  render() {
    const { value, code, symbol } = this.props
    return (
      <span>
        {(!!code || !!symbol) && <small>{code || symbol}</small>}{' '}
        <span>{(value / 100).toFixed(2)}</span>
      </span>
    )
  }
}
