import { createSelector } from 'reselect'
import _get from 'lodash/get'

// selectors
import {
  get_event_id
} from '.'

export const get_all_addons = (state) => _get(state, 'addons.addons')

export const get_event_addons = createSelector(
  get_all_addons,
  get_event_id,
  (all_addons, eid) => _get(all_addons, eid, [])
)

