export default function formatNumber(number, decimals, useGroup, localeString) {
  let str = parseFloat(number).toFixed(decimals)
  if (useGroup && decimals >= 1) {
    let array1 = str.split('.')[0].split('').reverse().join('')
    const array2 = str.split('.')[1]
    array1 = array1.replace(/(\d{3})(?=[^$])/g, '$1,')
    array1 = array1.split('').reverse().join('')
    str = `${array1}.${array2}`
  }
  if(localeString){
    str = parseInt(number).toLocaleString()
    if(decimals >= 1 && parseFloat(number) - parseInt(number) != 0){
      const str1 = parseFloat(number).toFixed(decimals)
      const array2 = str1.split('.')[1]
      str = `${str}.${array2}`
    }
  }
  return str
}
