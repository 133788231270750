import { createAsyncAction } from '../actions'
import { fetchAPI } from '../../core/http'
import { ERROR } from '../notification/actions'
import { showAxiosError } from '../../../web/utils/messenger'

const { SEARCH_TALENT } = createAsyncAction('SEARCH_TALENT', function(query, type = 'artist') {
  return (dispatch) => fetchAPI('/api/talent/search', { params: { type, detail: true, search_query: query } })
    .catch((err) => {
      dispatch(ERROR(...err.errors))
      dispatch(this.failed(err))
      throw err
    })
    .then((res) => {
      dispatch(this.success(res))
      return res
    })
})

const { GET_EVENT_TALENT } = createAsyncAction('GET_EVENT_TALENT', function(eid) {
  return (dispatch) => fetchAPI(`/api/events/${eid}/relationships/talent`)
    .catch((err) => {
      dispatch(ERROR(...err.errors))
      dispatch(this.failed(err))
      throw err
    })
    .then((res) => {
      dispatch(this.success(res))
      return res
    })
})

const { GET_TALENT } = createAsyncAction('GET_TALENT', function(aid) {
  return (dispatch) => fetchAPI(`/api/talent/get-by-id/${aid}`)
    .catch((err) => {
      dispatch(ERROR(...err.errors))
      dispatch(this.failed(err))
      throw err
    })
    .then((res) => {
      dispatch(this.success(res))
      return res
    })
})

const { ASSIGN_TALENT } = createAsyncAction('ASSIGN_TALENT', function(eid, form) {
  return (dispatch) => fetchAPI(`api/events/${eid}/talent`, {
    method: 'POST',
    body: JSON.stringify({ data: { ...form } })
  })
    .catch((err) => {
      const errMsg = err.errors[0].meta.details
      showAxiosError(err, errMsg, 3)
      throw err
    })
    .then((res) => {
      dispatch(this.success(res))
      return res
    })
})

const { REASSIGN_TALENT } = createAsyncAction('REASSIGN_TALENT', function(eid, form) {
  return (dispatch) => fetchAPI(`api/events/${eid}/talent`, {
    method: 'PATCH',
    body: JSON.stringify({ data: { ...form } })
  })
    .catch((err) => {
      dispatch(ERROR(...err.errors))
      dispatch(this.failed(err))
      throw err
    })
    .then((res) => {
      dispatch(this.success(res))
      return res
    })
})

const { UNASSIGN_TALENT } = createAsyncAction('UNASSIGN_TALENT', function(eid, form) {
  return (dispatch) => fetchAPI(`api/events/${eid}/talent`, {
    method: 'DELETE',
    body: JSON.stringify({ data: { ...form } })
  })
    .catch((err) => {
      dispatch(ERROR(...err.errors))
      dispatch(this.failed(err))
      throw err
    })
    .then((res) => {
      dispatch(this.success(res))
      return res
    })
})

export {
  SEARCH_TALENT,
  GET_EVENT_TALENT,
  GET_TALENT,
  ASSIGN_TALENT,
  REASSIGN_TALENT,
  UNASSIGN_TALENT,
}
