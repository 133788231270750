import {
  // field level validators
  requiredValidator,
  minNumberValidator, 
  isValidURL
} from '../../../_common/core/validation'

export const validate = values => {
  const errors = {}
  //1
  if (values.facebook_share_active) {
    if (requiredValidator()(values.facebook_share)) {
      errors.facebook_share = requiredValidator()(values.facebook_share)
    } else if (minNumberValidator(undefined, 1)(values.facebook_share)) {
      errors.facebook_share = minNumberValidator(undefined, 1)(values.facebook_share)
    }
  }
  //2
  if (values.messenger_active) {
    if (requiredValidator()(values.messenger)) {
      errors.messenger = requiredValidator()(values.messenger)
    } else if (minNumberValidator(undefined, 1)(values.messenger)) {
      errors.messenger = minNumberValidator(undefined, 1)(values.messenger)
    }
  }
  //3
  if (values.twitter_active) {
    if (requiredValidator()(values.twitter)) {
      errors.twitter = requiredValidator()(values.twitter)
    } else if (minNumberValidator(undefined, 1)(values.twitter)) {
      errors.twitter = minNumberValidator(undefined, 1)(values.twitter)
    }
  }
  //4
  if (values.spotify_active) {
    if (requiredValidator()(values.spotify)) {
      errors.spotify = requiredValidator()(values.spotify)
    } else if (minNumberValidator(undefined, 1)(values.spotify)) {
      errors.spotify = minNumberValidator(undefined, 1)(values.spotify)
    }
  }
  //5
  if (values.shared_url_active) {
    if (requiredValidator()(values.shared_url)) {
      errors.shared_url = requiredValidator()(values.shared_url)
    } else if (minNumberValidator(undefined, 1)(values.shared_url)) {
      errors.shared_url = minNumberValidator(undefined, 1)(values.shared_url)
    }
  }
  //6
  if (values.facebook_going_active) {
    if (requiredValidator()(values.facebook_going)) {
      errors.facebook_going = requiredValidator()(values.facebook_going)
    } else if (minNumberValidator(undefined, 1)(values.facebook_going)) {
      errors.facebook_going = minNumberValidator(undefined, 1)(values.facebook_going)
    }

    if (requiredValidator()(values.facebook_going_url)) {
      errors.facebook_going_url = requiredValidator()(values.facebook_going_url)
    } else if (isValidURL(undefined, { require_protocol: false })(values.facebook_going_url)) {
      errors.facebook_going_url = isValidURL(undefined, { require_protocol: false })(
        values.facebook_going_url
      )
    }
  }
  //7
  if (values.facebook_follow_active) {
    if (requiredValidator()(values.facebook_follow)) {
      errors.facebook_follow = requiredValidator()(values.facebook_follow)
    } else if (minNumberValidator(undefined, 1)(values.facebook_follow)) {
      errors.facebook_follow = minNumberValidator(undefined, 1)(values.facebook_follow)
    }

    if (requiredValidator()(values.facebook_follow_url)) {
      errors.facebook_follow_url = requiredValidator()(values.facebook_follow_url)
    } else if (isValidURL(undefined, { require_protocol: false })(values.facebook_follow_url)) {
      errors.facebook_follow_url = isValidURL(undefined, { require_protocol: false })(
        values.facebook_follow_url
      )
    }
  }
  //8
  if (values.instagram_follow_active) {
    if (requiredValidator()(values.instagram_follow)) {
      errors.instagram_follow = requiredValidator()(values.instagram_follow)
    } else if (minNumberValidator(undefined, 1)(values.instagram_follow)) {
      errors.instagram_follow = minNumberValidator(undefined, 1)(values.instagram_follow)
    }

    if (requiredValidator()(values.instagram_follow_url)) {
      errors.instagram_follow_url = requiredValidator()(values.instagram_follow_url)
    } else if (isValidURL(undefined, { require_protocol: false })(values.instagram_follow_url)) {
      errors.instagram_follow_url = isValidURL(undefined, { require_protocol: false })(
        values.instagram_follow_url
      )
    }
  }
  return errors
}
