// Not reusable -- need to be refactored
import React from 'react'
import ReactSlider from 'react-slider'

export default class Slider extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      grade: 'General'
    }
  }

  handleChange = e => {
    let { grade } = this.state
    if (e > 99) {
      grade = 'Recommended'
    } else {
      grade = 'General'
    }
    if (e > 199) {
      grade = 'Featured'
    }
    this.setState({ grade })
  }

  handleAfterChange = e => {
    const { onChange, id } = this.props
    onChange(e, id)
  }

  renderThumb = (props, state) => (
    <div className="thumb" {...props}>
      <span className="thumb-tooltip">{state.valueNow}</span>
    </div>
  )

  componentDidMount() {
    const { value } = this.props
    this.handleChange(value)
  }

  render() {
    const { value } = this.props
    const { grade } = this.state
    return (
      <div className="slider-container">
        <p className="slider-title">
          Event Grade: <span>{grade}</span>
        </p>
        <ReactSlider
          className="horizontal-slider"
          trackClassName="track"
          value={value}
          min={0}
          max={299}
          onChange={this.handleChange}
          renderThumb={this.renderThumb}
          onAfterChange={this.handleAfterChange}
        />
      </div>
    )
  }
}
