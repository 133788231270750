import _get from 'lodash/get'
import _reduce from 'lodash/reduce'
import { baseRequest } from '.'

export const getTourPerfomanceData = async (tourId, options) => {
  let filter = _get(options, 'filter', {})
  const page = _get(options, 'page')
  const limit = _get(options, 'limit')
  const section = _get(options, 'section', '')
  const order = _get(options, 'order', '')
  const sort = _get(options, 'sort', '')

  filter = _reduce(
    filter,
    (acm, val, key) => {
      acm[`filters[${key}]`] = val
      return acm
    },
    {}
  )
  const res = await baseRequest.get(`tours/${tourId}/tickets/performance/`, {
    params: { 
      page,
      limit,
      section,
      ...filter,
      order,
      sort
    }
  })

  return _get(res, 'data')
}

export default getTourPerfomanceData
