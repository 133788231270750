import { createSelector } from 'reselect'
import _get from 'lodash/get'

// selectors
import {
  get_event_id
} from '.'

export const get_all_budgets = (state) => _get(state, 'budgets.budgets')

export const get_event_budgets = createSelector(
  get_all_budgets,
  get_event_id,
  (all_budgets, eid) => _get(all_budgets, eid, [])
)
