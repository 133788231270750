import React from 'react'
import moment from 'moment'
import _map from 'lodash/map'
import _isEqual from 'lodash/isEqual'
import _sortBy from 'lodash/sortBy'
import _filter from 'lodash/filter'
import _reduce from 'lodash/reduce'
import SortableTable from '../../../_library/SortableTable'
import { getTableColumns, makeRunningTotalLabel } from '../../../utils/sortableTableUtils'
import {
  formatDay,
  createFixedFloatNormalizer,
  currencyNormalizerCreator
} from '../../../../_common/core/validation/normalizers'

export default class SalesByOrderDate extends React.Component {
  constructor(props) {
    super(props)
    this.tableColumns = getTableColumns([
      {
        key: 'datetime',
        label: 'Order Date',
        normalizer: formatDay
      },
      {
        key: 'numSales',
        label: 'No. of Sales'
      },
      {
        key: 'numSalesTotal',
        label: makeRunningTotalLabel('No. of Sales').value,
        labelComponent: makeRunningTotalLabel('No. of Sales').component,
        isSortable: false
      },
      {
        key: 'revenue',
        label: 'Revenue',
        disableNormalizerOnCopy: true,
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(props.event))(
            createFixedFloatNormalizer(2)(parseFloat(value))
          )
      },
      {
        key: 'revenueTotal',
        label: makeRunningTotalLabel('Revenue').value,
        labelComponent: makeRunningTotalLabel('Revenue').component,
        isSortable: false,
        disableNormalizerOnCopy: true,
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(props.event))(
            createFixedFloatNormalizer(2)(parseFloat(value))
          )
      },
      {
        key: 'grossRevenue',
        label: 'Gross Revenue',
        isSortable: true,
        disableNormalizerOnCopy: true,
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(props.event))(
            createFixedFloatNormalizer(2)(parseFloat(value))
          )
      },
      {
        key: 'grossRevenueTotal',
        label: makeRunningTotalLabel('Gross Revenue').value,
        labelComponent: makeRunningTotalLabel('Gross Revenue').component,
        isSortable: false,
        disableNormalizerOnCopy: true,
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(props.event))(
            createFixedFloatNormalizer(2)(parseFloat(value))
          )
      }
    ])

    this.detailsTableColumns = getTableColumns(
      [
        {
          key: 'discountCode',
          label: 'Promo Code',
          className: 'detail-cell'
        },
        {
          key: 'ticketType',
          label: 'Ticket Type',
          className: 'detail-cell'
        },
        {
          key: 'numSales',
          label: 'No. of Sales',
          className: 'detail-cell'
        },
        {
          key: 'cost',
          label: 'Price (excl. Fees)',
          className: 'detail-cell',
          disableNormalizerOnCopy: true,
          normalizer: value =>
            currencyNormalizerCreator(getCurrencySymbol(props.event))(
              createFixedFloatNormalizer(2)(parseFloat(value))
            )
        },
        {
          key: 'revenue',
          label: 'Revenue',
          className: 'detail-cell',
          disableNormalizerOnCopy: true,
          normalizer: value =>
            currencyNormalizerCreator(getCurrencySymbol(props.event))(
              createFixedFloatNormalizer(2)(parseFloat(value))
            )
        },
        {
          key: 'grossRevenue',
          label: 'Gross Revenue',
          className: 'detail-cell',
          disableNormalizerOnCopy: true,
          normalizer: value =>
            currencyNormalizerCreator(getCurrencySymbol(props.event))(
              createFixedFloatNormalizer(2)(parseFloat(value))
            )
        }
      ],
      getCurrencySymbol(props.event)
    )

    this.footbarColumns = [
      { key: 'numSalesTotal', sourceKey: 'numSales' },
      {
        key: 'revenueTotal',
        sourceKey: 'revenue',
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(props.event))(
            createFixedFloatNormalizer(2)(parseFloat(value))
          )
      },
      {
        key: 'grossRevenueTotal',
        sourceKey: 'grossRevenue',
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(props.event))(
            createFixedFloatNormalizer(2)(parseFloat(value))
          )
      }
    ]

    this.state = {
      sortBy: { column: 'datetime', asc: true },
      rows: null,
      detailsRows: []
    }
  }

  componentDidMount() {
    this.updateData()
  }

  componentDidUpdate(prevProps, prevState) {
    const { data } = this.props

    if (!_isEqual(data, prevProps.data)) {
      this.updateData()
    }
  }

  updateData = () => {
    const { data, groupedData } = this.props

    const tempRows = []
    const detailsRows = []

    this.getRows(data, groupedData, tempRows, detailsRows)
    this.setState({
      rows: _filter(tempRows, rowItem => !!rowItem.id),
      detailsRows
    })
  }

  getRows = (data, groupedData, tempRows, detailsRows) => {
    _map(groupedData, (value, key) => {
      const numSales = _reduce(value, (sum, n) => sum + parseInt(n.numSales), 0)
      const revenue = _reduce(value, (sum, n) => sum + parseInt(n.numSales) * parseFloat(n.cost), 0)
      const grossRevenue = _reduce(value, (sum, n) => sum + parseInt(n.numSales) * parseFloat(n.price), 0)
      this.getDetailRows(data, key, detailsRows)

      const updatedObj = {
        id: key,
        datetime: key,
        cost: value[0].cost,
        numSales,
        revenue,
        grossRevenue
      }
      tempRows.push(updatedObj)
    })
  }

  getDetailRows = (data, date, detailsRows) => {
    const detailRows = []

    _map(_sortBy(data, 'discountCode'), item => {
      if (moment(item.datetime).isSame(date)) {
        detailRows.push({
          id: item.discountCode + item.cost,
          datetime: item.datetime,
          numSales: item.numSales,
          cost: item.cost,
          revenue: parseInt(item.numSales) * parseFloat(item.cost),
          grossRevenue: parseInt(item.numSales) * parseFloat(item.price),
          discountCode: item.discountCode,
          ticketType: item.ticketType
        })
      }
    })

    !!detailRows.length &&
      detailsRows.push({
        id: detailRows[0].datetime || detailRows[0].id,
        type: 'detailRow',
        component: ({ detailRowIndex }) => (
          <SortableTable
            e2e_test_id={`sales_by_order_date-${detailRowIndex}`}
            data={detailRows || []}
            tableColumns={this.detailsTableColumns}
            enableSort={false}
            enableCopyTable={true}
            disableMobileView={true}
            className="child-table"
          />
        )
      })
  }

  render() {
    const { rows, sortBy, detailsRows } = this.state

    return (
      <div>
        {rows && rows.length ? (
          <SortableTable
            e2e_test_id="sales_by_order_date"
            data={rows}
            tableColumns={this.tableColumns}
            enableSort={true}
            enableCopyTable={true}
            disableMobileView={true}
            sortBy={sortBy}
            detailsRows={detailsRows}
            calculatedColumns={[
              { for: 'numSalesTotal', column: 'numSales' },
              { for: 'revenueTotal', column: 'revenue' },
              { for: 'grossRevenueTotal', column: 'grossRevenue' }
            ]}
            footbar={{
              label: 'Total',
              columns: this.footbarColumns
            }}
          />
        ) : null}
      </div>
    )
  }
}
