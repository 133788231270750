import _get from 'lodash/get'
import handleActions from 'redux-actions/lib/handleActions'
import { createAsyncHandlers } from '../actions'

const initialState = {
  emailtemplates: {}
}

const FETCH_EMAIL_TEMPLATES = createAsyncHandlers('FETCH_EMAIL_TEMPLATES', {
  success(state, action) {
    const { emailtemplates: { data } } = action.payload
    state.emailtemplates = _get(data, '$original')
  }
})
export default handleActions({
  ...FETCH_EMAIL_TEMPLATES,
}, initialState)

