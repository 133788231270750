import _get from 'lodash/get'
import { consumerRequest } from '.'


export const getCustomerProfile = async () => {
  const res = await consumerRequest.get(`auth/customer/profile/`)
  return _get(res, 'data.data')
}

export const customerLogin = async (form) => await consumerRequest.post('/auth/login/', {
  data: {
    type: 'sessionrequest',
    ...form,
  }
})

