
import _ from 'lodash'
import React from 'react'
import { withFormik } from 'formik'
import moment from 'moment'
import Scroll from 'react-scroll'
import Tooltip from 'react-bootstrap/lib/Tooltip'

import Button from '../../_library/Button'
import DateTimePickerReact from '../../_library/DateTimePickerReact'


function isDateRangeInvalid2(start, end) {	
  if(!start)
    return false
  const sm = moment.utc(start)//moment(start, dateFormat, true)
  const em = moment.utc(end)//moment(end, dateFormat, true)
  if (!sm.isValid()) { return }
  if (!em.isValid()) { return }
  return em.isSame(sm) || em.isBefore(sm)
}

function validate(data) {
  const errors = {}

  if (_.get(data, 'flagPreRegEnabled') && !_.get(data, 'preRegStartDate')){
    _.set(errors, 'preRegStartDate', 'Required')
  }

  if (_.get(data, 'flagPreRegEnabled') && !_.get(data, 'preRegEndDate')){
    _.set(errors, 'preRegEndDate', 'Required')
  }

  if (_.get(data, 'preSalesEndDate') && !_.get(data, 'preSalesStartDate')){
    _.set(errors, 'preSalesStartDate', 'Required')
  }
	
  if (_.get(data, 'preSalesStartDate') && !_.get(data, 'preSalesEndDate')){
    _.set(errors, 'preSalesEndDate', 'Required')
  }

  const preRegStartDate = _.get(data, 'preRegStartDate')
  const preRegEndDate = _.get(data, 'preRegEndDate')
  if (isDateRangeInvalid2(preRegStartDate, preRegEndDate)) {
    _.set(errors, 'preRegEndDate', 'Pre-registration end date must be after the pre-registration start date')
  }

  const preSalesStartDate = _.get(data, 'preSalesStartDate')
  const preSalesEndDate = _.get(data, 'preSalesEndDate')
  if (isDateRangeInvalid2(preSalesStartDate, preSalesEndDate)) {
    _.set(errors, 'preSalesEndDate', 'Pre-sale end date must be after the pre-sale start date')
  }

  if (isDateRangeInvalid2(preRegEndDate, preSalesStartDate)) {
    _.set(errors, 'preSalesStartDate', 'The Pre-sale phase has to be after the Pre-registration phase')
  }

  return errors
}

class MyForm extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = { importantSetting: false, importantSettingID: null, submitPressed: false }
    this.updateField = this.updateField.bind(this)
    this.processSubmit = this.processSubmit.bind(this)
    this.handleChangeFlagPreRegEnabled = this.handleChangeFlagPreRegEnabled.bind(this)
    this.handleChangeFlagPreSaleEnabled = this.handleChangeFlagPreSaleEnabled.bind(this)
    this.handleDatePickerPreRegStart = this.handleDatePickerPreRegStart.bind(this)
    this.handleDatePickerPreRegEnd = this.handleDatePickerPreRegEnd.bind(this)
    this.handleDatePickerPreSalesStart = this.handleDatePickerPreSalesStart.bind(this)
    this.handleDatePickerPreSalesEnd = this.handleDatePickerPreSalesEnd.bind(this)
  }

  componentWillReceiveProps(nextProps){
    const { submitPressed } = this.state
    if(!_.isEmpty(nextProps.errors) && submitPressed){
      console.log('submit failed due to the errors, now scroll to top', nextProps.errors)
      this.setState({ submitPressed: false }, ()=> {
        const scroll = Scroll.animateScroll
        scroll.scrollToTop()
      })
    }
  }

  displayDisableTooltip() {
    return (
      <Tooltip placement="top" className="in" id="reward-tooltip">
				The Rewards System cannot be disabled once ticket sales have started, to ensure consistent ticket buyer experience.
      </Tooltip>
    )
  }

  updateField(field, value) {
    const { setFieldValue } = this.props
    setFieldValue(field, value)

    const fieldName = `attributes.${field}`
    const fields = form_helper_get()
    fields[fieldName] = fieldName
    form_helper_set(fields)
  }

  processSubmit(){
    const { handleSubmit } = this.props
    this.setState({ submitPressed: true }, () => {
      handleSubmit()
    })
  }

  handleChangeFlagPreRegEnabled(e) {
    this.updateField('flagPreRegEnabled', e.target.checked)
  }

  handleChangeFlagPreSaleEnabled(e) {
    this.updateField('flagPreSaleEnabled', e.target.checked)
  }

  handleDatePickerPreRegStart(date) {
    this.updateField('preRegStartDate', date)
  }

  handleDatePickerPreRegEnd(date) {
    this.updateField('preRegEndDate', date)
  }

  handleDatePickerPreSalesStart(date) {
    this.updateField('preSalesStartDate', date)
  }

  handleDatePickerPreSalesEnd(date) {
    this.updateField('preSalesEndDate', date)
  }

  render() {
    const {
      values,
      touched,
      errors,
      isSubmitting,
      handleSubmit,
      submitLabel,
    } = this.props

    return (
      <div className='event-ticketoptions'>
        <form ref="form" method="POST" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-sm-6 col-12">
              <div className="line">
                <div className="line-cell">
                  <div className="checkbox-switch">
                    <input
                      type="checkbox"
                      id="flagPreRegEnabled"
                      checked={values.flagPreRegEnabled}
                      value={values.flagPreRegEnabled}
                      onChange={this.handleChangeFlagPreRegEnabled}
                    />
                    <label htmlFor="flagPreRegEnabled" />
                  </div>
                </div>
                <div className="line-cell">
                  <label htmlFor="flagPreRegEnabled">Enable Pre-registration</label>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-12" style={{ position: 'static' }}>
              <DateTimePickerReact
                id="preRegStartDate"
                label="Pre-registration Start Date"
                placeholder="D MMM YYYY H:M AM"
                error={errors.preRegStartDate}
                touched={touched.preRegStartDate}
                value={values.preRegStartDate}
                onChange={this.handleDatePickerPreRegStart}
                disabled={!values.flagPreRegEnabled}
              />
              <div className='div-spacing-10' />
            </div>
            <div className="col-sm-6" style={{ position: 'static' }}>
              <DateTimePickerReact
                id="preRegEndDate"
                label="Pre-registration End Date"
                placeholder="D MMM YYYY H:M AM"
                error={errors.preRegEndDate}
                touched={touched.preRegEndDate}
                value={values.preRegEndDate}
                onChange={this.handleDatePickerPreRegEnd}
                disabled={!values.flagPreRegEnabled}
              />
              <div className='div-spacing-10' />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-12">
              <div className="line">
                <div className="line-cell">
                  <div className="checkbox-switch">
                    <input
                      type="checkbox"
                      id="flagPreSaleEnabled"
                      checked={values.flagPreSaleEnabled}
                      value={values.flagPreSaleEnabled}
                      onChange={this.handleChangeFlagPreSaleEnabled}
                    />
                    <label htmlFor="flagPreSaleEnabled" />
                  </div>
                </div>
                <div className="line-cell">
                  <label htmlFor="flagPreSaleEnabled">Enable Pre-sale</label>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-12" style={{ position: 'static' }}>
              <DateTimePickerReact
                id="preSalesStartDate"
                label="Presales Start (optional)"
                placeholder="D MMM YYYY H:M AM"
                error={errors.preSalesStartDate}
                touched={touched.preSalesStartDate}
                value={values.preSalesStartDate}
                onChange={this.handleDatePickerPreSalesStart}
                disabled={!values.flagPreSaleEnabled}
              />
              <div className='div-spacing-10' />
            </div>
            <div className="col-sm-6" style={{ position: 'static' }}>
              <DateTimePickerReact
                id="preSalesEndDate"
                label="Presales End (optional)"
                placeholder="D MMM YYYY H:M AM"
                error={errors.preSalesEndDate}
                touched={touched.preSalesEndDate}
                value={values.preSalesEndDate}
                onChange={this.handleDatePickerPreSalesEnd}
                disabled={!values.flagPreSaleEnabled}
              />
              <div className='div-spacing-10' />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <Button className="btn btn-success btn-xl btn-shadow" type="button" loading={isSubmitting} onClick={this.processSubmit}>{submitLabel || 'Save'}</Button>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

const PreRegistrationDates = withFormik({
  mapPropsToValues: (props) => ({
    flagPreRegEnabled: _.get(props, 'initialValues.flagPreRegEnabled'),
    flagPreSaleEnabled: _.get(props, 'initialValues.flagPreSaleEnabled'),
    preRegStartDate: _.get(props, 'initialValues.preRegStartDate'),
    preRegEndDate: _.get(props, 'initialValues.preRegEndDate'),
    preSalesStartDate: _.get(props, 'initialValues.preSalesStartDate'),
    preSalesEndDate: _.get(props, 'initialValues.preSalesEndDate'),

  }),

  // Custom sync validation
  validate: values => validate(values),

  handleSubmit: (values, { props, setSubmitting }) => {
    props.onSubmit({
      attributes: { ...values },
    })
      .then((v) => {
        setSubmitting(false)
      })
      .catch((err) => {
        setSubmitting(false)
      })
  },
  displayName: 'PreRegistrationDates', // helps with React DevTools
})(MyForm)

export default PreRegistrationDates
