import _size from 'lodash/size'
import _map from 'lodash/map'
import _result from 'lodash/result'
import _get from 'lodash/get'
import { connect } from 'react-redux'
import React from 'react'

import Button from '../_library/Button'
import Card from '../_library/Card'
import { UPDATE_BRAND_PAYMENT,FETCH_BRAND } from '../../_common/redux/brands/actions'
import PaymentMethodForm from './PaymentMethodForm'
import { getTitle } from '../utils/getTitle'

function PaymentMethodsTable(props) {
  if (!_size(props.methods)) {
    return (
      <Card title={' '}>
        <div className="text-center h3">
          There are no payment methods associated with this brand
        </div>
      </Card>
    )
  }
  return (
    <Card title={' '}>
      <div className="table-responsive">
        <table className="table table-borderless table-themed">
          <thead>
            <tr>
              <th>Name</th>
              <th>Method</th>
            </tr>
          </thead>
          <tbody>
            {_map(props.methods, m => <tr key={m.id}><td>{m.displayName}</td><td>{m.type}</td></tr>)}
          </tbody>
        </table>
      </div>
    </Card>
  )
}

@connect(
  (state) => ({
    brand: state.brands.selected,
    loading: state.loading.has('FETCH_BRAND'),
  }),
  { UPDATE_BRAND_PAYMENT,FETCH_BRAND }
)
export default class BrandPayments extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.handleSubmitPaymentMethod = this.handleSubmitPaymentMethod.bind(this)
    this.handleCancelAddPaymentMethod = this.handleCancelAddPaymentMethod.bind(this)
    this.handleAddPaymentMethod = this.handleAddPaymentMethod.bind(this)
  }
  componentWillMount() {
    const { brand: { id: brandId, displayName },  FETCH_BRAND, match: { params: { id } } } = this.props
    const configDocTitle = _get(this.props.configs, 'messages.documentTitle', '')
    document.title = getTitle(configDocTitle, [displayName])

    if (!brandId) { FETCH_BRAND(id) }
  }

  handleSubmitPaymentMethod(form) {
    const { match: { params: { id } } } = this.props
    const { UPDATE_BRAND_PAYMENT, push } = this.props
    return Promise.resolve(UPDATE_BRAND_PAYMENT(id, { ...form }))
      .catch(err => Promise.reject(_result(err, 'toFieldErrors', err)))
  }
  handleAddPaymentMethod() {
    this.setState({ addingPaymentMethod: true })
  }
  handleCancelAddPaymentMethod() {
    this.setState({ addingPaymentMethod: false })
  }
  render() {
    const { addingPaymentMethod } = this.state
    const { loading, brand, match: { params: { id } } } = this.props

    return (
      <div className="hold-transition skin-ttf sidebar-collapse">
        <div className="wrapper">
          <div className="content-wrapper">
            {loading && <div className="container-fluid">
              <div className="row">
                <div className="col-xs-12">
                  <div className="text-center h1"><i className="fa fa-spin fa-circle-o-notch" /></div>
                </div>
              </div>
            </div>}
            {brand && <div className="container-fluid">
              <div className="row">
                <div className="col-xs-12">
                  <h2>Payment Methods</h2>
                  <PaymentMethodsTable methods={brand.paymentMethods || []} />
                  {addingPaymentMethod && <PaymentMethodForm
                    onSubmit={this.handleSubmitPaymentMethod}
                    onCancel={this.handleCancelAddPaymentMethod} />}
                  {!addingPaymentMethod && <Button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.handleAddPaymentMethod}>Add Payment Method
                  </Button>}
                </div>
              </div>
            </div>}
          </div>
        </div>
      </div>
    )
  }
}
