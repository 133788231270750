import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import _chunk from 'lodash/chunk'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _isEmpty from 'lodash/isEmpty'

import EmptyBar from '../_library/EmptyBar'
import Notifications from '../_library/notifications/Notifications'
import { FETCH_BRANDS } from '../../_common/redux/brands/actions'
import LoadingBar from '../_library/LoadingBar'
import SearchInput, { SearchResultCount } from '../_library/SearchInput'
import { Pagination } from '../_library/Pagination'

import {
  get_brands,
  get_brands_pagination,
} from '../../_common/core/selectors'
import { getTitle } from '../utils/getTitle'

const BRAND_ITEMS_CHUNK_SIZE = 4
const BRANDS_SECTION_COUNT = 50
const PAGE_SIZE_OPTIONS = _map([10, 50, 100, 250, 500, 1000, 2500], item => ({ label: item, value: item }))

const buildBrandItem = ({ 
  id, 
  logo, 
  displayName, 
  parent, 
  parent: { 
    displayName: parentDisplayName 
  } = {} 
}) => (
  <div key={id} className="col-md-3 brands_container">
    <div className="card brands-card-radius">
      <Link className="card-block" to={'/brand/' + id + '/details'}>
        <div className='brand-card-block-inner'>
          {
            logo ? (
              <img className='brand_logo_image' src={logo} alt='no data' />
            ) : (
              <div className='brand-ico'>
                <img src={asset('/resources/images/brand_ico_inner.png')} alt='no data' />
              </div>
            )
          }
        </div>
             
        <div className="title" />
        <span className="brands_title">{displayName}</span>
      </Link>
      {parent && (
        <div className="parent_brand__name">
          <div
            title="Parent brand"
            style={{
              backgroundImage: `url('${asset(
                '/resources/images/system_icons/inactive/brands.svg'
              )}')`
            }}
          />
          <p>{parentDisplayName}</p>
        </div>
      )}
    </div>
  </div>
)

const BrandsPage = React.memo(({
  configs, 
  brands, 
  pagination,
  loading, 
  FETCH_BRANDS,
}) => {
  const [pageNumber, setPageNumber] = useState(1)
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(BRANDS_SECTION_COUNT)
  const [searchValue, setSearchValue] = useState('')

  const getContent = () => {
    let content = null

    if (loading) {
      content = <LoadingBar title={"Hold tight! We're getting your brand list..."} />
    } else if (brands.length) {
      const builtBrandItems = brands.map(buildBrandItem)
      content = _chunk(builtBrandItems, BRAND_ITEMS_CHUNK_SIZE).map((items, index) => (
        <div key={index} className="container-fluid">
          {items}
        </div>
      ))
    } else {
      content = <EmptyBar />  
    }

    return content
  }

  const onPageChange = selectedPage => {
    setPageNumber(selectedPage)
    setOffset((selectedPage * limit) - limit)
  }

  const onPageSizeChange = pageSizeOption => {
    const pageSize = parseInt(pageSizeOption.value)
    if (pageSize === limit) return

    setPageNumber(1)
    setOffset(0)
    setLimit(pageSize)
  }

  const onSearchChange = value => {
    setPageNumber(1)
    setOffset(0)
    setSearchValue(value)
  }

  useEffect(() => {
    const configDocTitle = _get(configs, 'messages.documentTitle', '')
    document.title = getTitle(configDocTitle)
  }, [configs])
  

  useEffect(() => {        
    FETCH_BRANDS({ offset, limit, search_query: searchValue })
  }, [FETCH_BRANDS, offset, limit, searchValue])
  
  return (
    <div className="body-main brands-page-container">
      <Notifications />
      <div>
        <div className="body-panel-header">
          <div className="left">
            <div className="title">Brands</div>
          </div>
          <div className="right">
            <Link className="btn btn-primary" to="/brands/new">
              <i className="fa fa-fw fa-plus" /> Create Brand
            </Link>
          </div>
        </div>
        <div className='brands-page-searchbar'>
          {searchValue && !loading && <SearchResultCount resultsCount={brands.length} />}
          <SearchInput
            showAsTag={true}
            onChange={onSearchChange}
            searchValue={searchValue}
          />
        </div>
        <div className="body-panel-spacing" />
        <div className="body-panel-content">{getContent()}</div>
        {
          !_isEmpty(brands) && !loading && (
            <Pagination
              activePage={pageNumber}
              pageRangeDisplayed={5}
              itemsCountPerPage={limit}
              totalItemsCount={pagination?.total || BRANDS_SECTION_COUNT}
              onChange={onPageChange}
              pageSizeOptions={PAGE_SIZE_OPTIONS}
              onPageSizeChange={onPageSizeChange}
            />
          )
        }
      </div>
    </div>
  )
})
  
const mapStateToProps = state => {
  const brands = get_brands(state)
  const pagination = get_brands_pagination(state)
  const loading = state.loading.has('FETCH_BRANDS')

  return {
    loading,
    brands,
    pagination
  }
}

const mapDispatchToProps = {
  FETCH_BRANDS,
}
  
export default connect(mapStateToProps, mapDispatchToProps)(BrandsPage)