import { createAsyncAction } from '../actions'
import { fetchAPI } from '../../core/http'
import { ERROR } from '../notification/actions'

let lastEventId = ''

const { FETCH_EVENT_INFLUENCERS } = createAsyncAction('FETCH_EVENT_INFLUENCERS', function(eid) {
  return (dispatch) => {
    lastEventId = eid
    return fetchAPI(`/api/events/${eid}/relationships/referrals/`)
      .then((res) => {
        if (eid !== lastEventId) {
          return
        }
        const out = { eid, influencers: res }
        dispatch(this.success(out))
        return res
      })
      .catch((err) => {
        if (eid !== lastEventId) {
          return
        }
        dispatch(ERROR(...err.errors))
        throw err
      })
  }
})


export {
  FETCH_EVENT_INFLUENCERS,
}

