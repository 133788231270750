import React from 'react'
import PropTypes from 'prop-types'

export default class CustomToggle extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onText: PropTypes.string,
    offText: PropTypes.string
  }

  handleChange = e => {
    const { onChange } = this.props
    const { checked } = e.target
    const fieldKey = e.target.id
    onChange(fieldKey, checked, e)
  }

  render() {
    const { id, children, offText, onText, value, checked, fieldKey, subText, disabled, direction, ...attr } = this.props
    const off = offText || null
    const on = onText || null
    const isOff = !!off
    const offStyle = attr.offStyle || {}

    const isRightStyle = direction === "right" ? "toggle-input-right" : undefined

    return (
      <div className={`toggle-group ${disabled ? 'disable-element' : ''}`}>
        <input
          id={id || fieldKey}
          type="checkbox"
          className="toggle-input"
          value={value || checked || false}
          checked={checked || false}
          {...attr}
          onChange={this.handleChange}
        />
        <div className={isRightStyle}>
          {off && (
            <div className="toggle-label-container" htmlFor={id || fieldKey} style={{ ...offStyle }}>
              <small className="toggle-label" htmlFor={id}>
                {off}
              </small>
            </div>
          )}
          <div className="toggle-control-container" htmlFor={id || fieldKey}>
            <label className="toggle-control" htmlFor={id || fieldKey} />
          </div>
          {on && (
            <div className="toggle-label-container" htmlFor={id || fieldKey}>
              <small className="toggle-label" htmlFor={id || fieldKey}>
                {on}
              </small>
            </div>
          )}
        </div>
        <div>
          {subText && (
            <div className={isOff ? 'toggle-sub-text inline' : 'toggle-sub-text'}>
              {subText ||
                `This will ensure the ticket is always shown on the event page. Turning this on ignores the stock levels of preceding tickets`}
            </div>
          )}
        </div>
      </div>
    )
  }
}
