import _isEmpty from 'lodash/isEmpty'
import _map from 'lodash/map'
import _find from 'lodash/find'
import React, { useState } from 'react'

import Field from '../_library/Field'
import Card from '../_library/Card'
import { countries } from '../constants/countries'
import { checkCountry, countryCodeAdapter } from '../utils/coutriesUtils'
import { IS_MOBILE_DEVICE } from '../../_common/core/utils'
import { parsePlaceResult } from '../events/form/EventForm/utils/placeUtils'

const BusinessDetails = React.memo(props => {
  const { values, touched, errors, handleChange, handleBlur, closed, updateField, onChangeCountry } = props
  const [suggestions, setSuggestions] = useState([])
  const [searching, setSearching] = useState(false)
  const [error, setError] = useState({})
  const [AutocompleteService] = useState(global.google ? new google.maps.places.AutocompleteService() : null)

  const handleAddressSelected = loc => {
    updateField('googleAddress', loc.label)

    const statuses = google.maps.places.PlacesServiceStatus
    const pyrmont = new google.maps.LatLng(-33.8665433, 151.1956316)
    const map = new google.maps.Map(document.getElementById('map'), {
      center: pyrmont,
      zoom: 15
    })

    const service = new google.maps.places.PlacesService(map)

    service.getDetails({ placeId: loc.id }, (res, status) => {
      let err
      if (status === statuses.ZERO_RESULTS) {
        err = 'No address found'
      } else if (status !== statuses.OK) {
        err = 'Unexpected error occurred while looking up address'
      }

      if (err) {
        return setError({ error: err })
      }

      const { country, city, postalCode, state, street, streetNumber } = parsePlaceResult(res, {
        isRnDomain: false,
        autoDefineVenue: false,
        useLongNames: true,
        useCountryShortName: true
      })

      const countryValue = countryCodeAdapter(country, 'gb', 'uk')
      const selectedCountry = _find(countries, (item, key) => key === countryValue) ? countryValue : ''
      updateField('country', selectedCountry)

      updateField('brandCity', city)
      updateField('zip', postalCode)
      updateField('brandState', state)
      updateField('addressLine1', street + ' ' + streetNumber)
      updateField('addressLine2', '')
    })
  }

  const handleSuggestions = suggestions => {
    setSearching(false)

    const cleaned = (suggestions || []).map(s => ({
      id: s.place_id,
      label: s.terms[0].value,
      sub: s.terms
        .slice(1)
        .map(t => t.value)
        .join(', '),
      details: s
    }))

    setSuggestions(cleaned)
  }

  return (
    <Card icon={'fa-briefcase'} title={'Business Details'} closed={closed}>
      <div className="row">
        <div className="col-xs-12">
          <div className={'form-group ' + (errors.country ? 'has-error' : '')}>
            <div className="floating-field-group active">
              <Field
                id="googleAddress"
                label="Address Search"
                className="input-address-create-brand"
                loading={searching}
                onChange={e => {
                  const { value } = e.target
                  updateField('googleAddress', value)
                  const statuses = google.maps.places.PlacesServiceStatus
                  AutocompleteService?.getPlacePredictions({ input: value }, (suggestions, status) => {
                    if (status === statuses.ZERO_RESULTS) {
                      return
                    } else if (status !== statuses.OK) {
                      return
                    }
                    handleSuggestions(suggestions)
                  })
                }}
                value={values.googleAddress}
                options={suggestions}
                onSelected={handleAddressSelected}
                error={errors.googleAddress}
                touched={touched.googleAddress}
              />
              <div id="map" />
              {!_isEmpty(error) && <div className="alert alert-danger">{error}</div>}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <div className={'form-group ' + (errors.country ? 'has-error' : '')}>
            <div className="floating-field-group active">
              <div className="floating-field">
                <label className="control-label" htmlFor="country">
                  Country
                </label>
                <select
                  className="form-control"
                  id="country"
                  value={values.country}
                  onBlur={handleBlur}
                  onChange={onChangeCountry}
                >
                  {_map(countries, (value, key) => (
                    <option key={key} value={key}>
                      {value}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {!!errors.country && touched.country && <div className="help-block">{errors.country}</div>}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <Field
            id="legalEntity"
            label="Legal Entity Name"
            value={values.legalEntity}
            error={errors.legalEntity}
            touched={touched.legalEntity}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      </div>
      {checkCountry(values.country, 'us') && (
        <div className="row">
          <div className="col-xs-6">
            <Field
              id="legalFirstName"
              label="First Name"
              value={values.legalFirstName}
              error={errors.legalFirstName}
              touched={touched.legalFirstName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div className="col-xs-6">
            <Field
              id="legalLastName"
              label="Last Name"
              value={values.legalLastName}
              error={errors.legalLastName}
              touched={touched.legalLastName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-sm-6">
          <Field
            id="addressLine1"
            label="Address (Line 1)"
            value={values.addressLine1}
            error={errors.addressLine1}
            touched={touched.addressLine1}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="col-sm-6">
          <Field
            id="addressLine2"
            label="Address (Line 2)"
            value={values.addressLine2}
            error={errors.addressLine2}
            touched={touched.addressLine2}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <Field
            id="brandCity"
            label="City"
            value={values.brandCity}
            error={errors.brandCity}
            touched={touched.brandCity}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="col-sm-6">
          <Field
            id="brandState"
            label="State"
            value={values.brandState}
            error={errors.brandState}
            touched={touched.brandState}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <Field
            id="zip"
            label="Zip/Postcode"
            value={values.zip}
            error={errors.zip}
            touched={touched.zip}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="col-sm-6">
          <Field
            id="taxID"
            label="GST/VAT Number"
            value={values.taxID}
            error={errors.taxID}
            touched={touched.taxID}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6 col-xs-12">
          <Field
            id="email"
            label="Contact Email Address"
            type="email"
            value={values.email}
            error={errors.email}
            touched={touched.email}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={'Customer replies to emails sent from the Event’s Messaging will come here.'}
          />
        </div>
        {checkCountry(values.country, 'nz') && (
          <div className="col-sm-6 col-xs-12">
            <Field
              id="nzbn"
              label="NZBN"
              value={values.nzbn}
              error={!IS_MOBILE_DEVICE() ? errors.nzbn : null}
              customError={IS_MOBILE_DEVICE() ? errors.nzbn : null}
              touched={touched.nzbn}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        )}
      </div>
    </Card>
  )
})

export default BusinessDetails
