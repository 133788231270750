import React from 'react'
import { connect } from 'react-redux'
import _get from 'lodash/get'

import { OboeAudience, TYPE_BRAND, SECTION_LIKES } from '../_library/OboeAudience'
import { getTitle } from '../utils/getTitle'

@connect(state => {
  const brand = state.brands.selected

  return {
    brand
  }
})
export default class BrandLikes extends React.Component {
  componentDidMount() {
    const {
      brand: { displayName }
    } = this.props

    const configDocTitle = _get(this.props.configs, 'messages.documentTitle', '')
    document.title = getTitle(configDocTitle, [displayName])
  }

  render() {
    const {
      match: {
        params: { id }
      }
    } = this.props
    return (
      <div className="audience-likes">
        <OboeAudience
          type={TYPE_BRAND}
          section={SECTION_LIKES}
          limitShowing={true}
          data={{
            url: `/api/audience/${id}/`,
            node: 'data.audience.likes.*',
            param: { type: 'brand', section: 'likes' }
          }}
        />
      </div>
    )
  }
}
