import React from 'react'

export const FieldUrl = ({
  style,
  type,
  name,
  className,
  label,
  error,
  touched,
  ...props
}) => (
  <div style={style} className={className}>
    <label htmlFor={name}>
      {label}
    </label>
    <div className="input-wrapper input-url-container">
      <div className="inner-label">
        <img
          alt=''
          className="link-prefix-img"
          src={asset('/resources/images/event-url.png')}
          style={{ height: 20, marginLeft: 9, marginRight: 5 }}
        />
        https://
      </div>
      <input
        type={type}
        name={name}
        {...props}
      />
      {error && touched && <div className="feedback-input">{error}</div>}
    </div>
  </div>
)

          
