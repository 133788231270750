import _get from 'lodash/get'
import { createAsyncAction } from '../actions'
import { fetchAPI } from '../../core/http'
import { ERROR } from '../notification/actions'

import { deletePreRegistrationActions } from '../../core/http_services'
import { showAxiosError } from '../../../web/utils/messenger'

//dates
const { FETCH_PRE_REGISTRATION_DATES } = createAsyncAction('FETCH_PRE_REGISTRATION_DATES', function (eid) {
  return dispatch =>
    fetchAPI(`/api/event/${eid}/preregistration/dates/`)
      .catch(err => {
        const errMsg = _get(err, 'errors[0].details')
        showAxiosError(err.errors[0], errMsg, 3)
        throw err
      })
      .then(res => {
        const ret = _get(res.data, '$original')
        dispatch(this.success({ data: ret }))
        return res
      })
})

const { UPDATE_PRE_REGISTRATION_DATES } = createAsyncAction(
  'UPDATE_PRE_REGISTRATION_DATES',
  function (eid, data) {
    return dispatch =>
      fetchAPI(`/api/event/${eid}/preregistration/dates/`, {
        method: 'PUT',
        body: JSON.stringify({ data })
      })
        .catch(err => {
          const errMsg = _get(err, 'errors[0].details')
          showAxiosError(err.errors[0], errMsg, 3)
          throw err
        })
        .then(res => {
          const ret = _get(res.data, '$original')
          dispatch(this.success({ data: ret }))
          Messenger().post({
            type: 'success',
            message: res.message,
            hideAfter: 3,
            showCloseButton: true
          })
          return res
        })
  }
)

//actions
const { FETCH_PRE_REGISTRATION_ACTIONS } = createAsyncAction(
  'FETCH_PRE_REGISTRATION_ACTIONS',
  function (eid) {
    return dispatch =>
      fetchAPI(`/api/event/${eid}/preregistration/actions/`)
        .catch(err => {
          dispatch(ERROR(...err.errors))
          dispatch(this.failed(err))
          throw err
        })
        .then(res => {
          const ret = _get(res.data, '$original')
          dispatch(this.success({ data: ret }))
          return res
        })
  }
)

const { CREATE_PRE_REGISTRATION_ACTIONS } = createAsyncAction(
  'CREATE_PRE_REGISTRATION_ACTIONS',
  function (eid, data) {
    return dispatch =>
      fetchAPI(`/api/event/${eid}/preregistration/actions/`, {
        method: 'POST',
        body: JSON.stringify({ data })
      })
        .catch(err => {
          const errMsg = _get(err, 'errors[0].details')
          showAxiosError(err.errors[0], errMsg, 3)
          throw err
        })
        .then(res => {
          const ret = _get(res.data, '$original')
          dispatch(this.success({ data: ret }))
          Messenger().post({
            type: 'success',
            message: res.message,
            hideAfter: 3,
            showCloseButton: true
          })
          return res
        })
  }
)
const { UPDATE_PRE_REGISTRATION_ACTIONS } = createAsyncAction(
  'UPDATE_PRE_REGISTRATION_ACTIONS',
  function (eid, data) {
    return dispatch =>
      fetchAPI(`/api/event/${eid}/preregistration/actions/`, {
        method: 'put',
        body: JSON.stringify({ data })
      })
        .catch(err => {
          const errMsg = _get(err, 'errors[0].details')
          showAxiosError(err.errors[0], errMsg, 3)
          throw err
        })
        .then(res => {
          const ret = _get(res.data, '$original')
          dispatch(this.success({ data: ret }))
          Messenger().post({
            type: 'success',
            message: res.message,
            hideAfter: 3,
            showCloseButton: true
          })
          return res
        })
  }
)
const { DELETE_PRE_REGISTRATION_ACTIONS } = createAsyncAction(
  'DELETE_PRE_REGISTRATION_ACTIONS',
  function (eid, data) {
    return dispatch => {
      const deleteData = async (id, form) => {
        try {
          const res = await deletePreRegistrationActions(id, form)
          dispatch(this.success(res))
          Messenger().post({
            type: 'success',
            message: res.message,
            hideAfter: 3,
            showCloseButton: true
          })
        } catch (err) {
          dispatch(ERROR([err]))
          dispatch(this.failed(err))
        }
      }
      return deleteData(eid, data)
    }
  }
)

//prizes

const { FETCH_PRE_REGISTRATION_PRIZES } = createAsyncAction('FETCH_PRE_REGISTRATION_PRIZES', function (eid) {
  return dispatch =>
    fetchAPI(`/api/event/${eid}/preregistration/prizes/`)
      .catch(err => {
        const errMsg = _get(err, 'errors[0].details')
        showAxiosError(err.errors[0], errMsg, 3)
        throw err
      })
      .then(res => {
        const ret = _get(res.data, '$original')
        dispatch(this.success({ data: ret }))
        return res
      })
})

const { DELETE_PRE_REGISTRATION_PRIZES } = createAsyncAction(
  'DELETE_PRE_REGISTRATION_PRIZES',
  function (eid, data) {
    return dispatch =>
      fetchAPI(`/api/event/${eid}/preregistration/prizes/`, {
        method: 'delete',
        body: JSON.stringify({ data })
      })
        .catch(err => {
          const errMsg = _get(err, 'errors[0].details')
          showAxiosError(err.errors[0], errMsg, 3)
          throw err
        })
        .then(res => {
          const ret = _get(res.data, '$original')
          dispatch(this.success({ data: ret }))
          Messenger().post({
            type: 'success',
            message: res.message,
            hideAfter: 3,
            showCloseButton: true
          })
          return res
        })
  }
)
const { CREATE_PRE_REGISTRATION_PRIZES } = createAsyncAction(
  'CREATE_PRE_REGISTRATION_PRIZES',
  function (eid, data) {
    return dispatch =>
      fetchAPI(`/api/event/${eid}/preregistration/prizes/`, {
        method: 'post',
        body: JSON.stringify({ data })
      })
        .catch(err => {
          const errMsg = _get(err, 'errors[0].details')
          showAxiosError(err.errors[0], errMsg, 3)
          throw err
        })
        .then(res => {
          const ret = _get(res.data, '$original')
          dispatch(this.success({ data: ret }))
          Messenger().post({
            type: 'success',
            message: res.message,
            hideAfter: 3,
            showCloseButton: true
          })
          return res
        })
  }
)

const { EDIT_PRE_REGISTRATION_PRIZES } = createAsyncAction(
  'EDIT_PRE_REGISTRATION_PRIZES',
  function (eid, data) {
    return dispatch =>
      fetchAPI(`/api/event/${eid}/preregistration/prizes/`, {
        method: 'PATCH',
        body: JSON.stringify({ data })
      })
        .catch(err => {
          const errMsg = _get(err, 'errors[0].details')
          showAxiosError(err.errors[0], errMsg, 3)
          throw err
        })
        .then(res => {
          const ret = _get(res.data, '$original')
          dispatch(this.success({ data: ret }))
          Messenger().post({
            type: 'success',
            message: res.message,
            hideAfter: 3,
            showCloseButton: true
          })
          return res
        })
  }
)

export {
  //dates
  FETCH_PRE_REGISTRATION_DATES,
  UPDATE_PRE_REGISTRATION_DATES,
  //actions
  FETCH_PRE_REGISTRATION_ACTIONS,
  CREATE_PRE_REGISTRATION_ACTIONS,
  UPDATE_PRE_REGISTRATION_ACTIONS,
  DELETE_PRE_REGISTRATION_ACTIONS,
  //prizes
  FETCH_PRE_REGISTRATION_PRIZES,
  DELETE_PRE_REGISTRATION_PRIZES,
  CREATE_PRE_REGISTRATION_PRIZES,
  EDIT_PRE_REGISTRATION_PRIZES
}
