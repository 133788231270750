import React from 'react'
import { connect } from 'react-redux'
import _get from 'lodash/get'

import { VERIFY_USER, GET_VERIFY_INFO, UPDATE_VERIFY_USER_STATUS } from '../../../_common/redux/auth/actions'

import Button from '../../_library/Button'
import Card from '../../_library/Card'
import IDVerificationModal from '../../_library/IDVerificationModal'
import { isFullVerified, isPendingVerification } from '../../utils/checkVerification'
import { showAxiosError, showMessage } from '../../utils/messenger'

@connect(
  state => {
    const verifyData = _get(state, 'auth.verifyData') || null
    const verifyInfo = _get(state, 'auth.user.verifyInfo') || null
    const isLoadingInfo = state.loading.has('GET_VERIFY_INFO')
    return {
      verifyData,
      verifyInfo,
      isLoadingInfo
    }
  },
  { VERIFY_USER, GET_VERIFY_INFO, UPDATE_VERIFY_USER_STATUS }
)
export default class IDVerificationForm extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false
    }
    this.refreshTimer = null
  }

  componentDidMount() {
    this.unMounted = false
    this.getVerifyInfo()
    this.refreshTimer = setInterval(() => {
      this.getVerifyInfo()
    }, 60 * 1000)
  }

  componentWillUnmount() {
    this.unMounted = true
    if (this.refreshTimer) clearInterval(this.refreshTimer)
  }

  verifyUser = async () => {
    const { VERIFY_USER } = this.props
    try {
      await VERIFY_USER()
    } catch (err) {}
  }

  getVerifyInfo = async () => {
    const { isLoadingInfo, GET_VERIFY_INFO } = this.props
    if (this.unMounted || isLoadingInfo) return

    try {
      await GET_VERIFY_INFO()
    } catch (err) {}
  }

  handleVerifyClick = () => {
    this.setState({ showModal: true }, () => {
      this.verifyUser()
    })
  }

  handleModalClose = () => {
    this.setState({ showModal: false })
  }

  handleSubmitSuccess = async () => {
    const { UPDATE_VERIFY_USER_STATUS } = this.props

    try {
      await UPDATE_VERIFY_USER_STATUS({
        verification: {
          verificationStatus: 'PENDING'
        }
      })
      await this.getVerifyInfo()

      showMessage('success', 'Your ID verification is approved!', 10, true)
    } catch (err) {
      showAxiosError(err)
    }

    this.setState(() => ({
      showModal: false
    }))
  }

  handleSubmitError = () => {
    this.setState(() => ({
      showModal: false
    }))

    showMessage(
      'error',
      // eslint-disable-next-line max-len
      `Unfortunately your ID verification has failed. Please check your email for further information regarding manual verification.`,
      10,
      true
    )
  }

  render() {
    const { verifyData, verifyInfo, isLoadingInfo } = this.props

    const { showModal } = this.state
    const isVerified = isFullVerified(verifyInfo)
    const isPending = isPendingVerification(verifyInfo)

    return (
      <Card icon={'fa-id-card-o'} title={'Account Verification'}>
        <IDVerificationModal
          doVerification={showModal}
          verifyData={verifyData}
          handleClose={this.handleModalClose}
          handleSubmitSuccess={this.handleSubmitSuccess}
          handleSubmitError={this.handleSubmitError}
        />
        {isLoadingInfo && (
          <div>
            <i className="fa fa-spin fa-spinner" aria-hidden="true" />
            &nbsp; Loading verification info...
          </div>
        )}
        {!isLoadingInfo && (
          <div>
            {isVerified && <div>Your account has been verified.</div>}
            {!isVerified && (
              <div>
                {isPending
                  ? 'Your account is pending verification.'
                  : 'Your account has not been verified yet.'}
                {!isPending && (
                  <Button
                    type="button"
                    className="btn btn-primary btn-shadow"
                    onClick={this.handleVerifyClick}
                    style={{ marginLeft: 15 }}
                  >
                    Verify
                  </Button>
                )}
              </div>
            )}
          </div>
        )}
      </Card>
    )
  }
}
