import _get from 'lodash/get'
import handleActions from 'redux-actions/lib/handleActions'
import { createAsyncHandlers } from '../actions'

const initialState = {
  performance: {}
}

const FETCH_TOUR_PERFORMANCE = createAsyncHandlers('FETCH_TOUR_PERFORMANCE', {
  success(state, action) {    
    const { data } = action.payload
    state.performance = _get(data, '$original', {})
  }
})

export default handleActions({
  ...FETCH_TOUR_PERFORMANCE
}, initialState)
