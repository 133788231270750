import handleActions from 'redux-actions/lib/handleActions'
import { createAsyncHandlers } from '../actions'

const initialState = {
  giftCardTypes: [],
  issuedGiftCards: [],
}

const FETCH_GIFT_CARD_TYPES = createAsyncHandlers('FETCH_GIFT_CARD_TYPES', {
  success(state, action) {
    const { giftCardTypes } = action.payload
    state.giftCardTypes = giftCardTypes
  }
})

const FETCH_ISSUED_GIFT_CARDS = createAsyncHandlers('FETCH_ISSUED_GIFT_CARDS', {
  success(state, action) {
    const { issuedCards } = action.payload
    state.issuedGiftCards = issuedCards.map(
      issuedCard => ({ ...issuedCard, ...issuedCard.$original, ...issuedCard.$relationships })
    )
  }
})


export default handleActions({
  ...FETCH_GIFT_CARD_TYPES,
  ...FETCH_ISSUED_GIFT_CARDS,
}, initialState)

