import React from 'react'
import { connect } from 'react-redux'
import _map from 'lodash/map'
import _get from 'lodash/get'
import _keys from 'lodash/keys'
import _isEqual from 'lodash/isEqual'
import _isEmpty from 'lodash/isEmpty'
import _forEach from 'lodash/forEach'
import _reduce from 'lodash/reduce'
import _groupBy from 'lodash/groupBy'

import { getTourPerfomanceData } from '../../../_common/core/http_services'

import LoadingBar from '../../_library/LoadingBar'
import { showAxiosError } from '../../utils/messenger'
import { Radio, RadioGroup } from '../../_library/RadioGroup'
import TourPerformanceByTicketType, { DEFAULT_PAGE_SIZE } from './ByTicketType'
import TourPerformanceByOrderDate from './ByOrderDate'
import { getTitle } from '../../utils/getTitle'

const DEFAULT_TIMEZONE = { value: '', label: '' }

@connect(state => ({
  user: state.auth.user,
  tour: state.tours.selected,
  performance: state.tourperformance.performance,
}))

export default class TourPerformance extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      isLoadingSearchData: false,
      isLoadingPageData: false,
      tourPerformanceData: {},
      selectedTab: 'salesByTicketType',
      section: '',
      selectedTimezone: DEFAULT_TIMEZONE,
      searchValue: '',
      sortBy: { column: 'eventDate', asc: true, isLoading: false }
    }
  }

  componentDidMount() {
    const { tour: { displayName }, configs } = this.props
    const configDocTitle = _get(configs, 'messages.documentTitle', '')
    document.title = getTitle(configDocTitle, [displayName])
    this.fetchTourPerformance({})
  }

  fetchTourPerformance = async ({
    page = 1,
    limit = DEFAULT_PAGE_SIZE,
    searchValue = this.state.searchValue,
    sortBy = this.state.sortBy
  }) => {
    const { tour } = this.props
    const { section } = this.state

    try {
      const options = section
        ? { section }
        : {
          limit,
          page,
          filter: { name: searchValue },
          order: sortBy.column,
          sort: sortBy.asc ? 'ASC' : 'DESC'
        }
      const { data, pagination } = await getTourPerfomanceData(tour.id, options)
      this.setState({ pagination, sortBy })
      this.handleResponse(data, null, false)
    } catch (error) {
      this.handleResponse(null, error, false)
    }
  }

  handleResponse = (tourPerformanceData, error, isLoadingFinished) => {
    const { section, isLoadingSearchData, isLoadingPageData } = this.state

    if (this.isUnmounted) return

    if (error) {
      showAxiosError(error)
    } else {
      if (
        !_isEqual(this.initialData, tourPerformanceData) ||
        isLoadingSearchData !== isLoadingFinished ||
        isLoadingPageData !== isLoadingFinished
      ) {
        this.initialData = tourPerformanceData
        if (section) {
          const [timezone] = this.getTimezoneData(tourPerformanceData)
          this.setState({ selectedTimezone: { value: timezone, label: timezone } })
        }

        this.setState({
          tourPerformanceData,
          isLoading: false,
          isLoadingSearchData: false,
          isLoadingPageData: false,
          isLoadingTabData: false
        })
      }
    }
  }

  handleSortChange = item => {
    const { pagination: { limit } } = this.state

    const sortBy = {
      column: item.key,
      asc: !this.state.sortBy.asc,
      isLoading: true
    }
    
    this.setState({ sortBy }, () => {
      this.fetchTourPerformance({
        limit,
        sortBy: {
          ...sortBy,
          isLoading: false
        }
      })
    })
  }

  getChildComponent = () => {
    const {
      isLoadingPageData,
      isLoadingSearchData,
      tourPerformanceData,
      pagination,
      selectedTab,
      selectedTimezone,
      searchValue,
      sortBy
    } = this.state

    let performanceData = []

    const shouldBeEmptyString = sortBy.asc

    switch (selectedTab) {
      case 'salesByTicketType':
        _keys(tourPerformanceData).forEach(eventId => {
          const eventPerformance = tourPerformanceData[eventId]
          _keys(eventPerformance).forEach(id => {
            const { isOnlineEvent } = eventPerformance[id]
            const eventVenue = eventPerformance[id].eventVenue || {}

            performanceData.push({
              id,
              eventId,
              venue: isOnlineEvent
                ? (shouldBeEmptyString ? '' : null)
                : eventVenue.displayName || eventVenue.city
                ? `${eventVenue.displayName || ''}, ${eventVenue.city || ''}`
                : 'Not Defined',
              ...eventPerformance[id]
            })
          })
        })
        return (
          <TourPerformanceByTicketType
            data={performanceData}
            dataForCopy={performanceData}
            sortBy={sortBy}
            // search props
            showSearchLoading={true}
            showEmptyBar={true}
            isLoadingSearchData={isLoadingSearchData}
            getSearchedData={this.getSearchedData}
            searchValue={searchValue}
            // pagination props
            showPageLoading={true}
            isLoadingPageData={isLoadingPageData}
            pagination={pagination}
            handlePageChange={this.handlePageChange}
            handleSortChange={this.handleSortChange}
          />
        )
      case 'salesByOrderDate':
        const [timezone, timezoneData, timezoneOptions] = this.getTimezoneData(
          tourPerformanceData,
          selectedTimezone.value
        )

        performanceData = _get(timezoneData, 'data') || []
        const updatedData = []
        const groupedByOrderDate = _groupBy(performanceData, 'orderDate')
        _forEach(groupedByOrderDate, (item, key) => {
          updatedData.push({
            id: key,
            orderDate: key,
            group_num_sales: _reduce(item, (sum, n) => sum + parseInt(n.num_sales), 0),
            revenue: _reduce(item, (sum, n) => sum + parseInt(n.num_sales) * parseFloat(n.cost), 0),
            grossRevenue: _reduce(item, (sum, n) => sum + parseInt(n.num_sales) * parseFloat(n.price), 0)
          })
        })

        return (
          <TourPerformanceByOrderDate
            data={updatedData}
            timezoneOptions={timezoneOptions}
            selectedTimezone={selectedTimezone}
            handleTimezoneChange={this.handleTimezoneChange}
          />
        )

      default:
        return null
    }
  }

  getTimezoneData = (data, defaultTimezone) => {
    const timezones = data.timezones || {}
    const timezoneOptions = _map(_keys(timezones), timezone => ({ value: timezone, label: timezone }))
    const [firstTimezone] = _keys(timezones)
    const firstTimezoneData = timezones[firstTimezone] || {}
    const timezone = defaultTimezone ? defaultTimezone : firstTimezone
    const timezoneData = defaultTimezone ? timezones[defaultTimezone] : firstTimezoneData

    return [timezone, timezoneData, timezoneOptions]
  }

  handleTimezoneChange = timezoneOption => {
    this.setState({ selectedTimezone: timezoneOption })
  }

  handlePageChange = (pageNumber, limit) => {
    this.setState({ isLoadingPageData: true }, () => {
      this.fetchTourPerformance({ page: pageNumber, limit, searchValue: this.state.searchValue })
    })
  }

  getSearchedData = ({ page, limit, searchValue }) => {
    const { typingTimeout, tourPerformanceData } = this.state

    this.setState({ searchValue })

    if (searchValue && searchValue.length < 3 && searchValue.length !== 0 && !_isEmpty(tourPerformanceData)) {
      return
    }

    if (typingTimeout) {
      clearTimeout(typingTimeout)
    }

    this.setState({
      isLoadingSearchData: true,
      typingTimeout: setTimeout(this.fetchTourPerformance, 400, { page, limit, searchValue })
    })
  }

  onChangeShowGroupsByDate = selectedTab => {
    if (selectedTab === this.state.selectedTab) return
    const isOrderDateTab = selectedTab === 'salesByOrderDate'
    this.setState(
      prevState => ({
        ...prevState,
        selectedTab,
        section: isOrderDateTab ? 'tickets_daily' : '',
        isLoadingTabData: true,
        tourPerformanceData: {},
        selectedTimezone: isOrderDateTab ? prevState.selectedTimezone : DEFAULT_TIMEZONE
      }),
      () => {
        this.fetchTourPerformance({})
      }
    )
  }

  render() {
    const { isLoading, isLoadingTabData, selectedTab } = this.state

    return (
      <div className="tour-performance">
        {isLoading && <LoadingBar title={"Hold tight! We're getting tour performance..."} />}
        {!isLoading && (
          <div
            className="table-background"
            style={{
              padding: 0,
              boxShadow: '0px 1.3px 0px rgb(0 0 0 / 20%)'
            }}
          >
            <RadioGroup name="fruit" selectedValue={selectedTab} onChange={this.onChangeShowGroupsByDate}>
              <Radio name="salesByTicketType" value="salesByTicketType" label="Sales by Ticket Type" />
              <Radio name="salesByOrderDate" value="salesByOrderDate" label="Total Daily Sales" />
            </RadioGroup>
            {isLoadingTabData ? <LoadingBar /> : this.getChildComponent()}
          </div>
        )}
      </div>
    )
  }
}
