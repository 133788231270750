import React from 'react'
import { connect } from 'react-redux'
import _get from 'lodash/get'
import _isEqual from 'lodash/isEqual'

import { FETCH_AUDIENCE } from '../../_common/redux/audience/actions'
import { FETCH_BRAND } from '../../_common/redux/brands/actions'
import { Chart } from '../_library/Chart'
import Card from '../_library/Card'
import ProgressCircle from '../_library/ProgressCircle'
import { getTitle } from '../utils/getTitle'

const STATE_STATUS_INIT = 0
const STATE_STATUS_LOADING = 1
const STATE_STATUS_LOADING_SUCCESSED = 2
const STATE_STATUS_LOADING_FAILED = 3

@connect(
  (state) => {
    const brand = state.brands.selected
    const audience = _get(state.audience, 'brand.gender')

    return {
      brand,
      audience,
    }
  },
  { FETCH_BRAND, FETCH_AUDIENCE }
)
export default class BrandAudienceDemoGraphics extends React.Component {
  constructor(props) {
    super(props)
    this.state = { status: STATE_STATUS_INIT }
  }
  componentDidMount() {
    if (this.state.status == STATE_STATUS_LOADING) {
      return
    }
    const { brand: { displayName }, match: { params: { id } }, FETCH_AUDIENCE } = this.props
    const configDocTitle = _get(this.props.configs, 'messages.documentTitle', '')
    document.title = getTitle(configDocTitle, [displayName])

    const loadingSetter = (val) => () =>{
      this.setState({ status: val })			
    }
    Promise.resolve(FETCH_AUDIENCE(id, 'brand', 'gender'))
      .catch(loadingSetter(STATE_STATUS_LOADING_FAILED))
      .then(loadingSetter(STATE_STATUS_LOADING_SUCCESSED))
    loadingSetter(STATE_STATUS_LOADING)()
  }

  componentDidUpdate(prevProps) {
    const { audience } = this.props
    const { status } = this.state

    if (!_isEqual(audience, prevProps.audience) || status > STATE_STATUS_LOADING) {
      const gender = _get(audience, 'audience.gender') || {
        male: 0,
        female: 0
      }

      const sum = gender.male + gender.female
      const malePercent = sum ? parseInt((gender.male / sum) * 100) : 0
      const femalePercent = sum ? parseInt((gender.female / sum) * 100) : 0

      this.setState({
        gender,
        malePercent,
        femalePercent,
        status: STATE_STATUS_INIT
      })
    }
  }

  render() {
    const { audience } = this.props
    const { status, gender = {}, malePercent, femalePercent } = this.state
    const isEmptyData = !(gender.female || gender.male)

    return (
      <div className="demographics">
        <Card icon={'fa-male'} icon2={'fa-female'} title={'Gender Breakdown'}>
          <div className="card-block-title">
            <div className="highlight">
              Ticket Buyers by <strong>Gender</strong>
            </div>
          </div>
          <div className="row gender-breakdown">
            {!isEmptyData && (
              <div className="col-xs-12 col-sm-4 pie-component">
                <Chart
                  height="200px"
                  width="200px"
                  chartConfig={{
                    type: 'pie',
                    hideLegend: true,
                    displayPercentage: true,
                    data: {
                      labels: ['FEMALE', 'MALE'],
                      datasets: [
                        {
                          data: [gender.female, gender.male],
                          backgroundColor: ['#FB52AE', '#5876FF']
                        }
                      ]
                    },
                    options: {
                      legend: {
                        display: false
                      }
                    }
                  }}
                />
                <div className="pie-component-bar">
                  <div className="pie-component-bar-left">
                    <div>MALE</div>
                  </div>
                  <div className="pie-component-bar-center">
                    <div>OVERALL</div>
                  </div>
                  <div className="pie-component-bar-right">
                    <div>FEMALE</div>
                  </div>
                </div>
              </div>
            )}
            <div className={`col-xs-12 col-sm-${isEmptyData ? 6 : 4} female-circle`}>
              <ProgressCircle
                img={asset('/resources/images/icon-female.svg')}
                value={gender.female_percent}
              />
              <div className="circle-info">
                <div className="circle-description">
                  <div className="description1">
                    <div className="circle-value">{gender.female}</div>
                    <div className="circle-type">FEMALE</div>
                  </div>
                  <div className="description2">Ticket Buyers</div>
                </div>
              </div>
            </div>
            <div className={`col-xs-12 col-sm-${isEmptyData ? 6 : 4} male-circle`}>
              <ProgressCircle img={asset('/resources/images/icon-male.svg')} value={gender.male_percent} />
              <div className="circle-info">
                <div className="circle-description">
                  <div className="description1">
                    <div className="circle-value">{gender.male}</div>
                    <div className="circle-type">MALE</div>
                  </div>
                  <div className="description2">Ticket Buyers</div>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
    )
  }
}
