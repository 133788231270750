import _get from 'lodash/get'
import _reduce from 'lodash/reduce'
import { baseRequest } from '.'

export const getPerfomanceData = async (eventId, section, filter={}) => {
  filter = _reduce(filter, (acm, val, key) => {
    acm[`filters[${key}]`] = val
    return acm
  }, {})
  const res = await baseRequest.get(`events/${eventId}/relationships/performance/`, {
    params: {
      section,
      ...filter,
    }
  })

  return _get(res, 'data.data')
}

export const resendAllPromoterLinks = async(eventId)=>await baseRequest.post(`event/${eventId}/send-promoter-links`)
