
export const createValidator = (error_message, checkedFunction) => (...value) => checkedFunction(...value) ? undefined : error_message


export const combineValidators = (...validators) => (...value) => {
  for (let i = 0; i < validators.length; ++i) {
    const error_message = validators[i](...value)
    if (error_message !== undefined) return error_message
  }
}

export const or_Validators = (error_message, ...validators) => (...value) => {
  let someAreValid = false
  for (let i = 0; i < validators.length; ++i) {
    const error = validators[i](...value)
    if (error === undefined) {
      someAreValid = true
      break
    }
  }
  return someAreValid ? undefined : error_message
}

export const combineNormalizers = (...normalizers) => (value) =>
  normalizers.reduce((currentResult, normalizer) => normalizer(currentResult), value)