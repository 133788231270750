import { createAsyncAction } from '../actions'
import { fetchAPI } from '../../core/http'
import { ERROR } from '../notification/actions'
import { cancelInvitation } from '../../core/http_services/invitations'

// invitations

const { SEND_INVITATIONS } = createAsyncAction('SEND_INVITATIONS', function (eid, form) {
  const body = {
    data: {
      ...form,
      relationships: {
        event: {
          data: { type: 'event', id: eid }
        }
      }
    }
  }
  return dispatch =>
    fetchAPI(`/api/invitations`, {
      method: 'POST',
      body: JSON.stringify(body)
    })
      .catch(err => {
        dispatch(ERROR(...err.errors))
        dispatch(this.failed(err))
        throw err
      })
      .then(res => res)
})

const { CANCEL_INVITATION } = createAsyncAction('CANCEL_INVITATION', function (invitationId) {
  return dispatch =>
    cancelInvitation(invitationId)
      .catch(error => {
        if (error.response.status === 422 || error.response.status === 404) {
          dispatch(this.failed(error.response.data.details))
          throw error.response.data.details
        }
        throw error
      })
      .then(res => res)
})

export { SEND_INVITATIONS, CANCEL_INVITATION }
