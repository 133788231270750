import { createAsyncAction } from '../actions'
import { fetchAPI } from '../../core/http'
import { ERROR } from '../notification/actions'
import _forEach from 'lodash/forEach'

let lastEventId = ''
const { FETCH_EVENT_RSVP } = createAsyncAction('FETCH_EVENT_RSVP', function(
  eventId,
  limit,
  offset
) {
  const params = {}
  const propParams = {
    rn: true,
    limit,
    offset
  }

  _forEach(propParams, (item, key) => {
    if (item) {
      params[key] = item
    }
  })

  return dispatch => {
    lastEventId = eventId
    return fetchAPI(`/api/event/${eventId}/rsvp`, {
      method: 'GET',
      params,
      withCredentials: true
    })
      .then(res => {
        if (lastEventId !== eventId) {
          return
        }
        const out = { rsvp: res, eventId }
        dispatch(this.success(out))
        return res
      })
      .catch(err => {
        if (lastEventId !== eventId) {
          return
        }
        dispatch(ERROR(...err.errors))
        throw err
      })
  }
})

export {
  FETCH_EVENT_RSVP,
}
