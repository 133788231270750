export const EVENT_TYPES = [
  {
    id: 'eventType_festival',
    label: 'Festival'
  },
  {
    id: 'eventType_concert',
    label: 'Concert'
  },
  {
    id: 'eventType_esportsTournament',
    label: 'Esports Tournament'
  },
  {
    id: 'eventType_clubShow',
    label: 'Club Show'
  },
  {
    id: 'eventType_stadiumShow',
    label: 'Stadium Show'
  },
  {
    id: 'eventType_convention',
    label: 'Convention'
  },
  {
    id: 'eventType_conference',
    label: 'Conference'
  },
  {
    id: 'eventType_communityEvent',
    label: 'Community Event'
  },
  {
    id: 'eventType_technologyEvent',
    label: 'Technology Event'
  },
  {
    id: 'eventType_gamingEvent',
    label: 'Gaming Event'
  },
  {
    id: 'eventType_corporateEvent',
    label: 'Corporate Event'
  },
  {
    id: 'eventType_businessEvent',
    label: 'Business Event'
  },
  {
    id: 'eventType_privateEvent',
    label: 'Private Event/Party'
  },
  {
    id: 'eventType_sportsEvent',
    label: 'Sports Event'
  },
  {
    id: 'eventType_driveInEvent',
    label: 'Drive-In Event'
  },
  {
    id: 'eventType_outdoorEvent',
    label: 'Outdoor Event'
  }
]
