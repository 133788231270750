import React from 'react'
import classNames from 'classnames'

export default React.memo((props) => {
  const {
    disabled,
    loading,
    className,
    success,
    children,
    loadingContent,
    succesContent,
    ..._props
  } = props

  let content = null
  if (loading) {
    content = loadingContent || <i className="fa fa-circle-o-notch fa-fw fa-spin" />
  } else if (success) {
    content = succesContent || <i className="fa fa-fw fa-check" />
  } else {
    content = children
  }

  const cs = classNames(className, success ? 'btn-success' : '')

  return (
    <button
      {..._props}
      className={cs}
      disabled={disabled || loading}
    >
      {content}
    </button>
  )
})
