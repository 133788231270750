import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Button from '../../../_library/Button'

export default class BrandRow extends React.Component {
  static propTypes = {
    values: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
    organizations: PropTypes.array.isRequired,
    updateField: PropTypes.func.isRequired,
    onClickNewBrand: PropTypes.func.isRequired
  }

  shouldComponentUpdate(nextProps) {
    const { values: { owner: pValuesOwner }, errors: { owner: pErrorOwner }, touched: { owner: pTouchedOwner }, organizations: pOrganizations } = this.props
    const { values: { owner: nValuesOwner }, errors: { owner: nErrorOwner }, touched: { owner: nTouchedOwner }, organizations: nOrganizations } = nextProps

    return (
      pValuesOwner !== nValuesOwner ||
      pErrorOwner !== nErrorOwner ||
      pTouchedOwner !== nTouchedOwner ||
      pOrganizations !== nOrganizations
    )
  }

  handleChangeOwner = e => {
    const { organizations, updateField, FETCH_BRAND } = this.props
    const { value } = e.target
    updateField('owner', _.find(organizations, { id: value }))
    FETCH_BRAND(value)
  }

  render() {
    const { values, errors, touched, organizations, onClickNewBrand } = this.props
    let filteredOrg = _.uniqBy(organizations, 'id')
    filteredOrg = filteredOrg.sort((a, b) => a.displayName.trim().localeCompare(b.displayName))
    const eventBrand = values.owner ? filteredOrg.find(brand => brand.id === values.owner.id) : null
    return (
      <div className="row">
        <div className="row-brand col-xs-12 select-brand-val">
          <div className={'form-group dropdown-brand ' + (errors.owner ? 'has-error' : '')}>
            <div className="floating-field-group active">
              <div className="floating-field">
                <label className="control-label" htmlFor="brand">
                  Brand
                </label>
                <select
                  id="owner"
                  className="form-control"
                  value={_.get(values.owner, 'id')}
                  onChange={this.handleChangeOwner}
                >
                  {values.owner && !eventBrand ? (
                    <option key={values.owner.id} value={values.owner.id}>{values.owner.displayName}</option>
                  ) : (
                    <option value="">Select the brand running this event</option>
                  )}
                  {_.map(filteredOrg, (o, index) => (
                    <option key={o.id} value={o.id}>
                      {o.displayName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {!!errors.owner && touched.owner && <div className="help-block">{errors.owner}</div>}
          </div>
          <div className="btn-create-brand">
            <Button className="btn btn-default btn-shadow" type="button" onClick={onClickNewBrand}>
              Create Brand
            </Button>
          </div>
        </div>
      </div>
    )
  }
}
