import { baseRequest } from '.'
import _get from 'lodash/get'
import { invitationAdapter } from './adapters'

export const cancelInvitation = async invitationId => {
  const res = await baseRequest.delete(`invitation/${invitationId}/cancel`)
  return res
}

export const getEventInvitations = async (
  event_id,
  search_query,
  page,
  limit,
  optionSortBy,
  optionSortOrder,
  timezone
) => {
  const res = await baseRequest.get(`events/${event_id}/relationships/invitations/`, {
    params: {
      search_query,
      page,
      limit,
      'options[sort_by]': optionSortBy,
      'options[sort_order]': optionSortOrder
    }
  })
  const data = _get(res, 'data.data.data')
  return invitationAdapter(data, timezone)
}
