import React from 'react'
import _get from 'lodash/get'
import _set from 'lodash/set'

import { withFormik } from 'formik'

import Button from '../../_library/Button'
import TagsField from '../../_library/TagsField'

function validateMember(data) {
  const required = ['role']
  const errors = {}

  if (_get(data, 'emails').length === 0) {
    _set(errors, 'emails', 'Required')
  }

  required.forEach(f => {
    if (!_get(data, f)) {
      _set(errors, f, 'Required')
    }
  })

  const re = global.emailRegex
  for (const em of _get(data, 'emails')) {
    if (!re.test(em)) {
      _set(errors, 'emails', 'Invalid')
    }
  }

  return errors
}

export const memberRoles = [
  { value: 'owner', label: 'Owner - Full Access' },
  { value: 'admin', label: 'Administrator - Full Access' },
  { value: 'stats', label: 'Stats - Read Only (with Revenue)' },
  { value: 'limited_stats', label: 'Limited Stats - Read Only (without Revenue)' },
  { value: 'promoter', label: 'Promoter - Traffic Partner / Affiliate Only' },
  { value: 'staff', label: 'Door Staff - Entry Fairy App Access Only' },
  { value: 'curator', label: 'Curator - Access to Guest Tickets and Invitations Only' },
  { value: 'support', label: 'Support - Access to Orders' },
  { value: 'limited_support', label: 'Limited Support - Access to Orders' }
]

class MyForm extends React.Component {
  constructor(props) {
    super(props)
    this.handleCancel = this.handleCancel.bind(this)
    this.emailInputRef = React.createRef()
  }

  handleCancel() {
    if (this.props.onCancel) {
      this.props.onCancel()
    }
  }

  changeRole(r) {
    const { setFieldValue } = this.props
    setFieldValue('role', r)
  }

  onTagsChange = tags => {
    const { setFieldValue } = this.props
    setFieldValue('emails', tags)
  }

  render() {
    const { submitLabel } = this.props
    const { values, touched, errors, isSubmitting, handleSubmit, setFieldValue } = this.props
    return (
      <div className="brand-team-invitation-form">
        <div className="row">
          <div className="col-xs-12 floating-field ">
            <div className="eventpromotion-promotion-tag-label">
              Email Addresses (press space or enter after each one)
            </div>
            <TagsField
              tagsContainerClassName={`form-control${
                errors.emails && touched.emails ? ' form-control-error' : ''
              }`}
              value={values.emails}
              onChange={this.onTagsChange}
              controlOutside
              placeholder={'Add an email'}
              focusOnStart
              error={errors.emails}
              touched={touched.emails}
              inputValue={values.email}
              onChangeInput={value => {
                setFieldValue('email', value)
              }}
              renderTag={props => {
                const { tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other } = props
                const isValid = global.emailRegex.test(tag)
                return (
                  <span
                    key={key}
                    {...other}
                    style={{
                      color: isValid ? 'rgb(233, 233, 233)' : '#d27373',
                      maxWidth: '100%',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      verticalAlign: 'bottom'
                    }}
                  >
                    {getTagDisplayValue(tag)}
                    {!disabled && <a className={classNameRemove} onClick={e => onRemove(key)} />}
                  </span>
                )
              }}
              onlyUnique={true}
              addKeys={[9, 13, 32, 188]}
              pasteSplit
              inputClassName="input-responsive"
              tagsInputClassName="limit-input"
            />
          </div>
        </div>
        <div className="spacer" />
        <div className="row">
          <div className="col-sm-12">
            <label>Role</label>
            {memberRoles.map((mr, index) => (
              <div className="team-new-member-role" key={index}>
                <input
                  type="radio"
                  id={'role-' + mr.value}
                  name="brand-team-role"
                  checked={values.role === mr.value}
                  onChange={this.changeRole.bind(this, mr.value)}
                />
                <label htmlFor={'role-' + mr.value}>{mr.label}</label>
              </div>
            ))}
          </div>
        </div>
        <div className="text-center btn-toolbar">
          <Button
            type="submit"
            className="btn btn-success btn-lg btn-shadow"
            loading={isSubmitting}
            onClick={handleSubmit}
          >
            {submitLabel}
          </Button>
          <Button
            className="btn btn-danger btn-lg btn-shadow"
            type="button"
            disabled={isSubmitting}
            onClick={this.handleCancel}
          >
            Cancel
          </Button>
        </div>
      </div>
    )
  }
}

const BrandTeamInvitationForm = withFormik({
  mapPropsToValues: props => ({
    email: '',
    emails: _get(props, 'initialValues.attributes.emails') || [],
    role: _get(props, 'initialValues.attributes.role') || ''
  }),

  // Custom sync validation
  validate: values => validateMember(values),

  handleSubmit: (values, { props, setSubmitting }) => {
    props
      .onSubmit({
        attributes: values
      })
      .then(v => {
        setSubmitting(false)
      })
      .catch(err => {
        setSubmitting(false)
      })
  },
  displayName: 'BrandTeamInvitationForm' // helps with React DevTools
})(MyForm)

export default BrandTeamInvitationForm
