import _set from 'lodash/set'
import _get from 'lodash/get'
import React from 'react'
import { withRouter } from 'react-router-dom'
import Modal from 'react-modal'
import { withFormik } from 'formik'

import modalStyle from '../../../_common/core/modalStyle'
import { extractContent } from '../../../_common/core/utils'
import { ENV } from '../../constants/env'
import Field from '../../_library/Field'
import Button from '../../_library/Button'
import RichTextArea from '../../_library/RichTextArea'


function validateForm(data) {
  const errors = {}
  if (!_get(data, 'subject')) {
    _set(errors, 'subject', 'Required')
  }
  const extract_content = extractContent(_get(data, 'body'))
  const pure_body = extract_content === '+' ? '' : extract_content
  if (!pure_body) {
    _set(errors, 'body', 'Body is Required')
  }
  return errors
}

@withRouter
class EmailToTicketHolders extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      emailTemplatesModalOpen: false,
      isConfirmingModalOpen: false,
    }
    this.editorRef = React.createRef()
  }

  componentDidMount() {
    const { values, brand } = this.props
    if (values.subject === null || values.subject === '') {
      setTimeout(() => {
        this.props.setFieldValue('subject', 'Message to attendees of ' + brand.displayName)
      }, 0)
    }
  }

  onCheckboxChange = domEvent => {
    const isChecked = domEvent.target.checked
    if (isChecked) {
      const targetCheckboxId = domEvent.target.id
      const { setFieldValue } = this.props
      setFieldValue('toBrandSubscribers', targetCheckboxId === 'toBrandSubscribers')
      setFieldValue('toAllBrandTeamMembers', targetCheckboxId === 'toAllBrandTeamMembers')
    }
  }


  setEmailTemplatesModalState = value => () => {
    this.setState({
      emailTemplatesModalOpen: value
    })
  }

  selectEmailTemplate(template) {
    this.props.setFieldValue('subject', template.subject)
    if (template.body) {
      this.editorRef.current?.setContent(template.body)
    } else if (template.preview_url) {
      $.get(template.preview_url, result => {
        this.editorRef.current?.setContent(result)
      })
    }
    this.setState({
      emailTemplatesModalOpen: false
    })
  }

  onBodyChange = content => this.props.setFieldValue('body', content)

  setConfirmingModalState = value => () => {
    this.setState({ isConfirmingModalOpen: value })
  }

  handleSubmit = () => {
    this.setConfirmingModalState(false)()
    this.props.handleSubmit()
  }

  render() {
    const { emailTemplatesModalOpen, isConfirmingModalOpen } =
      this.state
    let {
      brand,
      values,
      isSubmitting,
      handleChange,
      handleBlur,
      errors,
      touched,
      submitLabel,
      emailTemplates = [],
      history: { replace }
    } = this.props

    if (emailTemplates.length) {
      emailTemplates = emailTemplates.filter(el => el.type === 'messaging' && el.enabled === 1)
    }


    return (
      <form ref="form" onSubmit={e => e.preventDefault()}>
        <div className="EmailToTicketHolders">
          <div className="row">
            <div className="col-xs-12 text-right">
              <div className="btn btn-success btn-shadow" onClick={this.setEmailTemplatesModalState(true)}>
                Select from Email Templates
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <Field
                id="subject"
                label="Subject"
                value={values.subject}
                error={errors.subject}
                touched={touched.subject}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </div>
          <div className="row" style={{ marginBottom: 10 }}>
            <div className="col-xs-4 col-12 padding-bottom10">
              <input
                style={{ marginRight: 10 }}
                type="checkbox"
                id="toBrandSubscribers"
                checked={values.toBrandSubscribers}
                onChange={this.onCheckboxChange}
              />
              <label htmlFor="toBrandSubscribers">All brand opted-in ticket holders</label>
            </div>
            <div className="col-xs-4 col-12 padding-bottom10">
              <input
                style={{ marginRight: 10 }}
                type="checkbox"
                id="toAllBrandTeamMembers"
                checked={values.toAllBrandTeamMembers}
                onChange={this.onCheckboxChange}
              />
              <label htmlFor="toAllBrandTeamMembers">All brand team members</label>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">Body</div>
            <div className="col-xs-12">
              <RichTextArea
                ref={this.editorRef}
                id="body"
                disableEmbeds={true}
                baseurl={ENV.CDN_URL}
                value={values.body}
                error={errors.body}
                touched={touched.body}
                onChange={this.onBodyChange}
                onBlur={handleBlur}
              />
            </div>
          </div>
          <div className="row text-center">
            <Button
              className="btn btn-success btn-lg btn-shadow"
              type="button"
              onClick={this.setConfirmingModalState(true)}
            >
              {submitLabel || (
                <span>
                  <i className="fa fa-paper-plane" aria-hidden="true" /> Send
                </span>
              )}
            </Button>
          </div>
        </div>
        <Modal
          className="modal-dialog modal-trans"
          style={modalStyle}
          isOpen={!!emailTemplatesModalOpen}
          contentLabel="Modal"
          onRequestClose={this.setEmailTemplatesModalState(false)}
          closeTimeoutMS={150}
          ariaHideApp={false}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div>
                <div className="modal-header">
                  <p className="h4 text-compact">Select Email Template</p>
                </div>
                <div className="modal-body">
                  <div className="emailtemplates-table-container">
                    <div className="email-template-containter">
                      <div className="email-tempalate-title clearfix">
                        <span className="template-left-side">Details</span>
                        <span className="template-right-side mobile-template-action">Action</span>
                      </div>
                      <div className="email-template-content">
                        {emailTemplates &&
                          emailTemplates.map((template, index) => (
                            <div key={index} className="clearfix">
                              <div className="template-list">
                                <div className="template-left-side">
                                  <div>
                                    <b className="template-subtitle">Name:</b>
                                    {template.name}
                                  </div>
                                  <div>
                                    <b className="template-subtitle">Subject:</b>
                                    {template.subject}
                                  </div>
                                  <div>
                                    <b className="template-subtitle">Description:</b>
                                    {template.description}
                                  </div>
                                </div>
                                <div className="template-right-side clearfix">
                                  <span
                                    className="btn btn-primary btn-shadow select-template-btn"
                                    onClick={this.selectEmailTemplate.bind(this, template)}
                                  >
                                    Select
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <div className="btn-toolbar btn-toolbar-right">
                    <Button
                      className="btn btn-success btn-shadow"
                      type="button"
                      onClick={() => replace(`/brand/${brand.id}/email-templates`)}
                    >
                      Add New Template
                    </Button>
                    <Button
                      className="btn btn-default"
                      type="button"
                      onClick={this.setEmailTemplatesModalState(false)}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          className="modal-dialog modal-trans modal-confirming-emailing"
          style={modalStyle}
          isOpen={isConfirmingModalOpen}
          contentLabel="Modal"
          onRequestClose={this.setConfirmingModalState(false)}
          closeTimeoutMS={150}
          ariaHideApp={false}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">Email Content Review</div>
              <div className="modal-body">
                <h4>Subject: {values.subject}</h4>
                <p className="email-body" dangerouslySetInnerHTML={{ __html: values.body }} />
              </div>
              <div className="modal-footer">
                <div className="btn-toolbar btn-toolbar-right">
                  <Button
                    className="btn btn-success btn-shadow"
                    type="button"
                    disabled={isSubmitting}
                    onClick={this.handleSubmit}
                  >
                    Send Email
                  </Button>
                  <Button
                    className="btn btn-default btn-shadow"
                    type="button"
                    disabled={isSubmitting}
                    onClick={this.setConfirmingModalState(false)}
                  >
                    Cancel &amp; Edit
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </form>
    )
  }
}

export default withFormik({
  mapPropsToValues: props => ({
    subject: _get(props, 'initialValues.attributes.subject') || '',
    body: _get(props, 'initialValues.attributes.body') || '',
    toBrandSubscribers: _get(props, 'initialValues.attributes.toBrandSubscribers') || true,
    toAllBrandTeamMembers: _get(props, 'initialValues.attributes.toAllBrandTeamMembers') || false,
  }),

  // Custom sync validation
  validate: (values, props) => validateForm(values, props),

  handleSubmit: (values, { props, setSubmitting, resetForm, setFieldValue }) => {
    props
      .onSubmit({
        ...values
      })
      .then(v => {
        setSubmitting(false)
        resetForm()
        setFieldValue('subject', 'Message to attendees of ' + props.brand.displayName)
      })
      .catch(err => {
        setSubmitting(false)
      })
  },
  displayName: 'EmailToTicketHolders' // helps with React DevTools
})(EmailToTicketHolders)
