import React from 'react'
import _map from 'lodash/map'
import _forEach from 'lodash/forEach'
import _isObject from 'lodash/isObject'

import DataTable, { getNormalizedItem } from '../_library/DataTable'
import Clipboard from '../_library/Clipboard'

const getRow = row => Array.isArray(row) ? row : row.row

const tableWithCopy = (TableComponent = DataTable) => class extends React.Component {
  constructor(props) {
    super(props)
    const {
      thead,
      tbody,
      tfoot,
    } = props

    this.state = {
      ...this.getExtendedData(thead, tbody, tfoot)
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      thead,
      tbody,
      tfoot,
    } = nextProps
    if (
      thead !== this.props.thead ||
        tbody !== this.props.tbody ||
        tfoot !== this.props.tfoot
    ) {
      this.setState(() => this.getExtendedData(thead, tbody, tfoot))
    }
  }

    getClipboardText = (_thead, _tbody, _tfoot) => {
      let text = ''
      if (Array.isArray(_thead)) {
        const th_row = getRow(_thead)
        _forEach(th_row, (tableNode) => {
          const tableNodeValue = getNormalizedItem(tableNode)
          if (!_isObject(tableNodeValue)) {
            text += (tableNodeValue + '\t')
          }
        })

        text = text.trim()
        text += '\n\n'
      }

      if (Array.isArray(_tbody)) {
        _forEach(_tbody, (tb_row) => {
          tb_row = getRow(tb_row)
          _forEach(tb_row, (tableNode) => {
            const tableNodeValue = getNormalizedItem(tableNode)
            if (!_isObject(tableNodeValue)) {
              text += (tableNodeValue + '\t')
            }
          })
          text = text.trim()
          text += '\n'
        })
        text += '\n'
      }

      if (Array.isArray(_tfoot)) {
        const tf_row = getRow(_tfoot)
        if (Array.isArray(tf_row)) {
          _forEach(tf_row, (tableNode) => {
            const tableNodeValue = getNormalizedItem(tableNode)
            if (!_isObject(tableNodeValue)) {
              text += (tableNodeValue + '\t')
            }
          })
        }
      }

      return text.trim()
    }


    getExtendedData = (_thead, _tbody, _tfoot) => {
      let thead = _thead
      let tbody = _tbody
      let tfoot = _tfoot

      if (Array.isArray(_tbody) && _tbody.length) {
        if (Array.isArray(_thead)) {
          const copyBtn = <Clipboard
            text={this.getClipboardText(_thead, _tbody, _tfoot)}
          />
          thead = [..._thead, copyBtn]
        }
        tbody = _map(_tbody, (row) => {
          if (Array.isArray(row)) {
            return [...getRow(row), '']
          } 
          return { ...row, row: [...getRow(row), ''] }
          
        })

        if (Array.isArray(_tfoot)) {
          tfoot = [..._tfoot, '']
        }
      }
      return {
        thead,
        tbody,
        tfoot,
      }
    }


    render() {
      const {
        thead,
        tbody,
        tfoot,
      } = this.state

      const {
        classNames={},
      } = this.props

      return (
        <div>
          <TableComponent
            thead={thead}
            tbody={tbody}
            tfoot={tfoot}
            classNames={classNames}
          />
        </div>
      )
    }
}

export { tableWithCopy }
