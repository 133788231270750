import { ROLES } from '../../constants/roles'
import { getPermissions } from './permissions'
import _get from 'lodash/get'
import { visibleByConfig } from './visibleByConfig'

export const getEventEditMenuItems = ({
  permission,
  itemId,
  path,
  showEventCredentials,
  enableGiftCertificates,
  userTfRole,
  flagRequireEthereumWallet,
  configs,
}) => {
  const permissions = getPermissions(permission)
  const isTfSupport = ROLES.is_tf_support(userTfRole)
  const isAdminOrOwner = permissions.isAdminOrOwner()
  const isTtfTeam = ROLES.is_ttf_team(userTfRole)

  const isBudgetVisible = _get(configs, 'appearance.isBudgetVisible', true)
  const isCredentialsVisible = _get(configs, 'appearance.isCredentialsVisible', true)
  const isPreregistrationVisible = _get(configs, 'appearance.isPreregistrationVisible', true)
  const isTalentVisible = _get(configs, 'appearance.isTalentVisible', true)

  const isTfSupportOrTtfTeam = isTfSupport || isTtfTeam

  const childGroup_edit = []
  childGroup_edit.push({
    img: 'details',
    title: 'Details',
    href: `/event/${itemId}/details`,
    active: path === `/event/${itemId}/details`,
    visible: isAdminOrOwner || isTfSupport,
  })
  childGroup_edit.push({
    img: 'tickets',
    title: 'Tickets',
    href: `/event/${itemId}/tickets`,
    active: path === `/event/${itemId}/tickets`,
    visible: isAdminOrOwner || isTfSupport,
  })
  childGroup_edit.push({
    img: 'guest-tickets',
    title: 'Gift Certificates',
    href: `/event/${itemId}/gift-certificates-management`,
    active: path === `/event/${itemId}/gift-certificates-management`,
    visible: (isAdminOrOwner || isTfSupport) && enableGiftCertificates,
  })
  childGroup_edit.push({
    img: 'talent',
    title: 'Talent',
    href: `/event/${itemId}/talent`,
    active: path === `/event/${itemId}/talent`,
    visible: visibleByConfig(
      isTalentVisible,
      isTfSupportOrTtfTeam,
      false,
    ),
  })
  childGroup_edit.push({
    img: 'budget',
    title: 'Budget',
    href: `/event/${itemId}/budgets`,
    active: path === `/event/${itemId}/budgets`,
    visible: visibleByConfig(
      isBudgetVisible,
      isTfSupportOrTtfTeam,
      isAdminOrOwner,
    ),
  })
  childGroup_edit.push({
    img: 'team',
    title: 'Team',
    href: `/event/${itemId}/team`,
    active: path === `/event/${itemId}/team`,
    visible: isAdminOrOwner || isTfSupport,
  })
  childGroup_edit.push({
    img: 'credentials',
    title: 'Credentials',
    href: `/event/${itemId}/credentials`,
    active: path === `/event/${itemId}/credentials`,
    visible: visibleByConfig(
      isCredentialsVisible,
      isTfSupportOrTtfTeam,
      isAdminOrOwner && showEventCredentials,
    ),
  })
  childGroup_edit.push({
    img: 'pre-registration-ico',
    title: 'Pre-Registration',
    href: `/event/${itemId}/pre-registration`,
    active: path === `/event/${itemId}/pre-registration`,
    visible: visibleByConfig(
      isPreregistrationVisible,
      isTfSupportOrTtfTeam,
      isAdminOrOwner,
    ),
  })

  if (flagRequireEthereumWallet) {
    childGroup_edit.push({
      img: 'nft',
      title: 'NFTs',
      href: `/event/${itemId}/nft`,
      active: path === `/event/${itemId}/nft`,
      visible: isAdminOrOwner || isTfSupport,
    })
  }

  return childGroup_edit
}
