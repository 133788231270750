import React from 'react'
import _map from 'lodash/map'

import Card from '../../../../_library/Card'
import CustomToggle from '../../../../_library/CustomToggle'
import { ONLINE_EVENT_SETTINGS } from '../../../../constants/eventSettings'
import ReminderEmailForm from './ReminderEmailForm'
import ConfirmModal from '../../../../_library/ConfirmModal'

export default class OnlineEventSettings extends React.Component {
  state = { modalVisible: false }
  render() {
    const { values, cardsStatus, updateField } = this.props
    const { modalVisible } = this.state

    return (
      <>
        <ConfirmModal
          isOpen={modalVisible}
          header={'Confirm'}
          content={
            <div className="message-modal-content">
              <strong>Are you sure that you want to turn on this setting?</strong>
              <div>
                Only those who you send an invitation to using Interact {'>'} Invitations will be able to buy
                tickets.
              </div>
            </div>
          }
          actions={[
            { value: 'ok', label: 'Ok', className: 'btn btn-success btn-shadow' },
            { value: 'cancel', label: 'Cancel', className: 'btn btn-default' }
          ]}
          onAction={value => {
            if (value === 'ok') {
              updateField('flagInvitationsOnly', true)
            }
            this.setState({ modalVisible: false })
          }}
        />
        <Card
          icon={'fa-toggle-on'}
          title={'Event Settings'}
          status={cardsStatus && cardsStatus.settings}
          className="event-settings"
        >
          <div className="row">
            {
              <div className="col-xs-6 col-6 additional-info" style={{ position: 'static' }}>
                {_map(ONLINE_EVENT_SETTINGS, s => (
                  <div key={s.id}>
                    <CustomToggle
                      key={s.id}
                      id={s.id}
                      onText={s.label}
                      value={!!values[s.id]}
                      checked={!!values[s.id]}
                      onChange={(fieldKey, checked, e) => {
                        if (fieldKey === 'flagInvitationsOnly' && checked) {
                          this.setState({ modalVisible: true })
                        } else {
                          updateField(fieldKey, checked, e)
                        }
                      }}
                      subText={s.subLabel}
                    />
                    {s.id === 'flagReminderEmails' && values.flagReminderEmails && (
                      <ReminderEmailForm
                        values={values}
                        reminderEmail={values.reminderEmail}
                        flagReminderEmails={values.flagReminderEmails}
                        updateField={updateField}
                      />
                    )}
                  </div>
                ))}
              </div>
            }
          </div>
        </Card>
      </>
    )
  }
}
