import moment from 'moment'
import isEmail from 'validator/lib/isEmail'
import isLength from 'validator/lib/isLength'
import isURL from 'validator/lib/isURL'

// checked functions

export { isEmail }
export const isDateRangeValid = (start, end) => {
  const sm = moment.utc(start)
  const em = moment.utc(end)
  return !sm.isValid() || !em.isValid() || sm.isBefore(em)
}

export const isEndDateValid = end => isDateRangeValid(moment.utc(), end)

export const isValidTag = (str = '', tagName) => {
  if (!str) {
    return true
  }
  // todo: this function is not universal, need some improvements
  str = str.trim()
  const E = window.document.createElement('div')
  E.innerHTML = str
  const tag = E.querySelector(tagName)
  if (!tag || E.childNodes.length > 1) {
    return false
  }
  const tagInnerHTML = tag.innerHTML.trim()
  const hasSrc = tag.hasAttribute('src')
  const src = tag.getAttribute('src')

  if (hasSrc) {
    if (tagInnerHTML) {
      return false
    }
    return isURL(src || '')
  }
  if (tagName === 'script') {
    return !!tagInnerHTML
  }
  return false
}

// create checked functions

export const createIsMinChecked = min => value => value >= min
export const createIsMaxChecked = max => value => value <= max
export const minLengthChecked = min => value => isLength(value, { min })
export const maxLengthChecked = max => value => isLength(value, { max })

export const matchPassword = nValue => value => nValue === value

export const isValidInteger = (value, includeE = true) => {
  if (!includeE && String(value).includes('e')) {
    return false
  }
  const strValue = `${value}`
  const lastChar = strValue.slice(-1)
  const isLastCharDot = lastChar === '.'
  return isLastCharDot ? false : Number.isInteger(Number(strValue))
}
