import React from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'
import _isEqual from 'lodash/isEqual'
import { PreLoader } from '../_library/PreLoader'
import { getCookieByName } from '../utils/cookies'
import { FETCH_SESSION } from '../../_common/redux/auth/actions'

const cookieName = 'X-TF-ECOMMERCE'
const defaultOptions = {
  redirectUrl: '/'
}

const skipIfLoggedInHOC = (Component, options) => {
  const updatedOptions = {
    ...defaultOptions,
    ...options
  }

  @connect(
    state => ({
      user: state.auth.user,
      checked: state.auth.checked,
      isRnDomain: state.shared.isRnDomain,
      isMcDomain: state.shared.isMcDomain
    }),
    {
      FETCH_SESSION
    }
  )
  class C extends React.PureComponent {
    state = {
      content: null
    }

    componentDidMount() {
      this.check(this.props)
      this.handleCookieChange()
    }

    componentDidUpdate(prevProps, prevState) {
      const { exist } = this.state

      if (!_isEqual(prevProps, this.props)) {
        this.check(this.props)
      }

      if (prevState.exist !== exist) {
        this.checkSession(this.props)
      }
    }

    componentWillUnmount() {
      const { interval } = this.state
      interval && clearInterval(interval)
    }

    checkSession = async () => {
      const { FETCH_SESSION } = this.props

      await FETCH_SESSION({ hideError: true })
    }

    check = _props => {
      const { user, checked, isRnDomain, isMcDomain, ...props } = _props
      const {
        history: { replace },
        location: { search = '' }
      } = props
      const { next } = queryString.parse(search)
      const { redirectUrl } = updatedOptions
      let content = null

      if (!user && !checked) {
        content = <PreLoader isRnDomain={isRnDomain} isMcDomain={isMcDomain} />
      } else if (!user && checked) {
        if (Component) {
          content = <Component {...props} />
        } else {
          content = props.children || null
        }
      } else if (user && checked) {
        replace(next || redirectUrl)
        return
      }

      this.setState(() => ({ content }))
    }

    handleCookieChange = () => {
      const interval = setInterval(() => this.setState({ exist: !!getCookieByName(cookieName) }), 500)
      this.setState({ interval })
    }

    render() {
      const { content } = this.state
      return content
    }
  }

  return C
}

export { skipIfLoggedInHOC }
