import React from 'react'
import CountUp from './AnimationCount/component/CountUp'
import CountRoll from './AnimationCount/component/CountRoll'
import CountSlide from './AnimationCount/component/CountSlide'

export default React.memo((props) => {
  const setting = {
    isLoading: props.isLoading || false,
    start: props.initValue || 0,
    count: props.target || 0,
    duration: props.duration || 3000,
    decimals: props.decimals || 0,
    useGroup: props.useGroup || false,
    animation: props.animation || 'up',
    prefix: props.prefix || '',
    subfix: props.subfix || '',
    localeString: props.localeString || false,
    linear: props.linear || false,
  }
  let countDiv = null

  switch (setting.animation) {
  case 'up':
    countDiv = <CountUp {...setting} />
    break
  case 'roll':
    countDiv = <CountRoll {...setting} />
    break
  case 'slide':
    countDiv = <CountSlide {...setting} />
    break
  default:
    countDiv = null
    break
  }

  return props.isLoading ? (
    <i className="fa fa-circle-o-notch fa-spin loading_spinner" />
  ) : (
    <div className="count-animation-container">
      {countDiv}
    </div>
  )
})
