import _get from 'lodash/get'
import handleActions from 'redux-actions/lib/handleActions'
import { createAsyncHandlers } from '../actions'

const initialState = {
  invitations: {},
  members: {},
  promoterGenericInvitation: null
}

const REGENERATE_PROMOTER_GENERIC_INVITATION_TOUR = createAsyncHandlers('REGENERATE_PROMOTER_GENERIC_INVITATION_TOUR', {
  success(state, action) {
    state.promoterGenericInvitation = {...state.promoterGenericInvitation, token: action.payload}
  }
})

const FETCH_TOUR_TEAM_INVITATIONS = createAsyncHandlers('FETCH_TOUR_TEAM_INVITATIONS', {
  success(state, action) {    
    const { data } = action.payload
    return state.invitations = _get(data, '$original')
  }
})


const FETCH_TOUR_TEAM_MEMBERS = createAsyncHandlers('FETCH_TOUR_TEAM_MEMBERS', {
  success(state, action) {    
    const { data } = action.payload
    return state.members = data.map((d) => _get(d, '$original'))
  }
})

const FETCH_TOUR_PROMOTER_GENERIC_INVITATION = createAsyncHandlers('FETCH_TOUR_PROMOTER_GENERIC_INVITATION', {
  success(state, action) {
    state.promoterGenericInvitation = action.payload
  }
})

const UPDATE_TOUR_PROMOTER_GENERIC_INVITATION = createAsyncHandlers('UPDATE_TOUR_PROMOTER_GENERIC_INVITATION', {
  success(state, action) {
    const {data} = action.payload
    state.promoterGenericInvitation = {...state.promoterGenericInvitation, status: data['$original'].attributes.status}
  }
})

export default handleActions({
  ...FETCH_TOUR_TEAM_INVITATIONS,
  ...FETCH_TOUR_TEAM_MEMBERS,
  ...FETCH_TOUR_PROMOTER_GENERIC_INVITATION,
  ...REGENERATE_PROMOTER_GENERIC_INVITATION_TOUR,
  ...UPDATE_TOUR_PROMOTER_GENERIC_INVITATION
}, initialState)
