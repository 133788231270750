import url from 'url'
import createAction from 'redux-actions/lib/createAction'
import { createAsyncAction } from '../actions'
import { fetchAPI } from '../../core/http'
import { ERROR } from '../notification/actions'

const { FETCH_TOURS } = createAsyncAction('FETCH_TOURS', function() {
  return (dispatch) => fetchAPI('/api/tours', null)
    .catch((err) => {
      dispatch(ERROR(...err.errors))
      throw err
    })
    .then((res) => {
      dispatch(this.success(res))
      return res
    })
})

const { CREATE_TOUR } = createAsyncAction('CREATE_TOUR', function(form) {
  return (dispatch) => fetchAPI('/api/tour', {
    method: 'POST',
    body: JSON.stringify({ data: { ...form, type: 'tour' } })
  })
    .catch((err) => {
      dispatch(ERROR(...err.errors))
      throw err
    })
    .then((res) => {
      dispatch(this.success(res))
      return res
    })
})

const { FETCH_TOUR } = createAsyncAction('FETCH_TOUR', function(id, query) {
  return (dispatch) => fetchAPI(url.format({ pathname: `/api/tour/${id}`, query }), null)
    .catch((err) => {
      dispatch(ERROR(...err.errors))
      throw err
    })
    .then((res) => {
      dispatch(this.success(res))
      return res
    })
})

const { UPDATE_TOUR } = createAsyncAction('UPDATE_TOUR', function(id, form) {
  const body = {
    data: {
      ...form,
      id,
      type: 'tour',
    }
  }
  return (dispatch) => fetchAPI(`/api/tour/${id}`, {
    method: 'PUT',
    body: JSON.stringify(body)
  })
    .catch((err) => {
      dispatch(ERROR(...err.errors))
      throw err
    })
    .then((res) => {
      dispatch(this.success(res))
      return res
    })
})

const CLEAR_SELECTED_TOUR = createAction('CLEAR_SELECTED_TOUR')

export {
  FETCH_TOURS,
  CREATE_TOUR,
  FETCH_TOUR,
  UPDATE_TOUR,
  CLEAR_SELECTED_TOUR,
}

