import React from 'react'
import _clone from 'lodash/clone'
import _includes from 'lodash/includes'
import _filter from 'lodash/filter'
import _map from 'lodash/map'

import SortableTable from '../_library/SortableTable'
import Chip from '../_library/Chip'

const SortableTableWithChipFiltersHOC = (TableComponent = SortableTable) => class extends React.Component {
    onSelect = selectedChip => {
      const { isMultiSelectEnabled, onFilter, selectedChips } = this.props

      let chips = _clone(selectedChips)

      if (isMultiSelectEnabled) {
        if (_includes(chips, selectedChip)) {
          chips = _filter(selectedChips, item => item !== selectedChip)
        } else {
          chips.push(selectedChip)
        }
      } else {
        chips = [selectedChip]
      }

      onFilter(chips)
    }

    render() {
      const { chipOptions, customHead = null, selectedChips } = this.props

      return (
        <div>
          <div className="div-spacing-10" />
          <div style={{ marginBottom: 8 }}>
            {_map(chipOptions, (item, i) => (
              <Chip
                key={item}
                value={item}
                style={{ width: '100%', marginLeft: i ? 10 : 0 }}
                isSelected={_includes(selectedChips, item)}
                disableClear={true}
                onSelectChip={this.onSelect}
              />
            ))}
          </div>
          <div className="div-spacing-20" />
          {customHead}
          <SortableTable {...this.props} />
        </div>
      )
    }
}

export default SortableTableWithChipFiltersHOC
