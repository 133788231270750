import React, { Component } from 'react'
import { connect } from 'react-redux'
import _get from 'lodash/get'
import PermissionDenied from '../_library/PermissionDeniedComponent'
import UsersListItem from './UsersListItem'
import LoadingBar from '../_library/LoadingBar'
import Button from '../_library/Button'
import { FETCH_USERS, UPDATE_USER_TYPE } from '../../_common/redux/users/actions'
import { RN_ROLES } from '../constants/roles'

import {
  get_users,
} from '../../_common/core/selectors'
import { getTitle } from '../utils/getTitle'

@connect(
  state => {
    const rnRoles = _get(state.auth, 'user.rnRoles', [])
    const hasAccessRnBrand = RN_ROLES.hasRoles(rnRoles, [RN_ROLES.ADMIN])
    return {
      loading: state.loading.has('FETCH_USERS'),
      usersChecked: state.users.usersData.checked,
      hasAccessRnBrand,
      data: get_users(state),
    }
  },
  {
    FETCH_USERS,
    UPDATE_USER_TYPE
  }
)
class Users extends Component {
  constructor(props) {
    super(props)
    this.handleCuratorUpdate = this.handleCuratorUpdate.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.clearSearch = this.clearSearch.bind(this)
    this.timeOut = null
    this.state = {
      searchValue: '',
      searched: false
    }
    const configDocTitle = _get(this.props.configs, 'messages.documentTitle', '')
    document.title = getTitle(configDocTitle)
  }
  componentDidMount() {
    const { hasAccessRnBrand = false, FETCH_USERS } = this.props
    if (hasAccessRnBrand) {
      FETCH_USERS({ customer_type: 'curator' })
    }
  }
  renderUserList() {
    const { data = [] } = this.props
    return data.map(element => {
      const item = element.$original
      const { rnRoles=[], id } = item
      const buttonClass = rnRoles.includes('curator') ? 'btn-danger': 'btn-ok'
      const buttonText = rnRoles.includes('curator') ? 'Remove curator permission': 'Grant curator permission'

      return (
        <UsersListItem
          item={item}
          buttonClass={buttonClass}
          key={id}
          buttonText={buttonText}
          handleClick={this.handleCuratorUpdate}
        />
      )
    })
  }
  handleCuratorUpdate(id, attrs) {
    const { UPDATE_USER_TYPE, data } = this.props
    const { searched } = this.state
    const params = {
      attributes: {
        ...attrs
      }
    }
    UPDATE_USER_TYPE(id, params, data, searched)
  }
  handleInputChange(e) {
    const { value } = e.target
    const { FETCH_USERS } = this.props
    const { searched } = this.state
    this.setState({ searchValue: value })
    clearTimeout(this.timeOut)
    if (value.length > 2) {
      this.timeOut = setTimeout(() => {
        this.setState({ searched: true })
        FETCH_USERS({ query_string: value })
      }, 400)
    } else {
      if (searched) {
        this.timeOut = setTimeout(() => {
          this.setState({ searched: false })
          FETCH_USERS({ customer_type: 'curator' })
        }, 400)
      }
    }
  }

  clearSearch() {
    const { searched } = this.state
    const { FETCH_USERS } = this.props
    if (searched) {
      this.setState({ searched: false, searchValue: '' })
      clearTimeout(this.timeOut)
      FETCH_USERS({ customer_type: 'curator' })
    } else {
      this.setState({ searchValue: '' })
    }
  }
  render() {
    const { loading, usersChecked, hasAccessRnBrand = false, data } = this.props
    const { searchValue } = this.state
    return (
      <div className="body-main">
        <div className="body-panel-header">
          <div className="left">
            <div className="title">Users</div>
          </div>
        </div>
        <div className="users_page">
          <div>
            <div className="users_page_search" style={{ paddingBottom: 1 }}>
              <div className="searchbar find_order">
                <div className="tags-field input-wrapper">
                  <input
                    onChange={this.handleInputChange}
                    value={searchValue}
                    className="react-tagsinput table-search-no-focus"
                  />
                </div>
                {searchValue.length ? (
                  <Button className="search-btn" onClick={this.clearSearch}>
                    <img src={asset('/resources/images/cross-ico.svg')} alt="" title="" />
                  </Button>
                ) : (
                  <span className="search-btn">
                    <img src={asset('/resources/images/search-ico.svg')} alt="" title="" />
                  </span>
                )}
              </div>
            </div>
            <div className="users_page_search" style={{ margin: '0 20px' }}>
              Search users by name, username or email
            </div>
          </div>
          {hasAccessRnBrand ? (
            <div className="users_list_section">
              <div className="users_list_title_block">User Details</div>
              {loading && <LoadingBar />}
              {!loading &&
                (usersChecked && !!data.length ? (
                  <div className="users_list_block">{this.renderUserList()}</div>
                ) : searchValue ? (
                  <div className="no_data_found">{`Sorry, we couldn't find a match for ${searchValue}`}</div>
                ) : (
                  <div className="no_data_found">{`Please fill the search field`}</div>
                ))}
            </div>
          ) : (
            <PermissionDenied subHeader={`You don't have access to this page`} />
          )}
        </div>
      </div>
    )
  }
}

export default Users
