import React from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

const RedirectToContainer = ({ 
  hasTeamInvitationFlag,
  hasPayoutModeFlag,
  hasBrandsFlag,
  hasPayoutMode,
  hasInvitations,
  hasBrands,
  to,
  component: Component,
  ...props
}) => {
  if (
    (hasBrands && hasBrandsFlag) || 
    (hasInvitations && hasTeamInvitationFlag) ||
    (hasPayoutMode && hasPayoutModeFlag)
  ) {
    return <Redirect push to={to} />
  }
  return (
    <Component {...props} />
  )
}

export const RedirectTo = connect(state => {
  const { hasBrandsFlag, hasPayoutModeFlag } = state.brands
  const { hasTeamInvitationFlag } = state.brandteam
  return {
    hasPayoutModeFlag,
    hasTeamInvitationFlag,
    hasBrandsFlag
  }
}, null)(RedirectToContainer)