// Deprecated -- use CustomToggle.jsx
import React from 'react'

export default React.memo((props) => {
  const { id, title, value, onChange } = props

  return (
    <div className="ctlToggle">
      <div className="line-cell">
        <div className="checkbox-switch">
          <input type="checkbox" id={id} checked={value} onChange={(evt)=> onChange(evt.target.checked)}/>
          <label htmlFor={id} />
        </div>
      </div>
      <div className="line-cell">
        <label htmlFor={id}>{title}</label>
      </div>
    </div>
  )
})
