import handleActions from 'redux-actions/lib/handleActions'
import { createAsyncHandlers } from '../actions'

const initialState = {
  event: {
    allProducts: [],
    addedIds: [],
    isFetch: false,
    shopifyCurrentId: '',
    connectChecked: false,
    connected: false,
  },
  brand: {
    allProducts: [],
    addedIds: [],
    isFetch: false,
    shopifyCurrentId: '',
    connectChecked: false,
    connected: false,
  },
  tour: {
    allProducts: [],
    addedIds: [],
    isFetch: false,
    shopifyCurrentId: '',
    connectChecked: false,
    connected: false,
  }
}

const SHOPIFY_CHECK_CONNECT = createAsyncHandlers('SHOPIFY_CHECK_CONNECT', {
  success(state, action) {
    const { id, shopifyType } = action.payload
    state[shopifyType].shopifyCurrentId = id
    state[shopifyType].allProducts = []
    state[shopifyType].addedIds = []
    state[shopifyType].connected = true
    state[shopifyType].connectChecked = true
  },
  failed(state, action) {
    const { id, shopifyType } = action.payload
    state[shopifyType].shopifyCurrentId = id
    state[shopifyType].allProducts = []
    state[shopifyType].addedIds = []
    state[shopifyType].connected = false
    state[shopifyType].connectChecked = true
  }
})

const SHOPIFY_SET_CONNECT_INFO = createAsyncHandlers('SHOPIFY_SET_CONNECT_INFO', {
  success(state, action) {
    const { id, shopifyType } = action.payload

    state[shopifyType].shopifyCurrentId = id
    state[shopifyType].allProducts = []
    state[shopifyType].addedIds = []
    state[shopifyType].connected = true
    state[shopifyType].connectChecked = true
  }
})

const SHOPIFY_DISCONNECT = createAsyncHandlers('SHOPIFY_DISCONNECT', {
  success(state, action) {
    const { id, shopifyType } = action.payload

    state[shopifyType].shopifyCurrentId = id
    state[shopifyType].allProducts = []
    state[shopifyType].addedIds = []
    state[shopifyType].isFetch = false
    state[shopifyType].connected = false
    state[shopifyType].connectChecked = true
  }
})

const FETCH_PRODUCTS = createAsyncHandlers('FETCH_PRODUCTS', {
  success(state, action) {
    const { shopifyType, id, addedIds, allProducts } = action.payload

    state[shopifyType].shopifyCurrentId = id
    state[shopifyType].allProducts = allProducts
    state[shopifyType].addedIds = addedIds
    state[shopifyType].isFetch = true
  }
})

const SHOPIFY_UPDATE = createAsyncHandlers('SHOPIFY_UPDATE', {
  success(state, action) {
    const { shopifyType, id, newAddedIds } = action.payload
    state[shopifyType].shopifyCurrentId = id
    state[shopifyType].addedIds = newAddedIds
  }
})

export default handleActions({
  ...SHOPIFY_CHECK_CONNECT,
  ...FETCH_PRODUCTS,
  ...SHOPIFY_SET_CONNECT_INFO,
  ...SHOPIFY_DISCONNECT,
  ...SHOPIFY_UPDATE,
}, initialState)