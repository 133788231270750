import React from 'react'
import Button from '../Button'
import _map from 'lodash/map'
import { shortid } from '../../../_common/core/rand'

const DnDTableActions = props => {
  const {
    isDndHistoryEnabled,
    isSavingSort,
    handleUndo,
    handleRedo,
    handleSaveSort,
    mainActions,
    disableUndo,
    disableRedo,
    showSave,
    hideUndoBtn,
    hideRedoBtn
  } = props

  return (
    <div className="btn-toolbar btn_holder">
      {mainActions &&
        _map(mainActions, item =>
          item.hideBtn ? null : (
            <Button
              key={shortid()}
              className={item.className}
              type="button"
              onClick={item.onClick}
              loading={item.loading}
            >
              {item.icon && <i className={item.icon} />}
              {item.label}
            </Button>
          )
        )}
      {isDndHistoryEnabled && (
        <>
          {!hideUndoBtn && (
            <Button className="btn btn-seablue" type="button" onClick={handleUndo} disabled={disableUndo}>
              <i className={'fa fa-fw fa-undo'} /> Undo
            </Button>
          )}
          {showSave && (
            <Button
              className="btn btn-seablue"
              type="button"
              onClick={handleSaveSort}
              loading={isSavingSort}
              disabled={disableUndo}
            >
              <i className={'fa fa-fw fa-floppy-o'} /> Save
            </Button>
          )}
          {!hideRedoBtn && (
            <Button className="btn btn-primary" type="button" onClick={handleRedo} disabled={disableRedo}>
              <i className={'fa fa-fw fa-repeat'} /> Redo
            </Button>
          )}
        </>
      )}
    </div>
  )
}

export default DnDTableActions