import { createAsyncAction } from '../actions'
import { fetchAPI } from '../../core/http'
import { ERROR } from '../notification/actions'

const { FETCH_SECTIONS } = createAsyncAction('FETCH_SECTIONS', function(eid) {
  return (dispatch) => fetchAPI(`/api/event/${eid}/budget-section`, null)
    .catch((err) => {
      dispatch(ERROR(...err.errors))
      dispatch(this.failed(err))
      throw err
    })
    .then((res) => {
      const out = { eid: eid.toString(), sections: res }
      dispatch(this.success(out))
      return res
    })
})

const { CREATE_SECTION } = createAsyncAction('CREATE_SECTION', function(eid, section) {
  const body = {
    data: {
      type: 'budget_section',
      attributes: {
        name: section.name,
        description: section.description,
        parentId: section.parentId,
        ownerId: section.ownerId,
        completedAt: section.completedAt,
      },
      relationships: [],
    }
  }
  return (dispatch) => fetchAPI(`/api/event/${eid}/budget-section`, {
    method: 'POST',
    body: JSON.stringify(body)
  })
    .catch((err) => {
      dispatch(ERROR(...err.errors))
      dispatch(this.failed(err))
      throw err
    })
    .then((res) => {
      dispatch(this.success(res))
      return res
    })
})

const { UPDATE_SECTION } = createAsyncAction('UPDATE_SECTION', function(section) {
  const body = {
    data: {
      type: 'budget_section',
      attributes: {
        name: section.name,
        // description: section.description,
        // ownerId: section.ownerId,
        // completedAt: section.completedAt,
      },
      relationships: [],
    }
  }
  return (dispatch) => fetchAPI(`/api/event/budget-section/${section.id}/`, {
    method: 'PUT',
    body: JSON.stringify(body)
  })
    .catch((err) => {
      dispatch(ERROR(...err.errors))
      dispatch(this.failed(err))
      throw err
    })
    .then((res) => {
      dispatch(this.success(res))
      return res
    })
})

const { DELETE_SECTION } = createAsyncAction('DELETE_SECTION', function(sectionId, eid) {
  return (dispatch) => fetchAPI(`/api/event/budget-section/${sectionId}`, {
    method: 'DELETE'
  })
    .catch((err) => {
      dispatch(ERROR(...err.errors))
      dispatch(this.failed(err))
      throw err
    })
    .then((res) => {
      const out = { sid: sectionId, eid }
      dispatch(this.success(out))
      return res
    })
})

const { CREATE_ITEM } = createAsyncAction('CREATE_ITEM', function(eid, sectionId, item) {
  const body = {
    data: {
      type: 'budget_section_item',
      attributes: {
        name: item.name,
        cost: item.cost,
        category: item.category,
        type: item.type,
        responsibleUsers: item.responsibleUsers === '' ? null : [item.responsibleUsers],
        // description: item.description,
        // completedAt: item.completedAt,
      },
      relationships: [],
    }
  }
  return (dispatch) => fetchAPI(`/api/event/budget-section/${sectionId}/item`, {
    method: 'POST',
    body: JSON.stringify(body)
  })
    .catch((err) => {
      dispatch(ERROR(...err.errors))
      dispatch(this.failed(err))
      throw err
    })
    .then((res) => {
      res.eid = eid
      dispatch(this.success(res))
      return res
    })
})

const { UPDATE_ITEM } = createAsyncAction('UPDATE_ITEM', function(eid, id, item) {
  const body = {
    data: {
      type: 'budget_section_item',
      attributes: {
        name: item.name,
        cost: item.cost,
        category: item.category,
        type: item.type,
        responsibleUsers: !item.responsibleUsers ? null : [item.responsibleUsers],
        // description: item.description,
        // completedAt: item.completedAt,
      },
      relationships: [],
    }
  }
  return (dispatch) => fetchAPI(`/api/event/budget-section/item/${id}`, {
    method: 'PUT',
    body: JSON.stringify(body)
  })
    .catch((err) => {
      dispatch(ERROR(...err.errors))
      dispatch(this.failed(err))
      throw err
    })
    .then((res) => {
      res.eid = eid
      dispatch(this.success(res))
      return res
    })
})

const { DELETE_ITEM } = createAsyncAction('DELETE_ITEM', function(eid, itemId, sectionId) {
  return (dispatch) => fetchAPI(`/api/event/budget-section/item/${itemId}`, {
    method: 'DELETE'
  })
    .catch((err) => {
      dispatch(ERROR(...err.errors))
      dispatch(this.failed(err))
      throw err
    })
    .then((res) => {
      const out = { id: itemId, eid, sid: sectionId }
      dispatch(this.success(out))
      return res
    })
})

export {
  FETCH_SECTIONS,
  CREATE_SECTION,
  UPDATE_SECTION,
  DELETE_SECTION,
  CREATE_ITEM,
  UPDATE_ITEM,
  DELETE_ITEM,
}