import _get from 'lodash/get'
import handleActions from 'redux-actions/lib/handleActions'
import { createAsyncHandlers } from '../actions'

const initialState = {
  event: {},
  brand: {}
}

const FETCH_AUDIENCE = createAsyncHandlers('FETCH_AUDIENCE', {
  success(state, action) {
    const { eid, audience: { data }, type, section } = action.payload
    state[type][section] = _get(data, '$original')
  }
})

export default handleActions({
  ...FETCH_AUDIENCE,
}, initialState)

