import { createAsyncAction } from '../actions'
import { fetchAPI } from '../../core/http'
import { ERROR } from '../notification/actions'
import { logOutZoomUser } from '../../core/http_services'

const { GET_ZOOM_AUTH_URL } = createAsyncAction('GET_ZOOM_AUTH_URL', function () {
  return dispatch => fetchAPI('/api/zoom/auth', {
    params: {
      redirectUrl: `${location.origin}/response-page?state=accountAttached`
    }
  })
    .catch(err => {
      dispatch(ERROR(...err.errors))
      dispatch(this.failed(err))
    })
    .then(res => {
      dispatch(this.success(res))
      return res
    })
})

const { GET_ZOOM_MEETINGS } = createAsyncAction('GET_ZOOM_MEETINGS', function (pageNumber, pageSize) {
  return dispatch => fetchAPI('/api/zoom/meetings', {
    params: {
      page: pageNumber,
      limit: pageSize
    }
  })
    .catch(err => {
      dispatch(ERROR(err))
      dispatch(this.failed(err))
    })
    .then(res => {
      dispatch(this.success(res))
      return res
    })
})

const { GET_ZOOM_MEETING } = createAsyncAction('GET_ZOOM_MEETING', function (id) {
  return dispatch => fetchAPI(`/api/zoom/meeting`, {
    params: {
      id
    }
  })
    .catch(err => {
      dispatch(ERROR(...err.errors))
      dispatch(this.failed(err))
    })
    .then(res => {
      dispatch(this.success(res))
      return res
    })
})

const { GET_ZOOM_USER_DATA } = createAsyncAction('GET_ZOOM_USER_DATA', function () {
  return dispatch => fetchAPI(`/api/zoom/identity`)
    .catch(err => {
      dispatch(ERROR(...err.errors))
      dispatch(this.failed(err))
    })
    .then(res => {
      dispatch(this.success(res))
      return res
    })
})

const { LOG_OUT_ZOOM_USER } = createAsyncAction('LOG_OUT_ZOOM_USER', function () {
  return async (dispatch) => {
    try {
      const res = await logOutZoomUser()
      dispatch(this.success())
      return res
    } catch(err) {
      dispatch(ERROR([err]))
      dispatch(this.failed(err))
    }
  }
})

export {
  GET_ZOOM_AUTH_URL,
  GET_ZOOM_MEETINGS,
  GET_ZOOM_MEETING,
  GET_ZOOM_USER_DATA,
  LOG_OUT_ZOOM_USER
}
