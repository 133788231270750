import React, { useState } from 'react'
import _ from 'lodash'

import Card from '../../../_library/Card'
import CustomToggle from '../../../_library/CustomToggle'
import { EVENT_SETTINGS } from '../../../constants/eventSettings'
import Slider from '../../../_library/Slider'
import Select from '../../../_library/Select'
import ConfirmModal from '../../../_library/ConfirmModal'

const accessOptions = [
  { label: 'Select...', value: '' },
  { label: 'Access Events', value: 'Access Passes' },
  { label: 'Access Reserve', value: 'Bonus Passes' },
]

const ageOptions = [
  { label: 'All Ages', value: '' },
  { label: '18+', value: 18 },
  { label: '21+', value: 21 },
]

const EventSettings = props => {
  const { values, cardsStatus, isRnDomain, user, updateField, configs } = props
  const isCurator = user.hasAccessRnBrand || (user.rnRoles || []).includes('curator')
  const [modalVisible, setModalVisible] = useState(false)
  const showRequireNTFs = _.get(configs, "appearance.showRequireNTFs", true)
  const showRefundsAllowed = _.get(configs, "appearance.showRefundsAllowed", true)
  const showFreeCarerTicketForDisabledGuests = _.get(configs, "appearance.showFreeCarerTicketForDisabledGuests", true)
  
  return (
    <Card
      icon={'fa-toggle-on'}
      title={'Event Settings'}
      status={cardsStatus && cardsStatus.settings}
      className="event-settings"
    >
      <ConfirmModal
        isOpen={modalVisible}
        header={'Confirm'}
        content={
          <div className="message-modal-content">
            <strong>Are you sure that you want to turn on this setting?</strong>
            <div>
              Only those who you send an invitation to using Interact {'>'} Invitations will be able to buy
              tickets.
            </div>
          </div>
        }
        actions={[
          { value: 'ok', label: 'Ok', className: 'btn btn-success btn-shadow' },
          { value: 'cancel', label: 'Cancel', className: 'btn btn-default' },
        ]}
        onAction={value => {
          if (value === 'ok') {
            updateField('flagInvitationsOnly', true)
          }
          setModalVisible(false)
        }}
      />
      <div className="row">
        {!isRnDomain && (
          <div className="col-xs-6 col-6 additional-info">
            {!isRnDomain &&
              _.map(EVENT_SETTINGS.filter(setting => !(
                (!showRequireNTFs && setting.id === "flagNftOwnershipRequired") ||
                (!showRefundsAllowed && setting.id === "flagRefunds") ||
                (!showFreeCarerTicketForDisabledGuests && setting.id === "flagCarer")
              )), s => (
                <CustomToggle
                  key={s.id}
                  id={s.id}
                  onText={s.label}
                  value={!!values[s.id]}
                  checked={!!values[s.id]}
                  onChange={(fieldKey, checked, e) => {
                    if (fieldKey === 'flagInvitationsOnly' && checked) {
                      setModalVisible(true)
                    } else {
                      updateField(fieldKey, checked, e)
                    }
                  }}
                  subText={s.subLabel}
                />
              ))}
          </div>
        )}
        {isRnDomain && (
          <div className="col-xs-6 col-6 additional-info">
            <div>
              {isCurator && (
                <Slider
                  id="eventGrade"
                  value={values.eventGrade}
                  onChange={e => updateField('eventGrade', e)}
                />
              )}
              <div className="col-xs-12 col-12" style={{ padding: '10px 0', marginTop: isCurator ? 0 : 15 }}>
                <CustomToggle
                  id={'noneTicketingEvent'}
                  onText={'Non Ticketing Event'}
                  value={!!values.noneTicketingEvent}
                  checked={!!values.noneTicketingEvent}
                  onChange={updateField}
                />
              </div>
              <div className="col-xs-12 col-12" style={{ padding: '10px 0' }}>
                <CustomToggle
                  id={'flagPrivate'}
                  onText={'Set Event To Private'}
                  value={!!values.flagPrivate}
                  checked={!!values.flagPrivate}
                  onChange={updateField}
                />
              </div>
              <div className="col-xs-12 col-12" style={{ padding: '10px 0' }}>
                <CustomToggle
                  id={'flagRnRsvp'}
                  onText={'Enable RSVP'}
                  value={!!values.flagRnRsvp}
                  checked={!!values.flagRnRsvp}
                  onChange={updateField}
                />
              </div>
            </div>
            <div>
              {isCurator && (
                <Select
                  fieldKey="accessSubGroup"
                  label="Access Page Sub Grouping"
                  style={{ margin: 0 }}
                  fieldGroupStyle={{ marginTop: 15, padding: 15, paddingBottom: 0 }}
                  value={values.accessSubGroup}
                  onChange={e => updateField('accessSubGroup', e.target.value)}
                  options={accessOptions}
                />
              )}
              <Select
                fieldKey="minimumAge"
                label="Age Restriction"
                style={{ margin: 0 }}
                fieldGroupStyle={{ marginTop: isCurator ? 0 : 8, padding: 15, paddingBottom: 0 }}
                value={Number(values.minimumAge) || ''}
                onChange={e => updateField('minimumAge', e.target.value)}
                options={ageOptions}
              />
            </div>
          </div>
        )}
      </div>
    </Card>
  )
}

export default EventSettings
