import React from 'react'
import { withFormik } from 'formik'

import Button from '../_library/Button'
import Field from '../_library/Field'
import Select from '../_library/Select'
import Card from '../_library/Card'

const providers = [
  { value: 'stripe', label: 'Stripe' },
  { value: 'spreedly', label: 'Spreedly' }
]

class MyForm extends React.Component {
  render() {
    const {
      submitLabel, onCancel
    } = this.props
    const {
      values,
      touched,
      errors,
      isSubmitting,
      handleChange,
      handleBlur,
      handleSubmit,
      handleReset,
      dirty,
    } = this.props
    return (
      <form onSubmit={handleSubmit}>
        <Card title={' '}>
          <Select
            id="type"
            label="Payment method provider"
            options={providers}
            value={values.type}
            error={errors.type}
            touched={touched.type}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Field
            id="displayName"
            label="Name"
            type="text"
            value={values.displayName}
            error={errors.displayName}
            touched={touched.displayName}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Field
            id="key"
            label="Key"
            type="text"
            value={values.key}
            error={errors.key}
            touched={touched.key}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Card>
        <div className="card-block">
          <Button className="btn btn-primary" type="submit" loading={isSubmitting}>{submitLabel || 'Submit'}</Button>
          {onCancel && <Button className="btn btn-link" type="button" loading={isSubmitting} onClick={onCancel}>Cancel</Button>}
        </div>
      </form>
    )
  }
}

const PaymentMethodForm = withFormik({
  mapPropsToValues: (props) => ({
    type: '',
    displayName: '',
    key: '',
  }),

  // Custom sync validation
  validate: values => ({}),

  handleSubmit: (values, { props, setSubmitting }) => {
    props.onSubmit({
      attributes: values,
    })
      .then((v) => {
        setSubmitting(false)
      })
      .catch((err) => {
        setSubmitting(false)
      })
  },
  displayName: 'PaymentMethodForm', // helps with React DevTools
})(MyForm)

export default PaymentMethodForm
