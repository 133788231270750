import React from 'react'
import { withRouter } from 'react-router-dom'
import _map from 'lodash/map'
import MenuItem from 'react-bootstrap/lib/MenuItem'
import DropdownButton from 'react-bootstrap/lib/DropdownButton'

const DropDownButton = (props) => {
  const { label = '', options = [] } = props

  const handleClick = (e, href) => {
    const { history: { push } } = props
    e.preventDefault()
    push(href)
  }

  return (
    <div className="col-xs-12">
      <DropdownButton
        title={
          <span>
            <i className={`fa fa-plus`} />
            {label}
          </span>
        }
        id="dropdown-basic"
        className="btn btn-success"
        noCaret={true}
      >
        {_map(options, item => (
          <MenuItem
            key={item.id ||item.value}
            eventKey={item.value}
            onClick={(e) => handleClick(e, item.value)}
            className="context-menu-item"
          >
            {item.label}
          </MenuItem>
        ))}
      </DropdownButton>
    </div>
  )
}

export default withRouter(DropDownButton)
