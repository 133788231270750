import React from 'react'
import _identity from 'lodash/identity'
import _get from 'lodash/get'
import ReactSelect from '../../_library/ReactSelect'

const ReactSelectField = ({
  field,
  form: { touched, errors, setFieldValue, setFieldTouched },
  touchedOnBlur = false,
  normalizer = _identity,
  showErrorOnlyTouched = true,
  ...props
}) => {
  const onChange = value => {
    // value is array
    setFieldValue(field.name, normalizer(value))
  }

  const onBlur = value => {
    if (value && value.length) {
      setFieldValue(field.name, normalizer(value))
      touchedOnBlur && setFieldTouched(field.name, normalizer(value))
    }
  }

  return (
    <div>
      <ReactSelect
        {...field}
        {...props}
        touched={!showErrorOnlyTouched || _get(touched, field.name)}
        error={_get(errors, field.name)}
        onChange={onChange}
        onBlur={onBlur}
      />
    </div>
  )
}

export { ReactSelectField }