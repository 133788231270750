import _findIndex from 'lodash/findIndex'
import moment from 'moment'

// Find index of Date object in Date objects array( Date: { startDate: FORMAT string, endDate: FORMAT string } )
export const FORMAT = 'D MMM YYYY'
export const findIndexInDateArray = (baseArray, dateObj) => _findIndex(
  baseArray,
  date =>
    moment(date.startDate).format(FORMAT) ===
        moment(dateObj.startDate).format(FORMAT) &&
      moment(date.endDate).format(FORMAT) ===
        moment(dateObj.endDate).format(FORMAT)
)
