import { createAsyncAction } from '../actions'
import { fetchAPI } from '../../core/http'
import { ERROR } from '../notification/actions'
import { adaptUserTypeChange } from './utils'

const { FETCH_USERS } = createAsyncAction('FETCH_USERS', function(params) {
  return (dispatch) => fetchAPI(`/api/customers`, {
    params: {
      ...params
    }
  })
    .catch((err) => {
      dispatch(ERROR(...err.errors))
      dispatch(this.failed(err))
      throw err
    })
    .then((res) => {
      const { data } = res //_.get(res.data, '$original')
      //console.log(ret, 'ret')
      //console.log(res.data, _.get(res.data, 'data'))
      dispatch(this.success({ data }))
      return res
    })
})

const { UPDATE_USER_TYPE } = createAsyncAction('UPDATE_USER_TYPE', function(id, data, allData, isActionDelete) {
  return (dispatch) => fetchAPI(`/api/customers/${id}/`, {
    method: 'PATCH',
    body: JSON.stringify({ data })
  })
    .catch((err) => {
      dispatch(ERROR(...err.errors))
      dispatch(this.failed(err))
      
      throw err
    })
    .then((res) => {
      // const ret = _.get(res.data, '$original')
      const adaptedData = adaptUserTypeChange(id, allData, isActionDelete)
      dispatch(this.success({ data:adaptedData }))
      return res
    })
})


export {
  FETCH_USERS,
  UPDATE_USER_TYPE,
}