// Not reusable -- need to be refactored
import React from 'react'
import cn from 'classnames'

export default class SquareButton extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isOnHover: false
    }
  }

  onMouseEnter = () => {
    const { disabled } = this.props
    this.setState({
      isOnHover: !disabled
    })
  }

  onMouseLeave = () => {
    this.setState({
      isOnHover: false
    })
  }

  render() {
    const { published, loading, title, onBtnClick, disabled, isBtnVisible = true } = this.props
    const { isOnHover } = this.state
    let iconObj = null
    let iconObj_inActive = null
    let loadingObj = null
    if (!loading) {
      const zIndex_active = isOnHover ? 100 : 99
      const zIndex_inActive = isOnHover ? 99 : 100
      const img = title === 'Preview' ? 'preview' : title === 'Publish' ? 'publish' : 'unpublish'
      iconObj = (
        <img
          src={asset('/resources/images/event/edit/' + img + '-btn.svg')}
          style={{ zIndex: zIndex_active }}
        />
      )
      iconObj_inActive = (
        <img
          src={asset('/resources/images/event/edit/inactive/' + img + '-btn.svg')}
          style={{ zIndex: zIndex_inActive }}
        />
      )
    } else {
      loadingObj = <i className="fa fa-circle-o-notch fa-spin fa-3x fa-fw" />
    }
    return (
      <div
        style={{ display: isBtnVisible ? 'block' : 'none' }}
        className={cn('publish', isOnHover ? 'hover' : '', loading ? 'loading' : '')}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        onClick={disabled ? null : onBtnClick}
      >
        {iconObj}
        {iconObj_inActive}
        {loadingObj}
        <div className="title">{title === 'Preview' && published ? 'View Page' : title}</div>
      </div>
    )
  }
}
