import _ from 'lodash'
import { withFormik } from 'formik'
import moment from 'moment'
import React from 'react'
import PropTypes from 'prop-types'

import Button from '../_library/Button'
import Select from '../_library/Select'
import Field from '../_library/Field'
import FileUploader from '../_library/FileUploader'
import TextArea from '../_library/TextArea'
import CurrencyField from '../_library/CurrencyField'
import * as fb from '../../_common/core/fb'
import Card from '../_library/Card'

import {
  TYPE_EVENT,
  TYPE_BRAND,
  SECTION_LIKES,
  SECTION_MUSIC,
  SECTION_MUSICSTREAMING
} from '../_library/OboeAudience'
import DateTimePickerReact from '../_library/DateTimePickerReact'

function isDateRangeInvalid(start, end) {
  const sm = moment.utc(start) //moment(start, dateFormat, true)
  const em = moment.utc(end) //moment(end, dateFormat, true)
  if (!sm.isValid()) {
    return
  }
  if (!em.isValid()) {
    return
  }
  return em.isSame(sm) || em.isBefore(sm)
}

function isEndDateInvalid(end) {
  const cm = moment.utc() //moment(start, dateFormat, true)
  const em = moment.utc(end) //moment(end, dateFormat, true)
  if (!cm.isValid()) {
    return
  }
  if (!em.isValid()) {
    return
  }
  return em.isSame(cm) || em.isBefore(cm)
}

function validateAd(data) {
  const errors = {}

  if (!_.get(data, 'attributes.user.accountId')) {
    _.set(errors, 'attributes.user.accountId', 'This field is required')
  }

  if (_.get(data, 'submitType') === 'createAd' && !_.get(data, 'attributes.campaign.name')) {
    _.set(errors, 'attributes.campaign.name', 'This field is required')
  }

  if (_.get(data, 'submitType') === 'createAd' && !_.get(data, 'attributes.campaign.objective')) {
    _.set(errors, 'attributes.campaign.objective', 'This field is required')
  }

  if (_.get(data, 'submitType') === 'createAd' && !_.get(data, 'attributes.adSet.name')) {
    _.set(errors, 'attributes.adSet.name', 'This field is required')
  }

  if (_.get(data, 'submitType') === 'createAd' && !_.get(data, 'attributes.adSet.daily_budget')) {
    _.set(errors, 'attributes.adSet.daily_budget', 'This field is required')
  }

  if (
    _.get(data, 'submitType') === 'createAd' &&
    !_.get(data, 'attributes.adSet.is_autobid', true) &&
    !_.get(data, 'attributes.adSet.bid_amount')
  ) {
    _.set(errors, 'attributes.adSet.bid_amount', 'This field is required')
  }

  if (!_.get(data, 'attributes.adCreative.object_story_spec.link_data.link')) {
    _.set(errors, 'attributes.adCreative.object_story_spec.link_data.link', 'This field is required')
  }

  if (!_.get(data, 'attributes.adCreative.object_story_spec.page_id')) {
    _.set(errors, 'attributes.adCreative.object_story_spec.page_id', 'This field is required')
  }

  if (_.get(data, 'submitType') === 'createAd' && !_.get(data, 'attributes.ad.name')) {
    _.set(errors, 'attributes.ad.name', 'This field is required')
  }

  if (!_.get(data, 'submitType') === 'createAd' && _.get(data, 'attributes.campaign.name', '').length > 65) {
    _.set(errors, 'attributes.campaign.name', 'Event name cannot exceed 65 characters')
  }

  const startDate = _.get(data, 'attributes.campaign.start_time')
  const endDate = _.get(data, 'attributes.campaign.stop_time')
  if (endDate) {
    if (isEndDateInvalid(endDate)) {
      _.set(errors, 'attributes.campaign.stop_time', 'End date must be after current date')
    }
    if (startDate && isDateRangeInvalid(startDate, endDate)) {
      _.set(errors, 'attributes.campaign.stop_time', 'End date must be after start date')
    }
  }

  return errors
}

class AdFormComponent extends React.Component {
  static propTypes = {
    user: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.fbAdAccounts = []
    this.fbPages = []
    this.fbAdAccountsLoading = false

    this.state = {
      submittingPreview: false,
      submittingAd: false
    }

    this.fetchFb = this.fetchFb.bind(this)
    this.selectFbAdAccount = this.selectFbAdAccount.bind(this)
    this.selectFbPage = this.selectFbPage.bind(this)
    this.processGeneratePreview = this.processGeneratePreview.bind(this)
    this.onIsAutoBidChange = this.onIsAutoBidChange.bind(this)
    this.processCreateAd = this.processCreateAd.bind(this)
    this.setFieldValue = this.setFieldValue.bind(this)
  }

  componentDidMount() {
    setTimeout(() => {
      this.btnFetchFB.click()
    }, 1000)
  }

  fetchFb() {
    const that = this

    this.fbAdAccounts = []
    this.fbAdAccountsLoading = true
    this.forceUpdate()

    fb.fetchSDK()
      .catch(onerror)
      .then(FB => {
        FB.login(
          response => {
            if (response.authResponse) {
              that.fbAccessToken = response.authResponse.accessToken
              that.props.setFieldValue('attributes.user.accessToken', response.authResponse.accessToken)
              that.fetchFbPages(FB)
              that.fetchFbAdAccounts(FB)
            }
          },
          { scope: 'ads_management,business_management,pages_show_list' }
        )
      })
  }

  fetchFbPages(FB) {
    const that = this
    FB.api('/me/accounts', response => {
      that.fbPages = response.data
      that.forceUpdate()
      return
    })
  }

  fetchFbAdAccounts(FB) {
    const that = this
    FB.api('/me/adaccounts?fields=id,name,currency,owner,business', response => {
      if (response.data) {
        const accts = _.filter(response.data, ad => ad.business)
        that.fbAdAccounts = _.map(accts, (fbAdAccount, fbAdAccountIndex) => ({
          id: fbAdAccount.id,
          name: fbAdAccount.name,
          currency: fbAdAccount.currency,
          owner_id: fbAdAccount.owner,
          business: fbAdAccount.business
        }))
        that.fbAdAccountsLoading = false
        that.fbAdAccountsLoadingError = null
        that.forceUpdate()
        _.each(that.fbAdAccounts, fbAdAccount => {
          that.fetchFbAdAccountOwner(FB, fbAdAccount)
        })
      } else {
        that.fbAdAccountsLoading = false
        that.fbAdAccountsLoadingError = response.error.message
        that.forceUpdate()
      }
    })
  }

  fetchFbAdAccountOwner(FB, fbAdAccount) {
    const that = this
    if (fbAdAccount.business) {
      fbAdAccount.owner = fbAdAccount.business
      that.forceUpdate()
    } else {
      FB.api('/' + fbAdAccount.owner_id, response => {
        fbAdAccount.owner = response
        that.forceUpdate()
      })
    }
  }

  selectFbAdAccount(e) {
    const id = e.target.value
    this.props.setFieldValue('attributes.user.accountId', id)
    const fbAdAccount = this.fbAdAccounts.find(fad => fad.id === id)
    this.props.setFieldValue('attributes.user.currency', fbAdAccount && fbAdAccount.currency)
    this.props.setFieldValue('attributes.user.accountName', fbAdAccount && fbAdAccount.name)
  }

  selectFbPage(e) {
    const id = e.target.value
    this.props.setFieldValue('attributes.adCreative.object_story_spec.page_id', id)
    const fbPage = this.fbPages.find(fpg => fpg.id === id)
    this.props.setFieldValue('attributes.user.pageName', fbPage && fbPage.name)
  }

  onIsAutoBidChange(e) {
    if (e.target.checked) {
      this.props.setFieldValue('attributes.adSet.bid_amount', null)
      this.props.setFieldValue('attributes.adSet.is_autobid', true)
    } else {
      this.props.setFieldValue(
        'attributes.adSet.bid_amount',
        _.get(this.props, 'initialValues.attributes.bid_amount') || null
      )
      this.props.setFieldValue('attributes.adSet.is_autobid', false)
    }
  }

  processGeneratePreview() {
    const { handleSubmit } = this.props
    this.props.setFieldValue('submitType', 'generatePreview')
    this.setState({ submittingPreview: true }, function () {
      return Promise.resolve(handleSubmit()).then(() => {
        this.setState({ submittingPreview: false })
      })
    })
  }

  processCreateAd() {
    const { handleSubmit } = this.props
    this.props.setFieldValue('submitType', 'createAd')
    this.setState({ submittingAd: true }, function () {
      return Promise.resolve(handleSubmit())
        .catch(err => {
          console.error('error processing create ad', err)
        })
        .then(() => {
          this.setState({ submittingAd: false })
        })
    })
  }

  setFieldValue(field, value) {
    this.props.setFieldValue(field, value)
  }

  render() {
    const that = this

    const {
      events,
      adCreativePreview,
      type,
      section,
      audienceItem,
      event,
      brand,
      isSubmitting,
      handleSubmit,
      values,
      touched,
      errors,
      handleChange,
      handleBlur
    } = this.props

    const { submittingPreview, submittingAd } = this.state

    let subtitle = ''
    let v_img = ''
    let v_category = ''
    let v_name = ''
    let v_count = 0
    let v_fan = 'Fan'

    switch (type) {
      case TYPE_EVENT:
        subtitle = 'Targeting attendees of "' + event.displayName + '" who like "' + audienceItem.name + '"'
        break
      case TYPE_BRAND:
        subtitle =
          'Targeting attendees of "' + brand.displayName + '"\'s events who like "' + audienceItem.name + '"'
        break
    }

    switch (section) {
      case SECTION_LIKES:
        v_img = '//graph.facebook.com/' + audienceItem.id + '/picture?width=200'
        v_category = audienceItem.category
        v_name = audienceItem.name
        v_count = audienceItem.count
        v_fan = 'Followers'
        break
      case SECTION_MUSIC:
        v_img = '//graph.facebook.com/' + audienceItem.id + '/picture?width=200'
        v_category = audienceItem.category
        v_name = audienceItem.name
        v_count = audienceItem.count
        v_fan = 'Fans'
        break
      case SECTION_MUSICSTREAMING:
        v_img = audienceItem.images && audienceItem.images[0] ? audienceItem.images[0].url : ''
        v_category = audienceItem.application
        v_name = audienceItem.name
        v_count = audienceItem.count
        v_fan = 'Listeners'
        break
      default:
        break
    }

    return (
      <div className="adform">
        <div className="adform-header">
          <div className="adform-audience">
            <img className="LazyLoadImg" src={v_img} />
            <div className="description">
              <div className="left">
                <div className="category">{v_category}</div>
                <div className="name">{v_name}</div>
              </div>
              <div className="right">
                <div className="count">{v_count}</div>
                <div className="fan">{v_fan}</div>
              </div>
            </div>
          </div>
          <div className="adform-header-right">
            <div className="adform-description">{subtitle}</div>
          </div>
        </div>
        <Card icon="fa-camera" title="Ad Creative">
          <div className="row">
            <div className="col-sm-12 col-xs-12">
              <button
                onClick={this.fetchFb}
                ref={input => (this.btnFetchFB = input)}
                style={{ display: 'none' }}
              >
                Fetch FB
              </button>
              <div className="adform-accounts">
                <Select
                  id="attributes.user.accountId"
                  value={_.get(values, 'attributes.user.accountId')}
                  error={_.get(errors, 'attributes.user.accountId')}
                  touched={_.get(touched, 'attributes.user.acountId')}
                  onChange={this.selectFbAdAccount}
                  options={[{ value: '', label: 'Select an AD Account' }].concat(
                    _.map(that.fbAdAccounts, a => ({
                      value: a.id,
                      label: a.name + ' [' + a.currency + ']' + (a.owner && ' owned by ' + a.owner.name)
                    }))
                  )}
                />
                {this.fbAdAccountsLoadingError && (
                  <div className="text-danger">
                    Loading Ad Accounts failed: {this.fbAdAccountsLoadingError}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-xs-12">
              <Field
                label="Headline"
                id="attributes.adCreative.object_story_spec.link_data.name"
                value={_.get(values, 'attributes.adCreative.object_story_spec.link_data.name')}
                error={_.get(errors, 'attributes.adCreative.object_story_spec.link_data.name')}
                touched={_.get(touched, 'attributes.adCreative.object_story_spec.link_data.name')}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-xs-12">
              <Select
                label="Page"
                id="attributes.adCreative.object_story_spec.page_id"
                value={_.get(values, 'attributes.adCreative.object_story_spec.page_id')}
                error={_.get(errors, 'attributes.adCreative.object_story_spec.page_id')}
                touched={_.get(touched, 'attributes.adCreative.object_story_spec.page_id')}
                onChange={this.selectFbPage}
                options={[{ value: '', label: 'Select the page to post this ad with' }].concat(
                  _.map(that.fbPages, p => ({ value: p.id, label: p.name }))
                )}
              />
            </div>
            <div className="col-sm-6 col-xs-12">
              <Select
                label="Event"
                id="attributes.adCreative.object_story_spec.link_data.link"
                value={_.get(values, 'attributes.adCreative.object_story_spec.link_data.link')}
                error={_.get(errors, 'attributes.adCreative.object_story_spec.link_data.link')}
                touched={_.get(touched, 'attributes.adCreative.object_story_spec.link_data.link')}
                onChange={handleChange}
                options={[{ value: '', label: 'Select an event to promote' }].concat(
                  _.map(events, e => ({ value: e.slug, label: e.displayName }))
                )}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-xs-12">
              <TextArea
                label="Text"
                id="attributes.adCreative.object_story_spec.link_data.message"
                value={_.get(values, 'attributes.adCreative.object_story_spec.link_data.message')}
                error={_.get(errors, 'attributes.adCreative.object_story_spec.link_data.message')}
                touched={_.get(touched, 'attributes.adCreative.object_story_spec.link_data.message')}
                onChange={handleChange}
              />
            </div>
            <div className="col-sm-6 col-xs-12">
              <TextArea
                label="Description (optional)"
                id="attributes.adCreative.object_story_spec.link_data.description"
                value={_.get(values, 'attributes.adCreative.object_story_spec.link_data.description')}
                error={_.get(errors, 'attributes.adCreative.object_story_spec.link_data.description')}
                touched={_.get(touched, 'attributes.adCreative.object_story_spec.link_data.description')}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-xs-12">
              <FileUploader
                label="Add Ad Image"
                filetype="image"
                id="attributes.adCreative.object_story_spec.link_data.picture"
                value={_.get(values, 'attributes.adCreative.object_story_spec.link_data.picture')}
                error={_.get(errors, 'attributes.adCreative.object_story_spec.link_data.picture')}
                touched={_.get(touched, 'attributes.adCreative.object_story_spec.link_data.picture')}
                onChange={val =>
                  this.setFieldValue('attributes.adCreative.object_story_spec.link_data.picture', val)
                }
              />
            </div>
            {adCreativePreview.previewGenerated && (
              <div className="col-sm-6 col-xs-12">
                <div
                  id="adCreativePreview"
                  className="preview text-center"
                  dangerouslySetInnerHTML={adCreativePreview.previewIframe()}
                />
              </div>
            )}
          </div>
          <div className="btn-toolbar text-center">
            <Button
              className="btn btn-success btn-lg btn-shadow"
              type="button"
              loading={submittingPreview}
              onClick={this.processGeneratePreview}
            >
              Generate Preview
            </Button>
          </div>
        </Card>
        <Card icon="fa fa-info" title="Ad Campaign">
          <div className="row">
            <div className="col-sm-6 col-xs-12">
              <Select
                label="Campaign Objective"
                id="attributes.campaign.objective"
                value={_.get(values, 'attributes.campaign.objective')}
                error={_.get(errors, 'attributes.campaign.objective')}
                touched={_.get(touched, 'attributes.campaign.objective')}
                onChange={handleChange}
                options={[
                  { value: '', label: 'Select an objective for your ad campaign' },
                  { value: 'LINK_CLICKS', label: 'Link Click' },
                  { value: 'REACH', label: 'Reach' },
                  { value: 'CONVERSIONS', label: 'Conversions' }
                ]}
              />
            </div>
            <div className="col-sm-6 col-xs-12">
              <Field
                label="Campaign Name"
                id="attributes.campaign.name"
                value={_.get(values, 'attributes.campaign.name')}
                error={_.get(errors, 'attributes.campaign.name')}
                touched={_.get(touched, 'attributes.campaign.name')}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6" style={{ position: 'static' }}>
              <DateTimePickerReact
                label="Start date"
                placeholder="D MMM YYYY H:M AM"
                id="attributes.campaign.start_time"
                value={_.get(values, 'attributes.campaign.start_time')}
                error={_.get(errors, 'attributes.campaign.start_time')}
                touched={_.get(touched, 'attributes.campaign.start_time')}
                onChange={val => this.setFieldValue('attributes.campaign.start_time', val)}
              />
            </div>
            <div className="col-sm-6" style={{ position: 'static' }}>
              <DateTimePickerReact
                label="End date"
                placeholder="D MMM YYYY H:M AM"
                id="attributes.campaign.stop_time"
                value={_.get(values, 'attributes.campaign.stop_time')}
                error={_.get(errors, 'attributes.campaign.stop_time')}
                touched={_.get(touched, 'attributes.campaign.stop_time')}
                onChange={val => this.setFieldValue('attributes.campaign.stop_time', val)}
                suggestion={_.get(values, 'attributes.campaign.start_time')}
              />
            </div>
          </div>
        </Card>
        <Card icon="fa-pencil-square" title="Ad Set & Ad">
          <div className="row">
            <div className="col-sm-6 col-xs-12">
              <Field
                label="Ad Set Name"
                id="attributes.adSet.name"
                value={_.get(values, 'attributes.adSet.name')}
                error={_.get(errors, 'attributes.adSet.name')}
                touched={_.get(touched, 'attributes.adSet.name')}
                onChange={handleChange}
              />
            </div>
            <div className="col-sm-6 col-xs-12">
              <Field
                label="Ad Name"
                id="attributes.ad.name"
                value={_.get(values, 'attributes.ad.name')}
                error={_.get(errors, 'attributes.ad.name')}
                touched={_.get(touched, 'attributes.ad.name')}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-xs-12">
              <CurrencyField
                type="text"
                label={
                  'Daily Budget' +
                  (_.get(values, 'attributes.user.currency')
                    ? ' (' + _.get(values, 'attributes.user.currency') + ')'
                    : '')
                }
                id="attributes.adSet.daily_budget"
                value={_.get(values, 'attributes.adSet.daily_budget')}
                error={_.get(errors, 'attributes.adSet.daily_budget')}
                touched={_.get(touched, 'attributes.adSet.daily_budget')}
                onChange={val => this.setFieldValue('attributes.adSet.daily_budget', val)}
              />
            </div>
            <div className="col-sm-6 col-xs-12">
              <div className="line">
                <div className="line-cell">
                  <label htmlFor="isAutoBid">Use automatic bidding (Recommended)</label>
                </div>
                <div className="line-cell">
                  <div className="checkbox-switch">
                    <input
                      type="checkbox"
                      id="isAutoBid"
                      checked={!!_.get(values, 'attributes.adSet.is_autobid')}
                      onChange={this.onIsAutoBidChange}
                    />
                    <label htmlFor="isAutoBid" />
                  </div>
                </div>
              </div>
              {!_.get(values, 'attributes.adSet.is_autobid') && (
                <CurrencyField
                  id="attributes.adSet.bid_amount"
                  type="text"
                  label={
                    'Bid Amount' +
                    (_.get(values, 'attributes.user.currency')
                      ? ' (' + _.get(values, 'attributes.user.currency') + ')'
                      : '')
                  }
                  value={_.get(values, 'attributes.adSet.bid_amount')}
                  error={_.get(errors, 'attributes.adSet.bid_amount')}
                  touched={_.get(touched, 'attributes.adSet.bid_amount')}
                  onChange={val => this.setFieldValue('attributes.adSet.bid_amount', val)}
                />
              )}
            </div>
          </div>
        </Card>
        <div className="btn-toolbar text-center">
          {adCreativePreview.previewGenerated && (
            <Button
              className="btn btn-success btn-lg btn-shadow"
              type="button"
              loading={submittingAd}
              onClick={this.processCreateAd}
            >
              Create Ad
            </Button>
          )}
        </div>
      </div>
    )
  }
}

const AdForm = withFormik({
  mapPropsToValues: props => ({
    attributes: {
      user: {
        accountId: _.get('props', 'initialValues.attributes.user.accountId') || '',
        accessToken: _.get('props', 'initialValues.attributes.user.accessToken') || null,
        currency: _.get('props', 'initialValues.attributes.user.currency') || null
      },
      campaign: {
        name: _.get('props', 'initialValues.attributes.campaign.name') || null,
        objective: _.get('props', 'initialValues.attributes.campaign.objective') || null,
        start_time: _.get('props', 'initialValues.attributes.campaign.start_time') || null,
        stop_time: _.get('props', 'initialValues.attributes.campaign.stop_time') || null,
        special_ad_category: 'NONE'
      },
      adCreative: {
        object_story_spec: {
          link_data: {
            link: _.get(props, 'initialValues.attributes.adCreative.object_story_spec.link_data.link') || '',
            name:
              _.get(props, 'initialValues.attributes.adCreative.object_story_spec.link_data.name') || null,
            message:
              _.get(props, 'initialValues.attributes.adCreative.object_story_spec.link_data.message') || null,
            description:
              _.get(props, 'initialValues.attributes.adCreative.object_story_spec.link_data.description') ||
              null,
            picture:
              _.get(props, 'initialValues.attributes.adCreative.object_story_spec.link_data.picture') || null
          },
          page_id: _.get(props, 'initialValues.attributes.adCreative.object_story_spec.page_id') || ''
        }
      },
      adSet: {
        name: _.get(props, 'initialValues.attributes.adSet.name') || null,
        daily_budget: _.get(props, 'initialValues.attributes.adSet.daily_budget') || null,
        bid_amount: _.get(props, 'initialValues.attributes.adSet.bid_amount') || null,
        is_autobid: _.get(props, 'initialValues.attributes.adSet.is_autobid') || true
      },
      ad: {
        name: _.get(props, 'initialValues.attributes.ad.name') || null,
        status: _.get(props, 'initialValues.attributes.ad.status') || null
      }
    },
    submitType: _.get(props, 'initialValues.submitType') || null
  }),

  // Custom sync validation
  validate: values => validateAd(values),

  handleSubmit: (values, { props, setSubmitting }) => {
    props
      .onSubmit({ ...values })
      .then(v => {
        setSubmitting(false)
      })
      .catch(err => {
        setSubmitting(false)
      })
  },
  displayName: 'AdForm' // helps with React DevTools
})(AdFormComponent)

export default AdForm
