import { baseRequest } from '.'
import _get from 'lodash/get'

export const updateCustomer = async form => {
  const res = await baseRequest.put(`signup/update-customer`, form)
  return _get(res, 'data')
}

export const requestDashboardAccess = async email => {
  const res = await baseRequest.post(`signup/request-dashboard-access/`, {
    data: {
      attributes: {
        email
      }
    }
  })
  return _get(res, 'data')
}

export const newCustomer = async form => {
  const res = await baseRequest.post(`signup/new-customer`, form)
  return _get(res, 'data')
}

export const newBrand = async form => {
  const res = await baseRequest.post(`signup/new-brand`, form)
  return _get(res, 'data')
}

export const sendBrandInvitation = async (brandId, invitations) => {
  const res = await baseRequest.post(`signup/team-invitation`, {
    data: {
      type: "organization",
      brandId: Number(brandId),
      attributes: invitations.map(({ email, role }) => ({ email, role }))
    }
  })
  return _get(res, 'data')
}