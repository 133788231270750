import _result from 'lodash/result'
import _get from 'lodash/result'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import React from 'react'

import Notifications from '../_library/notifications/Notifications'
import { CREATE_TOUR, FETCH_TOURS } from '../../_common/redux/tours/actions'
import TourForm from './TourForm'
import { getTitle } from '../utils/getTitle'

const initialValues = {
  attributes: {}
}

@withRouter
@connect(
  (state) => {
    const { user } = state.auth
    const { events } = state.events
    const eventsFullReady = state.events.fullReady
    const { tours } = state.tours
    const dynamicConfigs = state.launchDarklyInfo.launchDarklyFlags

    return {
      user,
      events,
      tours,
      eventsFullReady,
      dynamicConfigs,
    }
  },
  { CREATE_TOUR, FETCH_TOURS }
)
export default class NewTourPage extends React.PureComponent {
  componentDidMount() {
    const { FETCH_TOURS } = this.props
    FETCH_TOURS()
    
    const configDocTitle = _get(this.props.configs, 'messages.documentTitle', '')
    document.title = getTitle(configDocTitle)
  }

  handleSubmit = form => {
    const { history: { push }, CREATE_TOUR } = this.props

    return Promise.resolve(CREATE_TOUR(form))
      .catch(err => Promise.reject(_result(err, 'toFieldErrors', err)))
      .then(res => {
        push('/tours')
        return res
      })
  }

  render() {
    const { user, events, eventsFullReady, dynamicConfigs, tours, configs } = this.props

    return (
      <div className='body-main'>
        <Notifications />
        <div>
          <TourForm
            onSubmit={this.handleSubmit}
            initialValues={initialValues}
            user={user}
            events={events}
            tours={tours}
            eventsFullReady={eventsFullReady}
            isNew={true}
            dynamicConfigs={dynamicConfigs}
            configs={configs}
          />
        </div>
      </div>
    )
  }
}
