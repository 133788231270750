import getSlug from 'speakingurl'
import React from 'react'
import Field from './Field'

export default class AutoSlugField extends React.Component {
  constructor(props) {
    super(props)
    this.state = { editing: !!(props.defaultValue || props.initialValue), savedSlug: props.suggestion }
    this.cancel = this.cancel.bind(this)
    this.onChange = this.onChange.bind(this)
    this.edit = this.edit.bind(this)
  }

  onChange(e) {
    const { onChange } = this.props
    if (onChange) {
      onChange(e)
    }
  }

  edit() {
    this.setState({ editing: true })
  }
  cancel() {
    this.setState({ editing: false })
  }

  componentWillUpdate(nextProps, nextState) {
    const { onChange, suggestion, separator } = nextProps
    const { editing, savedSlug } = nextState
    const changed = editing !== this.state.editing || suggestion !== this.props.suggestion

    if (editing) {
      return
    }

    if (changed && onChange) {
      const slugOptions = {
        symbols: false,
        separator: separator || ' '
      }

      let slug = getSlug(suggestion || savedSlug || '', slugOptions)

      if (!separator) {
        slug = slug.replace(/\s/g, '')
      }
      onChange(slug)
    }
  }

  render() {
    const { value, children, disabled } = this.props
    if (this.state.editing) {
      return (
        <div style={{ flexDirection: 'row', alignItems: 'center', display: 'flex' }}>
          <div style={{ width: '100%' }}>
            <Field
              {...this.props}
              style={{ flex: 1, marginRight: 10 }}
              isAutoSlug={true}
              type="text"
              disabled={disabled}
            >
              {children}
            </Field>
          </div>
          {!value && !disabled && false && (
            <Field.SuffixButton
              className="btn btn-default"
              type="button"
              title="Use an automatically generated link"
              onClick={this.cancel}
            >
              <i className="fa fa-undo" />
            </Field.SuffixButton>
          )}
        </div>
      )
    } 
    return (
      <Field
        {...this.props}
        isAutoSlug={true}
        type="hidden"
        onChange={this.onChange}
        onClick={this.edit}
        disabled={disabled}
      />
    )
    
  }
}
