import React, { Component } from 'react'
import { Formik, Form, Field } from 'formik'
import _isEmpty from 'lodash/isEmpty'
import _keys from 'lodash/keys'

import Button from '../../_library/Button'

// form fields
import { SimpleField, CheckboxField } from '../../formik/Fields'

import {
  // normalizer
  toInt,
  combineNormalizers,
  minNormalizerCreator
} from '../../../_common/core/validation'
import { validate } from './validation'

const pointsLabel = 'Points'
const pointsPlaceholder = 'Points for action'
const urlPlaceholder = 'URL'

class PreRegistrationAction extends Component {
  renderInitialValues = () => {
    const { data } = this.props
    const initialValues = {
      //1
      facebook_share: '',
      facebook_share_active: false,
      //2
      messenger: '',
      messenger_active: false,
      //3
      twitter: '',
      twitter_active: false,
      //4
      spotify: '',
      spotify_active: false,
      //5
      shared_url: '',
      shared_url_active: false,
      //6
      facebook_going: '',
      facebook_going_active: false,
      facebook_going_url: '',
      //7
      facebook_follow: '',
      facebook_follow_active: false,
      facebook_follow_url: '',
      //8,
      instagram_follow: '',
      instagram_follow_active: false,
      instagram_follow_url: ''
    }

    data.forEach(item => {
      initialValues[item.action] = +item.point
      initialValues[`${item.action}_active`] = true
      if (item.socialUrl) {
        initialValues[`${item.action}_url`] = item.socialUrl
      }
    })
    return initialValues
  }

  handleScrollToErrors = errors => {
    if (this.refs[errors[0]]) {
      this.refs[errors[0]].scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }
  render() {
    const { handleSubmit, data } = this.props

    return (
      <div className="pre_registration_action_section">
        <Formik
          enableReinitialize={true}
          initialValues={this.renderInitialValues()}
          validate={validate}
          onSubmit={(values, actions) => {
            const { setFieldValue, setSubmitting, resetForm } = actions
            handleSubmit(values).finally(() => {
              setSubmitting(false)
            })
          }}
          render={({ values, isSubmitting, errors, touched }) => {
            if (!_isEmpty(errors) && !_isEmpty(touched)) {
              this.handleScrollToErrors(_keys(errors))
            }
            return (
              <Form autoComplete="off">
                <div className="pre_reg_container">
                  <div className="pre_reg_single_inputs">
                    <div className="pre_reg_row">
                      <div
                        ref={ref => (this.refs = { ...this.refs, facebook_share: ref })}
                        className="pre_reg_row_inner"
                      >
                        <div className="pre-reg-checkbox">
                          <span className="with_icon icon_fb">
                            <span className="header-span">Share on Facebook</span>
                          </span>
                          <Field name="facebook_share_active" component={CheckboxField} />
                        </div>
                        <div className="prer_reg_input">
                          <Field
                            name="facebook_share"
                            label={pointsLabel}
                            component={SimpleField}
                            type="number"
                            disabled={!values.facebook_share_active}
                            normalizer={combineNormalizers(minNormalizerCreator(1), toInt)}
                            placeholder={pointsPlaceholder}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="pre_reg_row">
                      <div
                        ref={ref => (this.refs = { ...this.refs, messenger: ref })}
                        className="pre_reg_row_inner"
                      >
                        <div className="pre-reg-checkbox">
                          <span className="with_icon icon_messenger">
                            <span className="header-span">Share in Messenger</span>
                          </span>
                          <Field name="messenger_active" component={CheckboxField} />
                        </div>
                        <div className="prer_reg_input">
                          <Field
                            name="messenger"
                            label={pointsLabel}
                            component={SimpleField}
                            type="number"
                            disabled={!values.messenger_active}
                            normalizer={combineNormalizers(minNormalizerCreator(1), toInt)}
                            placeholder={pointsPlaceholder}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="pre_reg_row">
                      <div
                        ref={ref => (this.refs = { ...this.refs, twitter: ref })}
                        className="pre_reg_row_inner"
                      >
                        <div className="pre-reg-checkbox">
                          <span className="with_icon icon_twitter">
                            <span className="header-span">Tweet to Followers</span>
                          </span>
                          <Field name="twitter_active" component={CheckboxField} />
                        </div>
                        <div className="prer_reg_input">
                          <Field
                            name="twitter"
                            label={pointsLabel}
                            component={SimpleField}
                            type="number"
                            disabled={!values.twitter_active}
                            normalizer={combineNormalizers(minNormalizerCreator(1), toInt)}
                            placeholder={pointsPlaceholder}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="pre_reg_row">
                      <div
                        ref={ref => (this.refs = { ...this.refs, spotify: ref })}
                        className="pre_reg_row_inner"
                      >
                        <div className="pre-reg-checkbox">
                          <span className="with_icon icon_spotify">
                            <span className="header-span">Connect to Spotify</span>
                          </span>
                          <Field name="spotify_active" component={CheckboxField} />
                        </div>
                        <div className="prer_reg_input">
                          <Field
                            name="spotify"
                            label={pointsLabel}
                            component={SimpleField}
                            type="number"
                            disabled={!values.spotify_active}
                            normalizer={combineNormalizers(minNormalizerCreator(1), toInt)}
                            placeholder={pointsPlaceholder}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="pre_reg_row">
                      <div
                        ref={ref => (this.refs = { ...this.refs, shared_url: ref })}
                        className="pre_reg_row_inner"
                      >
                        <div className="pre-reg-checkbox">
                          <span className="with_icon icon_share">
                            <span className="header-span">Sharing Event with Personal Link</span>
                          </span>
                          <Field name="shared_url_active" component={CheckboxField} />
                        </div>
                        <div className="prer_reg_input">
                          <Field
                            name="shared_url"
                            label={pointsLabel}
                            component={SimpleField}
                            type="number"
                            disabled={!values.shared_url_active}
                            normalizer={combineNormalizers(minNormalizerCreator(1), toInt)}
                            placeholder={pointsPlaceholder}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="pre_reg_multiple_inputs">
                    <div className="pre_reg_row">
                      <div
                        ref={ref => (this.refs = { ...this.refs, facebook_going: ref })}
                        className="pre_reg_row_inner"
                      >
                        <div className="pre-reg-checkbox">
                          <span className="with_icon icon_fb">
                            <span className="header-span">Set Going on Facebook Event</span>
                          </span>
                          <Field name="facebook_going_active" component={CheckboxField} />
                        </div>
                        <div className="pre_reg_input_block">
                          <div className="prer_reg_input">
                            <Field
                              name="facebook_going"
                              label={pointsLabel}
                              component={SimpleField}
                              type="number"
                              disabled={!values.facebook_going_active}
                              normalizer={combineNormalizers(minNormalizerCreator(1), toInt)}
                              placeholder={pointsPlaceholder}
                            />
                          </div>
                          <div className="prer_reg_input">
                            <Field
                              name="facebook_going_url"
                              label="URL"
                              component={SimpleField}
                              disabled={!values.facebook_going_active}
                              placeholder={urlPlaceholder}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pre_reg_row">
                      <div
                        ref={ref => (this.refs = { ...this.refs, facebook_follow: ref })}
                        className="pre_reg_row_inner"
                      >
                        <div className="pre-reg-checkbox">
                          <span className="with_icon icon_fb">
                            <span className="header-span">Follow on Facebook</span>
                          </span>
                          <Field name="facebook_follow_active" component={CheckboxField} />
                        </div>
                        <div className="pre_reg_input_block">
                          <div className="prer_reg_input">
                            <Field
                              name="facebook_follow"
                              label={pointsLabel}
                              component={SimpleField}
                              type="number"
                              disabled={!values.facebook_follow_active}
                              normalizer={combineNormalizers(minNormalizerCreator(1), toInt)}
                              placeholder={pointsPlaceholder}
                            />
                          </div>
                          <div className="prer_reg_input">
                            <Field
                              name="facebook_follow_url"
                              label="URL"
                              component={SimpleField}
                              disabled={!values.facebook_follow_active}
                              placeholder={urlPlaceholder}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pre_reg_row">
                      <div
                        ref={ref => (this.refs = { ...this.refs, instagram_follow: ref })}
                        className="pre_reg_row_inner"
                      >
                        <div className="pre-reg-checkbox">
                          <span className="with_icon icon_insta">
                            <span className="header-span">Follow on Instagram</span>
                          </span>
                          <Field name="instagram_follow_active" component={CheckboxField} />
                        </div>
                        <div className="pre_reg_input_block">
                          <div className="prer_reg_input">
                            <Field
                              name="instagram_follow"
                              label={pointsLabel}
                              component={SimpleField}
                              type="number"
                              disabled={!values.instagram_follow_active}
                              normalizer={combineNormalizers(minNormalizerCreator(1), toInt)}
                              placeholder={pointsPlaceholder}
                            />
                          </div>
                          <div className="prer_reg_input">
                            <Field
                              name="instagram_follow_url"
                              label="URL"
                              component={SimpleField}
                              disabled={!values.instagram_follow_active}
                              placeholder={urlPlaceholder}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row pre_reg_action_button_save">
                  <div className="col-xs-12">
                    <Button
                      className="btn btn-success btn-lg btn-shadow"
                      type="submit"
                      loading={isSubmitting}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </Form>
            )
          }}
        />
      </div>
    )
  }
}

export default PreRegistrationAction
