import { createSelector } from 'reselect'
import _get from 'lodash/get'

// selectors
import {
  get_event_id
} from '.'

export const get_all_faqs = (state) => _get(state, 'faqs.faqs')

export const get_event_faqs = createSelector(
  get_all_faqs,
  get_event_id,
  (all_faqs, eid) => _get(all_faqs, eid, [])
)
