import _findIndex from 'lodash/findIndex'
import _result from 'lodash/result'
import _orderBy from 'lodash/orderBy'
import _filter from 'lodash/filter'
import _get from 'lodash/get'
import { connect } from 'react-redux'
import React from 'react'
import Modal from 'react-modal'
import modalStyle from '../../../_common/core/modalStyle'
import Button from '../../_library/Button'
import LoadingBar from '../../_library/LoadingBar'
import { FETCH_TOUR_TEAM_INVITATIONS, FETCH_TOUR_TEAM_MEMBERS, ADD_TOUR_TEAM_INVITATION, RESEND_TOUR_TEAM_INVITATION } from '../../../_common/redux/tourteam/actions'
import { CANCEL_INVITATION } from '../../../_common/redux/eventteam/actions'

import {
  JSONDatatable, 
  TYPE_FROM_ARRAY,
  SEARCHBAR, DATATABLE,
} from '../../_library/JSONDatatable'

import TourTeamInvitationForm from './TourTeamInvitationForm'
import { getTitle } from '../../utils/getTitle'


const INVITATION_INITIAL_VALUES = {
  attributes: {
    role: 'promoter'
  }
}

const ROLES_MAP = {
  'owner': 'Owner',
  'admin': 'Administrator', 
  'stats': 'Stats',
  'limited_stats': 'Limited Stats',
  'promoter': 'Promoter',
  'staff': 'Door Staff',
  'curator': 'Curator'
}

const IS_TF_MEMBER = (m) => {
  const TF_DEVELOPERS = [
    'robbiecrusedev@outlook.com',
    'masao.ish824@outlook.com',
    'm.ishikawa@gmail.com',
    'vigorousdev@outlook.com',
    'marc@theticketfairy.com',
    'caice@gmx.com',
    'ali.caice@gmail.com'
  ]
  if (m.email) {
    return m.attributes.email.toLowerCase().endsWith('theticketfairy.com') || _findIndex(TF_DEVELOPERS, (em) => em === m.attributes.email.toLowerCase()) > -1
  } 
  return false
        
}

@connect(
  (state) => {
    const invitations = _get(state.tourteam, 'invitations.attributes')
    const { members } = state.tourteam

    return {
      user: state.auth.user,
      tour: state.tours.selected,
      invitations,
      members,
    }
  },
  { FETCH_TOUR_TEAM_INVITATIONS, FETCH_TOUR_TEAM_MEMBERS, ADD_TOUR_TEAM_INVITATION, CANCEL_INVITATION, RESEND_TOUR_TEAM_INVITATION }
)
export default class TourTeam extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			loadingInvitations: false,
			loadingMembers: false,
			showNewInvitationModal: false,
			showCancelInvitationModal: false,
            invitationToCancel: null,
			cancelingInvitation: false,
			invitationToResend: null,
            resendingInvitation: false
		}
		this.getMembersFilteredRows = this.getMembersFilteredRows.bind(this)
		this.getMembersSortedRows = this.getMembersSortedRows.bind(this)
		this.getMembersTableData = this.getMembersTableData.bind(this)
		this.onClickNewInvitation = this.onClickNewInvitation.bind(this)
		this.getInvitationsFilteredRows = this.getInvitationsFilteredRows.bind(this)
		this.getInvitationsSortedRows = this.getInvitationsSortedRows.bind(this)
		this.getInvitationsTableData = this.getInvitationsTableData.bind(this)
		this.onCancelNewInvitation = this.onCancelNewInvitation.bind(this)
		this.handleNewInvitationSubmit = this.handleNewInvitationSubmit.bind(this)
		this.onCancelInvitationCancel = this.onCancelInvitationCancel.bind(this)
		this.cancelInvitation = this.cancelInvitation.bind(this)
	}

	componentDidMount() {
    const { tour: { displayName }, configs } = this.props
    const configDocTitle = _get(configs, 'messages.documentTitle', '')
    document.title = getTitle(configDocTitle, [displayName])
		this.fetchInvitations()
		this.fetchMembers()
	}

	fetchInvitations(indicator = true) {
		const {tour, FETCH_TOUR_TEAM_INVITATIONS} = this.props
		const loadingSetter = (val) => () => indicator && this.setState({loadingInvitations: val})

		Promise.resolve(FETCH_TOUR_TEAM_INVITATIONS(tour.id))
			.catch(loadingSetter(false))
			.then(loadingSetter(false))
		loadingSetter(true)()
	}

	fetchMembers(indicator = true) {
		const {tour, FETCH_TOUR_TEAM_MEMBERS} = this.props
		const loadingSetter = (val) => () => indicator && this.setState({loadingMembers: val})

		Promise.resolve(FETCH_TOUR_TEAM_MEMBERS(tour.id))
			.catch(loadingSetter(false))
			.then(loadingSetter(false))
		loadingSetter(true)()
	}

	getInvitationsFilteredRows(rows, search){
		const isFound = (value, keyword) => {
			if(!value)
				return 0
			return value.toLowerCase().indexOf(keyword) != -1 ? 1 : 0
		}
		let rows_filtered = rows
		let keyword = search.join('').trim().toLowerCase()
		if(keyword != ''){
			rows_filtered = _filter(rows_filtered, (item) => {
				let found = 0
				found += isFound(item.firstName, keyword)
				found += isFound(item.lastName, keyword)
				found += isFound(item.email, keyword)
				found += isFound(item.role, keyword)
				return found > 0
			})
		}
		return rows_filtered
	}

	getInvitationsSortedRows(rows_filtered, sort){
		rows_filtered = _orderBy(rows_filtered, (t)=>{return t.firstName}, 'desc')
		return rows_filtered
	}

	getInvitationsTableData(datatable, rows_filtered, sort){
		const {tour} = this.props
		let self = this

		const content_header = datatable.getHeaderRow(datatable, [
			{title: 'Name', sort: false},
			{title: 'Email', sort: false},
			{title: 'Role', sort: false},
			{title: 'Actions', sort: false},
		], sort)
		
    return (
      <table className="table tour-team-invitations-table">
        <thead>
          {content_header}
        </thead>
        <tbody>
          {
            rows_filtered.map((row, index) => (
              <tr key={index} className={index % 2== 0 ? 'row-stale' : ''}>
                <td>{row.firstName} {row.lastName}</td>
                <td>{row.email}</td>
                <td>
                  <div className="role">
                    <span className={`circle ${row.role}`} />
                    <span>{ROLES_MAP[row.role]}</span>
                  </div>
                </td>
                <td>
                  <Button className="btn btn-primary" onClick={ this.resendInvitation.bind(this, row) }>
                    <i className="fa fa-undo" />Resend
                  </Button>
                  <Button className="btn btn-danger" onClick={ this.onClickCancelInvitation.bind(this, row) }>
                    <i className="fa fa-times" />Cancel
                  </Button>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    )
  }

  getMembersFilteredRows(rows, search){
    const isFound = (value, keyword) => {
      if(!value)
        return 0
      return value.toLowerCase().indexOf(keyword) != -1 ? 1 : 0
    }
    let rows_filtered = rows
    const keyword = search.join('').trim().toLowerCase()
    if(keyword != ''){
      rows_filtered = _filter(rows_filtered, (item) => {
        let found = 0
        found += isFound(item.attributes.firstName, keyword)
        found += isFound(item.attributes.lastName, keyword)
        found += isFound(item.attributes.email, keyword)
        return found > 0
      })
    }
    return rows_filtered
  }

  getMembersSortedRows(rows_filtered, sort){
    rows_filtered = _orderBy(rows_filtered, (t)=>t.attributes.firstName, 'desc')
    return rows_filtered
  }

  getMembersTableData(datatable, rows_filtered, sort){
    const { tour } = this.props
    const self = this

    const content_header = datatable.getHeaderRow(datatable, [
      { title: 'Name', sort: false },
      { title: 'Email', sort: false },
      { title: 'Role', sort: false },
    ], sort)
		
    return (
      <table className="table tour-team-members-table">
        <thead>
          {content_header}
        </thead>
        <tbody>
          { rows_filtered.length === 0 && 
                    <tr>
                      <td colSpan="3" className="text-center">
                            No Members Found
                      </td>
                    </tr>
          }
          {
            rows_filtered.length > 0 && rows_filtered.map((row, index) => {
              const role_tour = row.relationships.permissions.data.find((r) => r.attributes.enabled && r.attributes.scope === 'tour')
              return (
                <tr key={index} className={index % 2== 0 ? 'row-stale' : ''}>
                  <td>{row.attributes.firstName} {row.attributes.lastName}</td>
                  <td>{row.attributes.email}</td>
                  <td>
                    { role_tour && <div className="role">
                      <span className={`circle ${role_tour.attributes.role}`} />
                      <span>{ROLES_MAP[role_tour.attributes.role]}</span>
                    </div> }
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
    )
  }

  onClickNewInvitation() {
    this.setState({
      showNewInvitationModal: true
    })
  }

  onCancelNewInvitation() {
    this.setState({
      showNewInvitationModal: false
    })
  }

  handleNewInvitationSubmit(form) {
    const { tour, ADD_TOUR_TEAM_INVITATION } = this.props
    
    	return Promise.resolve(ADD_TOUR_TEAM_INVITATION(tour.id, form))
      .catch(err => {
        this.setState({
          showNewInvitationModal: false
        })
        return Promise.reject(_result(err, 'toFieldErrors', err))
      })
      .then(res => {
        this.setState({
          showNewInvitationModal: false
        })
        setTimeout(() => {
          this.fetchInvitations(false)
        }, 1000)
        return res
      })
  }
	
  onClickCancelInvitation(invitation) {
    this.setState({
      invitationToCancel: invitation,
      showCancelInvitationModal: true
    })
  }

  onCancelInvitationCancel() {
    this.setState({
      invitationToCancel: null,
      showCancelInvitationModal: false
    })
  }

  cancelInvitation() {
    const { CANCEL_INVITATION } = this.props
    const { invitationToCancel } = this.state

    this.setState({ cancelingInvitation: true })
    const that = this
    return Promise.resolve(CANCEL_INVITATION(invitationToCancel.token))
      .catch(err => {
        that.setState({
          cancelInvitation: false,
          invitationToCancel: null,
          showCancelInvitationModal: false
        })
        return Promise.reject(_result(err, 'toFieldErrors', err))
      })
      .then(res => {
        setTimeout(() => {
          that.fetchInvitations(false)
        }, 0)
        that.setState({
          cancelingInvitation: false,
          invitationToCancel: null,
          showCancelInvitationModal: false
        })
        return res
      })
  }
	
  resendInvitation(invitation) {
    const { tour, RESEND_TOUR_TEAM_INVITATION } = this.props        

    this.setState({
      invitationToResend: invitation,
      resendingInvitation: true
    })
    const that = this
    return Promise.resolve(RESEND_TOUR_TEAM_INVITATION(tour.id, invitation.email))
      .catch(err => {
        that.setState({
          resendingInvitation: false,
          invitationToResend: null
        })
        return Promise.reject(_result(err, 'toFieldErrors', err))
      })
      .then(res => {
        that.setState({
          resendingInvitation: false,
          invitationToResend: null
        })
        Messenger().post({
          type: 'success',
          message: 'Successfully reminded invitation',
          hideAfter: 3,
          showCloseButton: true
        })
        return res
      })
  }

  	render() {
		const {invitations, members, user} = this.props
		const {loadingInvitations, loadingMembers, showNewInvitationModal, showCancelInvitationModal, invitationToCancel, cancelingInvitation} = this.state

    const _members = Object.keys(members) > 0 ? members.filter((m) => user.tfStaff ? true : !IS_TF_MEMBER(m)) : []

    const invitationCancelConfirmMsg = invitationToCancel ? 'Are you sure you want to cancel the ' + ROLES_MAP[invitationToCancel.role] + ' team invitation to ' + invitationToCancel.email + '?' : ''

    return (
      <div className="tour-team">
        <h3 className="heading_style">Team Members</h3>
        <div className="tour-team-members">
          { loadingMembers &&
						<LoadingBar key='loadingbar' title={"Hold tight! We\'re getting your tour\'s team members..."} />
          }
          { !loadingMembers &&						
						<JSONDatatable 
						  type={TYPE_FROM_ARRAY}
						  data={_members}
						  sort={{ index: 0, asc: true }}
						  getFilteredRows={this.getMembersFilteredRows}
						  getSortedRows={this.getMembersSortedRows}
						  getTableData={this.getMembersTableData}
						  autoRefresh={20 * 1000}
						  loadingBarTitle={'Hold tight! We\'re getting your tour\'s team members...'}
						> 
						  <div ref={SEARCHBAR} hasSearch labelTotalCount="Number of Matching Members" />
						  <div ref={DATATABLE}/>
						</JSONDatatable>
          }
        </div>
        <h3 className="heading_style">Team Invitations</h3>
        <div className="btn-toolbar text-right">
          <Button className="btn btn-success" onClick={this.onClickNewInvitation}>
            <i className="fa fa-fw fa-plus" /> Invite New Member
          </Button>
        </div>
        <div className="tour-team-invitations">
          { loadingInvitations &&
						<LoadingBar key='loadingbar' title={"Hold tight! We\'re getting your tour\'s team invitations..."} />
          }
          { !loadingInvitations &&						
						<JSONDatatable 
						  type={TYPE_FROM_ARRAY}
						  data={invitations}
						  sort={{ index: 0, asc: true }}
						  getFilteredRows={this.getInvitationsFilteredRows}
						  getSortedRows={this.getInvitationsSortedRows}
						  getTableData={this.getInvitationsTableData}
						  autoRefresh={20 * 1000}
						  loadingBarTitle={'Hold tight! We\'re getting your tour\'s team invitations...'}
						> 
						  <div ref={SEARCHBAR} hasSearch labelTotalCount="Number of Matching Invitations" />
						  <div ref={DATATABLE}/>
						</JSONDatatable>
          }
        </div>
        <Modal
          className="tour-team-new-invitation modal-trans"
          style={modalStyle}
          isOpen={showNewInvitationModal}
          contentLabel="Modal"
          onRequestClose={this.onCancelNewInvitation}
          closeTimeoutMS={150}
          ariaHideApp={false}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                                New Team Invitations
              </div>
              <div className="modal-body">
                <TourTeamInvitationForm
                  onSubmit={this.handleNewInvitationSubmit}
                  onCancel={this.onCancelNewInvitation}
                  submitLabel={'Invite'}
                  initialValues={INVITATION_INITIAL_VALUES}
                  isNew={true}
                />
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          className="brand-team-cancel-invitation-confirm modal-trans"
          style={modalStyle}
          isOpen={showCancelInvitationModal}
          contentLabel="Modal"
          onRequestClose={this.onCancelInvitationCancel}
          closeTimeoutMS={150}
          ariaHideApp={false}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                                Invitation Cancel
              </div>
              <div className="modal-body">
                { invitationCancelConfirmMsg }
              </div>
              <div className="modal-footer">
                <div className="btn-toolbar btn-toolbar-right">
                  <Button className="btn btn-success btn-shadow" type="button" onClick={this.cancelInvitation} disabled={cancelingInvitation}>Ok</Button>
                  <Button className="btn btn-default btn-shadow" type="button" onClick={this.onCancelInvitationCancel} disabled={cancelingInvitation}>Cancel</Button>
                </div>
              </div>
            </div>
          </div>    
        </Modal>
      </div>
    )
  }
}
