import _get from 'lodash/get'
import { baseRequest } from '.'

export const getEventTickets = async eventId => {
  const res = await baseRequest.get(`events/${eventId}/relationships/tickets/`)
  return _get(res, 'data.data')
}

export const getEventTicketsByGroup = (eventId, groupId, date = '') =>
  baseRequest.get(`events/${eventId}/relationships/tickets/`, {
    params: {
      date,
      'group_ids[]': groupId
    }
  })

export const getSeatMaps = async eventId => {
  const res = await baseRequest.get(`event/${eventId}/get-seat-map/`)
  return _get(res, 'data.data.attributes', [])
}

export const getSeatMap = async (eventId, seatMapId) => {
  const res = await baseRequest.get(`event/${eventId}/get-seat-map/${seatMapId}/`)
  return _get(res, 'data.data.attributes')
}

export const createSeatMap = async (eventId, venueId = null, values) => {
  const res = await baseRequest.post(`event/${eventId}/create-seat-map/`, {
    data: {
      attributes: { ...values, venueId }
    }
  })
  return _get(res, 'data.data.attributes')
}

export const updateSeatMap = async (eventId, values) => {
  const res = await baseRequest.patch(`event/${eventId}/update-seat-map/${values.id}/`, {
    data: {
      attributes: values
    }
  })
  return _get(res, 'data.data.attributes')
}

export const deleteSeatMap = async (eventId, seatMapId) => await baseRequest.delete(`event/${eventId}/delete-seat-map/${seatMapId}/`)

export const getTicketTrackingData = async eventId => {
  const res = await baseRequest.get(`event/${eventId}/sales-tracking/`)
  return _get(res, 'data.data')
}

export const createTicketTrackingData = async (eventId, data) => await baseRequest.post(`event/${eventId}/sales-tracking/`, {
  data
})

export const deleteTicketTrackingData = async (eventId, trackingSalesId) => await baseRequest.delete(`event/${eventId}/sales-tracking/${trackingSalesId}/`)

export const regenerateTrackingToken = async (eventId, trackingSalesId) => await baseRequest.patch(`event/${eventId}/sales-tracking/${trackingSalesId}/regenerate-token/`)

export const getTicketTrackingDataByToken = async token => {
  const res = await baseRequest.get(`get-sales-tracking-breakdown/`, {
    params: {
      token
    }
  })
  return _get(res, 'data.data')
}


export const updateTicketsSorting = async (eId, tickets) => {
  return await baseRequest.patch(`event/${eId}/ticket-type/sorting/`, {
    data: {
      attributes: {
        ticketTypes: tickets
      }
    }
  })
}

export const blockSeats = async (eventId, seatIds, tierId) => {
  return await baseRequest.post(`event/${eventId}/seats/block/`, {
    data: {
      attributes: {
        seats: seatIds,
        tierId: +tierId,
      }
    }
  })
}

export const unblockSeats = async (eventId, seatIds, tierId) => {
  return await baseRequest.delete(`event/${eventId}/seats/unblock/`, {
    data: {
      data: {
        attributes: {
          seats: seatIds,
          tierId: +tierId,
        }
      }
    }
  })
}


export const getTicketTypeOrders = async (eId, ticketId) => {
  const res = await baseRequest.get(`event/${eId}/orders?ticket_types=${ticketId}`)
  const count = _get(res, 'data.count')
  const addons = _get(res, 'data.data.addOns')
  return {
    ticketId,
    orders: {
      count,
      addons,
    }
  }
}

export const deleteTicketType = async (eventId, ticketId) => {
  const res = await baseRequest.delete(`/event/${eventId}/ticket-type/${ticketId}`)
  return res.data
}