import _get from 'lodash/get'
import { baseRequest } from '.'

export const deletePreRegistrationActions = async (eid, data) => {
  const res = await baseRequest.delete(`event/${eid}/preregistration/actions/`, {
    data
  })
  return _get(res, 'data')
}

export default deletePreRegistrationActions
