import { arrayToObject, isDateRangeInvalid, isDateRangeInvalid2 } from './utils'
import _get from 'lodash/get'
import _set from 'lodash/set'
import _forEach from 'lodash/forEach'
import { fileSizeLimitValidator } from '../../../../_common/core/validation'

const shortNameRE = /[<>'\"]+/
const slugRE = /^[\w-]*$/

export function validateOnlineEvent(data, props) {
  const published = _get(props.event, 'status', '') === 'published'
  const required = ['displayName', 'slug', 'owner', 'shortName', 'owner', 'timezone', 'currency']
  const displayNameLength = 65
  const errors = {}

  required.forEach(f => {
    if (!_get(data, f)) {
      _set(errors, f, 'Required')
    }
  })

  if (_get(data, 'displayName', '').length > displayNameLength) {
    _set(errors, 'displayName', `${displayNameLength} characters maximum`)
  }

  if (_get(data, 'eventType')) {
    let eventType = _get(data, 'eventType')
    eventType = arrayToObject(eventType)
    const strEventType = JSON.stringify(eventType)
    if (strEventType.indexOf('true') === -1) {
      _set(errors, 'eventType', 'Please select at least one event type.')
    }
  } else {
    _set(errors, 'eventType', 'Please select at least one event type')
  }

  if (_get(data, 'shortName')) {
    const shortName = _get(data, 'shortName', '')
    if (shortName && shortName.length > 17) {
      _set(errors, 'shortName', 'Maximum 17 characters')
    } else {
      if (shortNameRE.test(shortName))
        _set(errors, 'shortName', 'Short Event Name are not allowed to use the symbols (>, <, \', ")')
    }
  }

  if (_get(data, 'facebookEvent')) {
    const fbURL = _get(data, 'facebookEvent', '')
    if (!/^(?:https?:\/\/)?(?:www.)?(?:m.)?facebook.com\/events\/+/i.test(fbURL))
      _set(errors, 'facebookEvent', 'Invalid Facebook URL')
  }

  const slug = _get(data, 'slug', '')
  if (!slugRE.test(slug)) {
    _set(
      errors,
      'slug',
      'Link may only contain alphanumeric characters (a-z, 0-9), underscores (_) and hyphens (-)'
    )
  }

  const startDate = _get(data, 'startDate')
  const endDate = _get(data, 'endDate')
  if (!startDate) {
    _set(errors, 'startDate', 'Required')
  }
  if (!endDate) {
    _set(errors, 'endDate', 'Required')
  }
  if (_get(data, 'endDate') && _get(data, 'startDate') && isDateRangeInvalid(startDate, endDate)) {
    _set(errors, 'endDate', 'End date must be after start date')
  }

  if (!_get(data, 'salesEndDate')) {
    _set(errors, 'salesEndDate', 'Required')
  }

  const salesStartDate = _get(data, 'salesStartDate')
  const salesEndDate = _get(data, 'salesEndDate')
  if (isDateRangeInvalid2(salesStartDate, salesEndDate)) {
    _set(errors, 'salesEndDate', 'Sales end date must be after sales start date')
  }

  // providers
  const { user = {} } = props
  const hasZoomAccountAttached = !!user.hasZoomAccountAttached
  const provider = _get(data, 'selectedMeeting.provider')
  const isNewMeeting = _get(data, 'isNewMeeting')

  if (
    published &&
    provider === 'zoom' &&
    !isNewMeeting &&
    !_get(data, 'selectedMeeting.meetingId') &&
    hasZoomAccountAttached
  ) {
    _set(errors, 'zoomMeeting', 'Please, create a new meeting or select from the list.')
  }

  if (published && provider === 'zoom' && !hasZoomAccountAttached) {
    _set(errors, 'zoomAccount', 'Please, connect to your Zoom account.')
  }

  if (data.flagDonation) {
    if (data.donationDetails.type) {
      if (
        data.donationDetails.type === 'fixed' &&
        !(data.donationDetails.amountOptions && data.donationDetails.amountOptions.length)
      ) {
        _set(errors, 'donationDetails.amountOptions', 'Required')
      }
    } else {
      _set(errors, 'donationType', 'Please, select a donation option.')
    }
  }

  const imageURLs = _get(data, 'imageURLs')
  _forEach(imageURLs, img => {
    if (fileSizeLimitValidator()(img)) {
      _set(errors, 'imageURLs', fileSizeLimitValidator()(img))
    }
  })

  const ogImage = _get(data, 'ogImage')
  if (fileSizeLimitValidator()(ogImage)) {
    _set(errors, 'ogImage', fileSizeLimitValidator()(ogImage))
  }

  const twitterImage = _get(data, 'twitterImage')
  if (fileSizeLimitValidator()(twitterImage)) {
    _set(errors, 'twitterImage', fileSizeLimitValidator()(twitterImage))
  }

  return errors
}
