import { createAsyncAction } from '../actions'
import { fetchAPI, formatFiltersAsParam } from '../../core/http'
import { ERROR } from '../notification/actions'

const { SET_HAS_TEAM_INVITATION } = createAsyncAction('SET_HAS_TEAM_INVITATION', function(flag) {
  return dispatch => {
    dispatch(this.success(flag))
  }
})

const { FETCH_BRAND_TEAM_INVITATIONS } = createAsyncAction('FETCH_BRAND_TEAM_INVITATIONS', function(eid) {
  return (dispatch) => fetchAPI(`/api/brand/${eid}/members/invitations`)
    .catch((err) => {
      dispatch(ERROR(...err.errors))
      throw err
    })
    .then((res) => {
      dispatch(this.success(res))
      return res
    })
})

const { ADD_BRAND_TEAM_INVITATION } = createAsyncAction('ADD_BRAND_TEAM_INVITATION', function(eid, form) {
  return (dispatch) => {
    form.attributes.manufacturerId = parseInt(eid)
    return fetchAPI('/api/team-invitations', {
      method: 'POST',
      body: JSON.stringify({ data: { ...form } })
    })
      .catch((err) => {
        dispatch(ERROR(...err.errors))
        throw err
      })
      .then((res) => {
        dispatch(this.success(res))
        return res
      })
  }
})

const { FETCH_BRAND_TEAM_MEMBERS } = createAsyncAction('FETCH_BRAND_TEAM_MEMBERS', function(eid) {
  return (dispatch) => fetchAPI(`/api/brand/${eid}/members`)
    .catch((err) => {
      dispatch(ERROR(...err.errors))
      throw err
    })
    .then((res) => {
      dispatch(this.success(res))
      return res
    })
})

const { RESEND_BRAND_TEAM_INVITATION } = createAsyncAction('RESEND_BRAND_TEAM_INVITATION', function(bid, email) {
  return (dispatch) => fetchAPI(`/api/brand/${bid}/team-invitation/reminder`, {
    method: 'POST',
    body: JSON.stringify({
      data: {
        attributes: {
          emails: [email]
        }
      }
    })
  })
    .catch((err) => {
      dispatch(ERROR(...err.errors))
      throw err
    })
    .then((res) => {
      dispatch(this.success(res))
      return res
    })
})

const {FETCH_BRAND_PROMOTER_GENERIC_INVITATION} = createAsyncAction('FETCH_BRAND_PROMOTER_GENERIC_INVITATION', function (eid) {
  return (dispatch) => {
    const filter = formatFiltersAsParam({type: 'generic'})

    return fetchAPI(`/api/brand/${eid}/members/invitations`, {
      params: {
        ...filter
      }
    })
      .catch((err) => {
        dispatch(ERROR(...err.errors))
        dispatch(this.failed(err))
        throw err
      })
      .then((res) => {
        let promoterInvitation = _.find(res.data, invitation => invitation.role === 'promoter')
        dispatch(this.success(promoterInvitation))
        return promoterInvitation
      })
  }
})


const {REGENERATE_PROMOTER_GENERIC_INVITATION_BRAND} = createAsyncAction('REGENERATE_PROMOTER_GENERIC_INVITATION_BRAND', function (token) {
  return (dispatch) => {
    return fetchAPI(`/api/team-invitations/regenerate`, {
      method: "POST",
      params: {
        token
      }
    })
      .catch((err) => {
        dispatch(ERROR(...err.errors))
        dispatch(this.failed(err))
        throw err
      })
      .then((res) => {
        dispatch(this.success(res.data.token))
        return res.data.token
      })
  }
})

const {UPDATE_BRAND_PROMOTER_GENERIC_INVITATION} = createAsyncAction('UPDATE_BRAND_PROMOTER_GENERIC_INVITATION', function (token, form) {
  return (dispatch) => {
    return fetchAPI(`/api/team-invitations/${token}`, {
      method: "PUT",
      body: JSON.stringify({data: {attributes: {...form}}}),
    })
      .catch((err) => {
        dispatch(ERROR(...err.errors))
        dispatch(this.failed(err))
        throw err
      })
      .then((res) => {
        dispatch(this.success(res))
        return res
      })
  }
})

export {
  FETCH_BRAND_TEAM_INVITATIONS,
  ADD_BRAND_TEAM_INVITATION,
  FETCH_BRAND_TEAM_MEMBERS,
  RESEND_BRAND_TEAM_INVITATION,
  FETCH_BRAND_PROMOTER_GENERIC_INVITATION,
  REGENERATE_PROMOTER_GENERIC_INVITATION_BRAND,
  UPDATE_BRAND_PROMOTER_GENERIC_INVITATION,
  SET_HAS_TEAM_INVITATION
}

