import _get from 'lodash/get'
import handleActions from 'redux-actions/lib/handleActions'
import { createAsyncHandlers } from '../actions'

const initialState = {
  mailchimp: {},
  redirect_uri: {},
}

const FETCH_MC_LISTS = createAsyncHandlers('FETCH_MC_LISTS', {
  success(state, action) {
    const { mailchimp: { data } } = action.payload
    state.mailchimp = _get(data, '$original')
  }
})

const CONNECT_TO_MAILCHIMP = createAsyncHandlers('CONNECT_TO_MAILCHIMP', {
  success(state, action) {
    const { data } = action.payload
    state.redirect_uri = _get(data, '$original')
  }
})


export default handleActions({
  ...FETCH_MC_LISTS,
  ...CONNECT_TO_MAILCHIMP,
}, initialState)

