import React from 'react'

import { getTableColumns, makeRunningTotalLabel } from '../../utils/sortableTableUtils'
import {
  createFixedFloatNormalizer,
  currencyNormalizerCreator,
  formatDay
} from '../../../_common/core/validation/normalizers'
import SortableTable from '../../_library/SortableTable'
import Select from '../../_library/Select'

export default class TourPerformanceByOrderDate extends React.Component {
  constructor(props) {
    super(props)

    this.searchBy = ['orderDate', 'ticketName']
    this.tableColumns = getTableColumns([
      { key: 'orderDate', label: 'Order Date', normalizer: formatDay },
      { key: 'group_num_sales', label: 'No. of Sales' },
      {
        key: 'num_sales_total',
        label: makeRunningTotalLabel('No. of Sales').value,
        labelComponent: makeRunningTotalLabel('No. of Sales').component,
        isSortable: false
      },
      {
        key: 'revenue',
        label: 'Revenue',
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(props.event))(
            createFixedFloatNormalizer(2)(parseFloat(value))
          )
      },
      {
        key: 'revenue_total',
        label: makeRunningTotalLabel('Revenue').value,
        labelComponent: makeRunningTotalLabel('Revenue').component,
        isSortable: false,
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(props.event))(
            createFixedFloatNormalizer(2)(parseFloat(value))
          )
      },
      {
        key: 'grossRevenue',
        label: 'Gross Revenue',
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(props.event))(
            createFixedFloatNormalizer(2)(parseFloat(value))
          )
      },
      {
        key: 'grossRevenueTotal',
        label: makeRunningTotalLabel('Gross Revenue').value,
        labelComponent: makeRunningTotalLabel('Gross Revenue').component,
        isSortable: false,
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(props.event))(
            createFixedFloatNormalizer(2)(parseFloat(value))
          )
      }
    ])

    this.footbarColumns = [
      { key: 'num_sales_total', sourceKey: 'group_num_sales' },
      {
        key: 'revenue_total',
        sourceKey: 'revenue',
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(props.event))(
            createFixedFloatNormalizer(2)(parseFloat(value))
          )
      },
      {
        key: 'grossRevenueTotal',
        sourceKey: 'grossRevenue',
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(props.event))(
            createFixedFloatNormalizer(2)(parseFloat(value))
          )
      }
    ]
  }

  handleChange = option => {
    const { handleTimezoneChange } = this.props
    handleTimezoneChange(option)
  }

  render() {
    const { data, timezoneOptions, selectedTimezone } = this.props
    const showTimezoneSelect = timezoneOptions && timezoneOptions.length > 1

    return (
      <div>
        {showTimezoneSelect && (
          <div className="col-xs-4 col-12 right" style={{ padding: 0, marginBottom: -15 }}>
            <Select
              onSelect={this.handleChange}
              options={timezoneOptions}
              value={selectedTimezone ? selectedTimezone.value : ''}
            />
          </div>
        )}
        <SortableTable
          data={data}
          tableColumns={this.tableColumns}
          calculatedColumns={[
            { for: 'num_sales_total', column: 'group_num_sales' },
            { for: 'revenue_total', column: 'revenue' },
            { for: 'grossRevenueTotal', column: 'grossRevenue' }
          ]}
          footbar={{
            label: 'Total',
            columns: this.footbarColumns
          }}
          enableCopyTable={true}
          enableSort={true}
          disableMobileView={true}
        />
      </div>
    )
  }
}
