import { produce } from 'immer'
import _forEach from 'lodash/forEach'
import handleActions from 'redux-actions/lib/handleActions'

const initialState = {}

const add = produce((state, action) => {
  const { payload } = action
  _forEach(payload, m => {
    if (m.message.code === 'request_failed') {
      return
    }
    return (state[m.id] = m)
  })
})

export default handleActions(
  {
    'notifications/INFO': add,
    'notifications/ERROR': add,
    'notifications/WARNING': add,
    'notifications/SUCCESS': add,
    'notifications/DISMISS': produce((state, action) => {
      delete state[action.payload]
    }),
    'notifications/CLEAR': () => initialState,
  },
  initialState
)
