import _some from 'lodash/some'
import _range from 'lodash/range'

export const PATHS = {
  // first level paths
  1: /^\/events$/,
  2: /^\/brands$/,
  3: /^\/tours$/,
  4: /^\/signin$/,
  5: /^\/account$/,
  6: /^\/reset-password$/,
  7: /^\/team-invitations$/,
  8: /^\/sales-by-promo-code$/,

  // statistics level paths
  101: /^\/event\/[\d]+\/performance$/,
  102: /^\/event\/[\d]+\/influencers$/,
  103: /^\/event\/[\d]+\/orders$/,
  104: /^\/event\/[\d]+\/checkin$/,
  105: /^\/event\/[\d]+\/demographics$/,
  106: /^\/event\/[\d]+\/geographics$/,
  107: /^\/event\/[\d]+\/music$/,
  108: /^\/event\/[\d]+\/musicstreaming$/,
  109: /^\/event\/[\d]+\/likes$/,
  110: /^\/event\/[\d]+\/psychographics$/,
  111: /^\/event\/[\d]+\/devices$/,
  112: /^\/event\/[\d]+\/gaming$/,
  113: /^\/event\/[\d]+\/traffic$/,

  // performance level paths
  201: /^\/event\/[\d]+\/performance\/ticket-sales$/,
  202: /^\/event\/[\d]+\/performance\/sales-by-ticket-type$/,
  203: /^\/event\/[\d]+\/performance\/add-ons$/,
  204: /^\/event\/[\d]+\/performance\/tickets-in-carts$/,
  205: /^\/event\/[\d]+\/performance\/pre-registration$/,
  206: /^\/event\/[\d]+\/performance\/waiting-list$/,
  207: /^\/event\/[\d]+\/performance\/ticket-resale$/,
  208: /^\/event\/[\d]+\/performance\/sales-by-promo-code$/,
  209: /^\/event\/[\d]+\/performance\/sales-by-promoter$/,
  210: /^\/event\/[\d]+\/performance\/box-office-sales$/,
  211: /^\/event\/[\d]+\/performance\/additional-charges$/,
  212: /^\/event\/[\d]+\/performance\/sales-by-password$/,
  213: /^\/event\/[\d]+\/performance\/rsvp$/,

  // edit level paths
  301: /^\/event\/[\d]+\/details$/,
  302: /^\/event\/[\d]+\/tickets$/,
  303: /^\/event\/[\d]+\/talent$/,
  304: /^\/event\/[\d]+\/gift-certificates-management$/,
  305: /^\/event\/[\d]+\/budgets$/,
  306: /^\/event\/[\d]+\/team$/,
  307: /^\/event\/[\d]+\/waiting-list$/,
  308: /^\/event\/[\d]+\/pre-registration$/,
  309: /^\/event\/[\d]+\/credentials$/,

  // interact level paths
  401: /^\/event\/[\d]+\/messaging$/,
  402: /^\/event\/[\d]+\/invitations$/,
  403: /^\/event\/[\d]+\/guest-tickets$/,
  404: /^\/event\/[\d]+\/promotions$/,
  405: /^\/event\/[\d]+\/membership$/,
  406: /^\/event\/[\d]+\/gift-certificates$/,
  407: /^\/event\/[\d]+\/email-templates$/,

  // support level paths
  501: /^\/event\/[\d]+\/faq$/,

  // brand level paths
  601: /^\/brand\/[\d]+\/details$/,
  602: /^\/brand\/[\d]+\/demographics$/,
  603: /^\/brand\/[\d]+\/music$/,
  604: /^\/brand\/[\d]+\/musicstreaming$/,
  605: /^\/brand\/[\d]+\/likes$/,
  606: /^\/brand\/[\d]+\/team$/,

  // tour level paths
  701: /^\/tour\/[\d]+\/details$/,
  702: /^\/tour\/[\d]+\/performance$/,

  801: /^\/event\/[\d]+\/traffic\/views$/,
  802: /^\/event\/[\d]+\/traffic\/conversions$/,
  803: /^\/event\/[\d]+\/traffic\/locations$/,
  804: /^\/event\/[\d]+\/traffic\/languages$/,
  805: /^\/event\/[\d]+\/traffic\/devices$/,
  806: /^\/event\/[\d]+\/traffic\/origin$/,

  isIncludePaths: (path, arr = []) => _some(arr, el => PATHS[el] && PATHS[el].test(path)),
  isStatisticsLevelPath: path => PATHS.isIncludePaths(path, _range(101, 201)),
  isPerformanceLevelPath: path => PATHS.isIncludePaths(path, _range(201, 301)),
  isEditLevelPath: path => PATHS.isIncludePaths(path, _range(301, 401)),
  isInteractLevelPath: path => PATHS.isIncludePaths(path, _range(401, 501)),
  isSupportLevelPath: path => PATHS.isIncludePaths(path, _range(501, 601)),
  isBrandLevelPath: path => PATHS.isIncludePaths(path, _range(601, 701)),
  isTourLevelPath: path => PATHS.isIncludePaths(path, _range(701, 801)),
  isTrafficLevelPath: path => PATHS.isIncludePaths(path, _range(801, 901))
}
