import React from 'react'

import { Field as FormikField } from 'formik'
import Field from '../_library/Field'
import Card from '../_library/Card'
import CustomToggle from '../_library/CustomToggle'
import { checkCountry } from '../utils/coutriesUtils'
import { RadioGroupField } from '../formik/Fields'

const PayoutDetails = React.memo(props => {
  const { 
    values, 
    touched, 
    errors, 
    handleChange, 
    handleBlur, 
    enableToggle, 
    closed, 
    flagIsStripeConnected,
    userIsPayoutAdmin
  } = props
  const showData = (enableToggle && values.flagPaymentDetails) || !enableToggle
  const handleSwiftChange = e => {
    const event = e
    event.target.value = event.target.value.toUpperCase()
    handleChange(event)
  }
  const { isOwner } = values
  const maskedAccountNumber = values.accountNumber.slice(0, -4).replace(/./g, '*') + values.accountNumber.slice(-4)
  const maskedRoutingNumber = values.routingNumber.slice(0, -4).replace(/./g, '*') + values.routingNumber.slice(-4)
  const allowEdit = isOwner || userIsPayoutAdmin

  return (
    <Card icon={'fa-money'} title={'Payout Details'} closed={closed}>
      {enableToggle && !flagIsStripeConnected && (
        <div className="row">
          <div className="col-sm-12">
            <CustomToggle
              id="flagPaymentDetails"
              onText="Use alternative payout details for this event"
              value={values.flagPaymentDetails}
              checked={values.flagPaymentDetails}
              onChange={handleChange}
            />
          </div>
        </div>
      )}
      {enableToggle && values.flagPaymentDetails && <div className="div-spacing-20" />}
      {showData && (
        <div className="row">
          {checkCountry(values.country, 'us') && (
            <div className="col-xs-6 col-12 div-margin20-mobile">
              <div className="div-spacing-20">Type of account</div>
              <div className={!allowEdit ? 'disable-element' : ''}>
                <FormikField
                  name="accountType"
                  component={RadioGroupField}
                  groups={[
                    {
                      label: 'Checking',
                      value: 'checking'
                    },
                    {
                      label: 'Saving',
                      value: 'saving'
                    }
                  ]}
                  classNames={{
                    container: 'radio-group-container',
                    container_item: 'radio-group-item',
                    component: ''
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
          )}
          <div className="col-sm-6 col-12 div-margin20-mobile">
            <Field
              id="accountName"
              label="Account Holder Name"
              value={values.accountName}
              error={errors.accountName}
              touched={touched.accountName}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!allowEdit}
            />
          </div>
          {checkCountry(values.country, 'us') && (
            <div className="col-sm-6 col-12 div-margin20-mobile">
              <Field
                id={allowEdit ? 'routingNumber' : 'maskedRoutingNumber'}
                label="ACH Routing Number"
                value={allowEdit ? values.routingNumber : maskedRoutingNumber}
                error={errors.routingNumber}
                touched={touched.routingNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={!allowEdit}
              />
            </div>
          )}
          <div className="col-sm-6 col-12">
            <Field
              id={allowEdit ? 'accountNumber' : 'maskedAccountNumber'}
              label="Account Number"
              value={allowEdit ? values.accountNumber : maskedAccountNumber}
              error={errors.accountNumber}
              touched={touched.accountNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!allowEdit}
            />
          </div>
        </div>
      )}

      <div className="div-spacing-20" />

      {showData && checkCountry(values.country, 'mx') && (
        <>
          <div className="row">
            <div className="col-xs-12">
              <Field
                id="swiftCode"
                label="SWIFT Code"
                value={values.swiftCode}
                error={errors.swiftCode}
                touched={touched.swiftCode}
                onChange={handleSwiftChange}
                onBlur={handleBlur}
                disabled={!allowEdit}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <Field
                id="clabe"
                label="CLABE"
                value={values.clabe}
                error={errors.clabe}
                touched={touched.clabe}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={!allowEdit}
              />
            </div>
            <div className="col-sm-6">
              <Field
                id="fullAddress"
                label="Full Address"
                value={values.fullAddress}
                error={errors.fullAddress}
                touched={touched.fullAddress}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={!allowEdit}
              />
            </div>
          </div>
        </>
      )}
      {showData && checkCountry(values.country, 'au') && (
        <div className="row">
          <div className="col-xs-6 col-12">
            <Field
              id="bsb"
              label="BSB"
              value={values.bsb}
              error={errors.bsb}
              touched={touched.bsb}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!allowEdit}
            />
          </div>
        </div>
      )}
      {showData && checkCountry(values.country, 'uk') && (
        <div className="row">
          <div className="col-xs-12">
            <Field
              id="sortCode"
              label="Sort Code"
              value={values.sortCode}
              error={errors.sortCode}
              touched={touched.sortCode}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!allowEdit}
            />
          </div>
        </div>
      )}
      {showData && checkCountry(values.country, 'in') && (
        <div className="row">
          <div className="col-sm-6">
            <Field
              id="ifscNumber"
              label="IFSC Number"
              value={values.ifscNumber}
              error={errors.ifscNumber}
              touched={touched.ifscNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!allowEdit}
            />
          </div>
          <div className="col-sm-6">
            <Field
              id="bankName"
              label="Bank Name"
              value={values.bankName}
              error={errors.bankName}
              touched={touched.bankName}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!allowEdit}
            />
          </div>
        </div>
      )}
      {showData && checkCountry(values.country, 'ca') && (
        <div className="row">
          <div className="col-sm-6">
            <Field
              id="transitNumber"
              label="Transit Number"
              value={values.transitNumber || ''}
              error={errors.transitNumber}
              touched={touched.transitNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!allowEdit}
            />
          </div>
          <div className="col-sm-6">
            <Field
              id="institutionNumber"
              label="Institution Number"
              value={values.institutionNumber || ''}
              error={errors.institutionNumber}
              touched={touched.institutionNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!allowEdit}
            />
          </div>
        </div>
      )}
    </Card>
  )
})

export default PayoutDetails
