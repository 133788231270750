import React from 'react'
import PropTypes from 'prop-types'

const CardHeader = ({ imageUrl, bodyText }) => {
    return (
        <div className="table-caption">
            <img
                className="section-main-heading"
                src={asset(`${imageUrl}`)}
            />
            { bodyText }
        </div>
    )
}

CardHeader.propTypes = {
    imageUrl: PropTypes.string,
    bodyText: PropTypes.string,
};

export default CardHeader