import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { ENV } from '../../../web/constants/env'
import { loadScripts } from './utils'

export const loadMediumEditor = () => {
  if (window.MediumEditor) {
    return Promise.resolve()
  }
  if (loadMediumEditor.MediumEditorPromise) {
    return loadMediumEditor.MediumEditorPromise
  }

  loadMediumEditor.MediumEditorPromise = loadScripts([
    { src: '/resources/plugins/mediumeditor/medium-editor.js', defer: true, async: false },
    {
      src: '/resources/plugins/mediumeditor/handlebars/handlebars.runtime.min.js',
      defer: true,
      async: false
    },
    {
      src: '/resources/plugins/mediumeditor/jquery-sortable/source/js/jquery-sortable-min.js',
      defer: true,
      async: false
    },
    {
      src: '/resources/plugins/mediumeditor/blueimp-file-upload/js/vendor/jquery.ui.widget.js',
      defer: true,
      async: false
    },
    {
      src: '/resources/plugins/mediumeditor/blueimp-file-upload/js/jquery.iframe-transport.js',
      defer: true,
      async: false
    },
    {
      src: '/resources/plugins/mediumeditor/blueimp-file-upload/js/jquery.fileupload.js',
      defer: true,
      async: false
    },
    { src: '/resources/plugins/mediumeditor/medium-editor-insert-plugin.js', defer: true, async: false }
  ])

  return loadMediumEditor.MediumEditorPromise
}

export const loadMessenger = () => {
  if (window.Messenger) {
    return Promise.resolve()
  }
  let postData = null
  let postOptions = null
  window.Messenger = () => ({
    post: _postData => (postData = _postData)
  })
  Object.defineProperty(window.Messenger, 'options', {
    set(_postOptions) {
      postOptions = _postOptions
    }
  })

  return loadScripts([
    { src: '/resources/plugins/messenger/messenger.min.js', defer: true, async: false },
    { src: '/resources/plugins/messenger/messenger-theme-future.js', defer: true, async: false }
  ]).then(() => {
    if (postOptions) {
      window.Messenger.options = postOptions
    } else {
      window.Messenger.options = {
        extraClasses: 'messenger-fixed messenger-on-top messenger-on-right',
        theme: 'future'
      }
    }
    if (postData) {
      window.Messenger().post(postData)
    }
  })
}

export const loadJSPDF = () =>
  window.jsPDF
    ? Promise.resolve()
    : loadScripts([
        { src: '/resources/plugins/jspdf/jspdf.min.js', defer: true, async: false },
        { src: '/resources/plugins/jspdf/jspdf.customfonts.min.js', defer: true, async: false },
        { src: '/resources/plugins/jspdf/jspdf.plugin.autotable.js', defer: true, async: false }
      ])

export const loadAppcues = () => {
  if (window.Appcues) {
    return Promise.resolve()
  }
  if (loadAppcues.loadAppcuesPromise) {
    return loadAppcues.loadAppcuesPromise
  }
  loadAppcues.loadAppcuesPromise = loadScripts([{ src: 'https://fast.appcues.com/52536.js', async: false }])
  return loadAppcues.loadAppcuesPromise
}

export const loadLocalize = () => {
  if (window.Localize) {
    return Promise.resolve()
  }
  window.Localize = {}
  const prp = [
    'translate',
    'untranslate',
    'phrase',
    'initialize',
    'translatePage',
    'setLanguage',
    'getLanguage',
    'detectLanguage',
    'getAvailableLanguages',
    'untranslatePage',
    'bootstrap',
    'prefetch',
    'on',
    'off'
  ]
  prp.forEach(el => (window.Localize[el] = () => undefined))

  return loadScripts([{ src: 'https://global.localizecdn.com/localize.js', async: false }]).then(() => {
    window.Localize.initialize({
      key: 'g6CSAT42kCQGs',
      rememberLanguage: true
    })
  })
}

export const loadSentry = () => {
  if (ENV.SENTRY_DSN) {
    Sentry.init({
      dsn: ENV.SENTRY_DSN,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 1.0,
      autoSessionTracking: true
    })
  }
}

export function loadZendeskWidget(key, options) {
  const zendeskInitScript = document.createElement('script')
  zendeskInitScript.id = 'ze-snippet'
  zendeskInitScript.src = `https://static.zdassets.com/ekr/snippet.js?key=${
    key || '4f3dc921-d43e-41d6-802f-08b71d6e711d'
  }`
  zendeskInitScript.async = true
  document.body.appendChild(zendeskInitScript)
  window.zESettings = { ...options }
}

export function loadUsernap() {
  window.onUsersnapCXLoad = function (api) {
    api.init()
  }
  const script = document.createElement('script')
  script.defer = 1
  script.src = 'https://widget.usersnap.com/global/load/93c8de33-96de-4af4-ba76-f1f8008925e3?onload=onUsersnapCXLoad'
  document.getElementsByTagName('head')[0].appendChild(script)
}

