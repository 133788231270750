import React, { PureComponent } from 'react'
import _map from 'lodash/map'
import Button from '../Button'
import DataTable from '../DataTable'
import modalStyle from '../../../_common/core/modalStyle'
import Modal from 'react-modal'

import { storage_all_get, storage_remove } from '../../../_common/core/http_services'
import { showAxiosError } from '../../utils/messenger'

class SelectFilterTemplate extends PureComponent {
  constructor(props) {
    super(props)

    this.isUnmounted = false
    this.state = {
      templates: null,
      isModalOpen: false,
      deleteLoadingName: ''
    }

    this.thead = ['Name', 'Actions']
  }
  componentDidMount() {
    this.fetchTemplates()
  }

  componentWillUnmount() {
    this.isUnmounted = true
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.updateVersion !== this.props.updateVersion) {
      this.fetchTemplates()
    }
  }

  openOrCloseModal = open => {
    this.setState(() => ({ isModalOpen: open }))
  }

  fetchTemplates = async () => {
    const { storagePath: path } = this.props
    try {
      const templates = await storage_all_get(path)
      !this.isUnmounted && this.setState(() => ({ templates }))
    } catch (err) {}
  }

  onSelect = template => {
    const { onSelectFilter } = this.props
    onSelectFilter(template)
    this.openOrCloseModal(false)
  }

  onDelete = async template => {
    const { storagePath: path } = this.props
    const { templates } = this.state
    const { name } = template
    try {
      this.setState(() => ({ deleteLoadingName: name }))
      await storage_remove(path, name)
      if (templates && templates.length === 1) {
        this.openOrCloseModal(false)
      }
      await this.fetchTemplates()
    } catch (e) {
      showAxiosError(e)
    } finally {
      this.setState(() => ({ deleteLoadingName: '' }))
    }
  }

  render() {
    const { templates, isModalOpen, deleteLoadingName } = this.state
    if (!templates || !templates.length) {
      return null
    }

    const { thead } = this
    const tbody = _map(templates, item => {
      const { name } = item
      return [
        name,
        <span style={{ maxWidth: '200px' }}>
          <Button
            className="btn btn-success btn-shadow"
            style={{ margin: 5 }}
            type="button"
            onClick={() => this.onSelect(item)}
          >
            Select
          </Button>
          <Button
            className="btn btn-danger"
            style={{ margin: 5 }}
            type="button"
            loading={name === deleteLoadingName}
            onClick={() => this.onDelete(item)}
          >
            Delete
          </Button>
        </span>
      ]
    })

    return (
      <div>
        <Button className="btn btn-success btn-shadow" onClick={() => this.openOrCloseModal(true)}>
          Select From Filter Templates
        </Button>
        <Modal
          className="modal-dialog modal-trans"
          style={modalStyle}
          isOpen={isModalOpen}
          contentLabel="Modal"
          closeTimeoutMS={150}
          ariaHideApp={false}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div>
                <div className="modal-header">
                  <div>Select Filter Template</div>
                </div>
                <div className="modal-body">
                  <DataTable
                    thead={thead}
                    tbody={tbody}
                    classNames={{ container: 'select-filter-template-table' }}
                  />

                  <div className="modal-footer credential_modal_action_buttons">
                    <div className="btn-toolbar btn-toolbar-right">
                      <Button
                        className="btn btn-cancel btn-shadow"
                        type="button"
                        onClick={() => this.openOrCloseModal(false)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

export { SelectFilterTemplate }
