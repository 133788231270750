import React from 'react'
import { Route } from 'react-router-dom'
import _get from 'lodash/get'
import { checkStringMatchesPath } from '../utils/checkStringMatchesPath'
import { getConfig } from '../utils/configUtils'

const CustomRoute = props => {
  const { exact, path, component, render, location } = props

  const Component = component
  const configFile = getConfig()

  // Set initial title
  document.title = _get(configFile, '*.messages.documentTitle')

  const pathConfig = checkStringMatchesPath(path, location.pathname)
    ? _get(configFile, path)
    : _get(configFile, '*')

  return (
    <Route
      exact={exact}
      path={path}
      component={component ? routeProps => <Component {...routeProps} {...props} configs={pathConfig} /> : undefined}
      render={render}
    />
  )
}

export default CustomRoute
