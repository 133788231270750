import { baseRequest } from '.'
import _get from 'lodash/get'
import { passesAdapter } from './adapters/multientrypasses'

export const getAllPassesTypes = async (levelId, level) => {
  const res = await baseRequest.get(`${level}/${levelId}/entry-pass-type`)
  return _get(res, 'data.data.attributes')
}

export const createPassType = async (eventId, level, { name, description, type, maxUsesPerEvent }) => {
  const res = await baseRequest.post(`entry-pass-type`, {
    data: {
      attributes: {
        name,
        description,
        type,
        level,
        itemId: Number(eventId),
        maxUsesPerEvent: maxUsesPerEvent === "" ? null : Number(maxUsesPerEvent),
      }
    }
  })
  return res
}

export const deletePassType = async passId => {
  const res = await baseRequest.delete(`entry-pass-type/${passId}`)
  return res
}

export const deletePass = async passId => {
  const res = await baseRequest.delete(`entry-pass/${passId}`)
  return res
}

export const editPassType = async (passId, eventId, level, { name, description, type, maxUsesPerEvent }) => {
  const res = await baseRequest.put(`entry-pass-type/${passId}`, {
    data: {
      attributes: {
        name,
        description,
        type,
        level,
        itemId: Number(eventId),
        maxUsesPerEvent: maxUsesPerEvent === "" ? null : Number(maxUsesPerEvent),
      }
    }
  })
  return res
}

export const importEntryPass = async (
  eventId, level, 
  { entrypassTypeId, maxUsesPerEvent, recipients, issuedByNotes }
) => {
  const res = await baseRequest.post(`entry-pass/import`, {
    data: {
      level,
      itemId: Number(eventId),
      attributes: recipients.map(recipient => {
        recipient.entrypassTypeId = Number(entrypassTypeId)
        recipient.maxUsesPerEvent = maxUsesPerEvent === "" ? null : Number(maxUsesPerEvent)
        recipient.issuedByNotes = issuedByNotes === "" ? null : issuedByNotes
        return recipient
      }),
    }
  })
  return _get(res, 'data.data.attributes.id')
}

export const createEntryPass = async (
  eventId, level,
  { entrypassTypeId, maxUsesPerEvent, recipient, issuedByNotes }
) => {
  const res = await baseRequest.post(`entry-pass`, {
    data: {
      level,
      itemId: Number(eventId),
      attributes: {
        ...recipient,
        entrypassTypeId,
        maxUsesPerEvent: maxUsesPerEvent === "" ? null : maxUsesPerEvent,
        issuedByNotes: issuedByNotes === "" ? null : issuedByNotes
      }
    }
  })
  return _get(res, 'data.data.attributes')
}

export const getAllPasses = async (levelId, level) => {
  const res = await baseRequest.get(`${level}/${levelId}/entry-pass`)
  return passesAdapter(_get(res, 'data.data.attributes'))
}

export const sendPass = async passId => {
  const res = await baseRequest.post(`entry-pass/${passId}/send`)
  return res
}

export const sendAllPasses = async (eventId, level) => {
  const res = await baseRequest.post(`entry-pass/send`, {
    data: {
      level,
      itemId: eventId
    }
  })
  return res
}

export const putEditPass = async (passId, { entrypassTypeId, firstName, lastName, email, maxUsesPerEvent, issuedByNotes }) => {
  const res = await baseRequest.put(`entry-pass/${passId}`, {
    data: {
      attributes: {
        entrypassTypeId: Number(entrypassTypeId),
        firstName,
        lastName,
        email,
        issuedByNotes,
        maxUsesPerEvent: maxUsesPerEvent === "" ? null : Number(maxUsesPerEvent),
      }
    }
  })
  return res
}