import { createAsyncAction } from '../actions'
import {
  fetchSupportNotes,
  createSupportNotes,
  editSupportNotes,
  deleteSupportNotes
} from '../../core/http_services'
import { showAxiosError, showSuccessMessage } from '../../../web/utils/messenger'

const { FETCH_SUPPORT_NOTES } = createAsyncAction('FETCH_SUPPORT_NOTES', function (eid) {
  return async dispatch => {
    try {
      const res = await fetchSupportNotes(eid)

      dispatch(this.success(res))
      return res
    } catch (err) {
      showAxiosError(err)
    }
  }
})

const { CREATE_SUPPORT_NOTES } = createAsyncAction('CREATE_SUPPORT_NOTES', function (eid, payload) {
  return async dispatch => {
    try {
      const res = await createSupportNotes(eid, payload)

      dispatch(this.success(res))
      showSuccessMessage()
      return res
    } catch (err) {
      showAxiosError(err)
    }
  }
})

const { EDIT_SUPPORT_NOTES } = createAsyncAction('EDIT_SUPPORT_NOTES', function (eventId, noteId, payload) {
  return async dispatch => {
    try {
      const res = await editSupportNotes(eventId, noteId, payload)

      dispatch(this.success(res))
      showSuccessMessage()
      return res
    } catch (err) {
      showAxiosError(err)
    }
  }
})

const { DELETE_SUPPORT_NOTES } = createAsyncAction('DELETE_SUPPORT_NOTES', function (eventId, noteId) {
  return async dispatch => {
    try {
      const res = await deleteSupportNotes(eventId, noteId)

      dispatch(this.success(noteId))
      showSuccessMessage(res.attributes)
      return res
    } catch (err) {
      showAxiosError(err)
    }
  }
})

export { FETCH_SUPPORT_NOTES, CREATE_SUPPORT_NOTES, EDIT_SUPPORT_NOTES, DELETE_SUPPORT_NOTES }
